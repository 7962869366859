import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function ClientUserIndex(type: string) {
	return FetchRequestGET(`/users?type=Clients`)
}

export async function ClientUserShow(id: number) {
	return FetchRequestGET(`/users/${id}`)
}

export async function ClientUserUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/users/${id}`,
		body
	)
}

export async function ClientUserCreate(body: any) {
	return FetchRequestPOST(
		`/users`,
		body
	)
}

export async function ClientUserDelete(id: number) {
	return FetchRequestDELETE(
		`/users/${id}`)
}

