import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	OnChangeFn,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'
import { useState, useLayoutEffect, useRef } from 'react'
import { TemplateTableProps, TableDataTypes } from '../react-app-env';
import { Advance, Archive, CommenceTesting } from '../lib/backend/candidate';
import MessageModal from './MessageModal';
import { CommenceTestingErrorBody } from '../pages/home/CandidatePageEntered';
import Select from './DropdownCheckbox';
import React from 'react';
import { PathForEntered, PathForTestCompleted } from '..';

export default function TemplateTable({ testStatusFilterOptions,
	data,
	columns,
	openCreateModal,
	openMaintainModal,
	object_name,
	disableCreate,
	UnderComponent,
	selectedPeople,
	setSelectedPeople,
	bulk,
	hidePageButtons,
	testStatusSelectedFilterOptions,
	setTestStatusSelectedFilterOptions,
	initialSortState
}: TemplateTableProps) {

	const [sorting, setSorting] = React.useState<SortingState>(initialSortState || [])
	const [filtering, setFiltering] = useState('')
	const checkbox = useRef<HTMLInputElement>(null)
	const [checked, setChecked] = useState(false)
	const [indeterminate, setIndeterminate] = useState(false)
	const [showErrorModal, setShowErrorModal] = useState(false)
	const [errorBody, setErrorBody] = useState(<div></div>)
	const table = useReactTable<TableDataTypes>({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		initialState: {
			pagination: {
				pageIndex: 0, //custom initial page index
				pageSize: 10, //custom default page size
			},
		},
		state: {
			sorting: sorting,
			globalFilter: filtering,
		},
		onSortingChange: setSorting,
		onGlobalFilterChange: setFiltering,
	})

	useLayoutEffect(() => {
		if (!selectedPeople) return
		const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < data.length;
		if (data.length) {
			setChecked(selectedPeople.length === data.length);
		}
		setIndeterminate(isIndeterminate);
		if (checkbox.current) {
			checkbox.current.indeterminate = isIndeterminate
		}
	}, [selectedPeople]);

	function toggleAll() {
		if (!selectedPeople || !setSelectedPeople) return
		if (!selectedPeople.length) {
			setSelectedPeople((prevSelectedPeople) =>
				prevSelectedPeople.length === data.length ? [] : [...data]
			);
			setChecked(!checked && !indeterminate);
			setIndeterminate(false);
		} else {
			setSelectedPeople([])
			setChecked(false)
			setIndeterminate(false)
		}

	}

	const showSortingCheckbox = !!selectedPeople
	const showTestStatusFilterOptions =
		!!testStatusFilterOptions &&
		!!testStatusSelectedFilterOptions &&
		!!setTestStatusSelectedFilterOptions

	let currentUrl = window.location.href.split("/").slice(-1)[0];

	const totalRowCount = data.length
	let pageChoices = [10, 20, 30, 40, 50, 100]
	pageChoices = pageChoices.filter((choice) => choice <= totalRowCount)
	if (pageChoices.includes(totalRowCount) === false) {
		pageChoices.push(totalRowCount)
	}

	return (
		<>
			<div className=''>
				<div className="mt-4">
					<div className="flex-col">
						<div className="px-2">
							<div className="sm:flex sm:items-end">
								<div className="sm:flex-auto">
									<label htmlFor="search" className="block text-sm font-medium leading-6 text-gray-900">
										Search
									</label>
									<input
										autoFocus
										id="search"
										name="search"
										type='text'
										value={filtering}
										onChange={e => setFiltering(e.target.value)}
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
									/>
								</div>
								<div hidden={disableCreate} className="mt-4 sm:ml-2 sm:mt-0 sm:flex-none">
									<button
										id="createButton"
										onClick={openCreateModal}
										type="button"
										className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
									>
										Create {object_name}
									</button>
								</div>
							</div>
							{UnderComponent && <UnderComponent />}
							{/* {showTestStatusFilterOptions && <Select multiple={true} onChange={setTestStatusSelectedFilterOptions} value={testStatusSelectedFilterOptions} options={testStatusFilterOptions} />} */}
							<div className="flex items-start">
								{showSortingCheckbox && currentUrl === PathForEntered && <button
									{...{ dataset: { tooltipTarget: selectedPeople.length && "user-selected-tooltip" } }}
									id="issue"
									type="button"
									disabled={!selectedPeople.length}
									title={selectedPeople.length ? "" : "A user must be selected"}
									className={`block w-1/24 h-auto text-center mr-auto mt-4 bg-green-600 rounded-md border-0 p-2 text-white shadow-sm ring-1 ring-inset cursor-pointer focus:ring-green-600 sm:text-sm sm:leading-6 outline-none ${selectedPeople.length ? "hover:bg-green-700" : "hover:bg-red-500 cursor-not-allowed"}`}
									//Update Candidate Status 
									onClick={async () => {
										const response = await CommenceTesting({ candidates: selectedPeople.map(selectedPerson => selectedPerson.id) });
										if (response.ok) {
											//
											alert('Candidate(s) have been been processed for testing');
											window.location.reload();
											//
										} else {
											//
											const body = await response.json()
											//
											// console.log({ body })
											//
											// Create a list of unique questions, to put a question in if we haven't already.
											var uniqueQuestions: any = [];
											//
											// Go through each question, one at a time.
											body.questions.forEach(function (question: any) {
												// The code within the following block runs only if the
												// current question does NOT exist in the uniqueQuestions list
												// - a.k.a. prevent duplicates
												if (uniqueQuestions.filter((questionFilter: any) => question.test === questionFilter.test && question.question === questionFilter.question && question.occupation === questionFilter.occupation).length === 0) {
													// Since we now know we haven't seen this question before,
													// copy it to the end of the uniqueQuestions list.
													uniqueQuestions.push(question);
												}
											});
											//
											// console.log({ uniqueQuestions })
											//
											setErrorBody(CommenceTestingErrorBody({
												questions: uniqueQuestions
											}))
											//
											setShowErrorModal(true)
											//
										}
									}}
								> Commence Testing
									<div id="user-selected-tooltip" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-250 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
										A User Must Be Selected
										<div className="tooltip-arrow" data-popper-arrow></div>
									</div>
								</button>}
								{showSortingCheckbox && currentUrl === PathForTestCompleted && <button
									{...{ dataset: { tooltipTarget: selectedPeople.length && "user-selected-tooltip" } }}
									id="issue"
									type="button"
									disabled={!selectedPeople.length}
									title={selectedPeople.length ? "" : "A user must be selected"}
									className={`flex h-auto mr-2 px-4 mt-4 bg-green-600 rounded-md border-0 p-2 text-white shadow-sm ring-1 ring-inset cursor-pointer focus:ring-green-600 sm:text-sm sm:leading-6 outline-none ${selectedPeople.length ? "hover:bg-green-700" : "hover:bg-red-500 cursor-not-allowed"}`}
									//Update Candidate Status 
									onClick={async () => {
										//
										function onlyUnique(value: any, index: any, array: any) {
											return array.indexOf(value) === index;
										}
										//
										const candidates = selectedPeople.map(selectedPerson => selectedPerson.user_id)
										const uniqueCandiates = candidates.filter(onlyUnique)
										//
										const response = await Advance({ candidates: uniqueCandiates });
										//
										if (response.ok) {
											//
											alert('Candidate(s) have been advanced to the next stage');
											window.location.reload();
											//
										} else {
											alert('Something has gone wrong. Please contact Peloton for assistance.');
										}
										//
									}}
								>
									Advance
									<div id="user-selected-tooltip" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-250 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
										A User Must Be Selected
										<div className="tooltip-arrow" data-popper-arrow></div>
									</div>
								</button>}
								{showSortingCheckbox && currentUrl === PathForTestCompleted && <button
									{...{ dataset: { tooltipTarget: selectedPeople.length && "user-selected-tooltip" } }}
									id="issue"
									type="button"
									disabled={!selectedPeople.length}
									title={selectedPeople.length ? "" : "A user must be selected"}
									className={`flex h-auto mt-4 px-6 bg-green-600 rounded-md border-0 p-2 text-white shadow-sm ring-1 ring-inset cursor-pointer focus:ring-green-600 sm:text-sm sm:leading-6 outline-none ${selectedPeople.length ? "hover:bg-green-700" : "hover:bg-red-500 cursor-not-allowed"}`}
									//Update Candidate Status 
									onClick={async () => {
										//
										function onlyUnique(value: any, index: any, array: any) {
											return array.indexOf(value) === index;
										}
										//
										const candidates = selectedPeople.map(selectedPerson => selectedPerson.user_id)
										const uniqueCandiates = candidates.filter(onlyUnique)
										//
										const response = await Archive({ candidates: uniqueCandiates });
										if (response.ok) {
											//
											alert('Candidate(s) have been Rejected');
											window.location.reload();
											//
										} else {
											alert('Something has gone wrong. Please contact Peloton for assistance.');
										}
										//
									}}
								>
									Reject/Archive
									<div id="user-selected-tooltip" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-250 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
										A User Must Be Selected
										<div className="tooltip-arrow" data-popper-arrow></div>
									</div>
								</button>}
							</div>
							<div className="mt-8 ">
								<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
										<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
											<table className="min-w-full divide-y divide-gray-300">
												<thead className="bg-gray-50">
													{table.getHeaderGroups().map(headerGroup => (
														<tr key={headerGroup.id}>
															{showSortingCheckbox && <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
																<input
																	type="checkbox"
																	className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
																	ref={checkbox}
																	checked={checked}
																	onChange={toggleAll}
																/>
															</th>}
															{headerGroup.headers.map(header => (
																<th
																	key={header.id}
																	onClick={header.column.getToggleSortingHandler()}
																	className="px-3 py-3.5 text-xs font-semibold text-gray-900 cursor-pointer text-center select-none"
																>
																	{header.isPlaceholder ? null : (
																		<div>
																			{flexRender(
																				header.column.columnDef.header,
																				header.getContext()
																			)}
																			{
																				!header.column.getIsSorted() ? "" : header.column.getIsSorted() === "asc" ? '  🔼' : '  🔽'
																			/* {
																				// { asc: '🔼', desc: '🔽' }[
																				// header.column.getIsSorted() ?? null
																				// ]
																			} */}
																		</div>
																	)}
																</th>
															))}
														</tr>
													))}
												</thead>

												<tbody className="divide-y divide-gray-200 bg-white overflow-auto">
													{table.getRowModel().rows.map(row => {
														return (
															<tr
																key={row.id}
																className={`hover:bg-blue-600 text-gray-500 hover:text-white hover:cursor-pointer text-center bg-white odd:bg-gray-100`}
																onClick={(e: React.MouseEvent<HTMLElement>) => {
																	if (!(e.target instanceof HTMLElement && e.target.dataset.checkbox)) {
																		openMaintainModal(row.original.id);
																	}
																}}
															>
																{showSortingCheckbox && <td hidden={!bulk} className="relative px-7 sm:w-12 sm:px-6">
																	<input
																		data-checkbox={true}
																		type="checkbox"
																		className="absolute z-4 left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
																		checked={selectedPeople?.includes(row.original)}
																		onChange={(e) => {
																			e.stopPropagation()
																			if (!selectedPeople || !setSelectedPeople) return
																			//
																			console.log(row.original.id)
																			//
																			let newPeople: any = []
																			if (e.target.checked) {
																				//@ts-ignore
																				const allMatching = data.filter((p) => p.user_id === row.original.user_id)
																				newPeople = [...selectedPeople, ...allMatching]
																			} else {
																				//@ts-ignore
																				newPeople = selectedPeople.filter((p) => p.user_id !== row.original.user_id)
																			}
																			//
																			setSelectedPeople(newPeople)
																			// setSelectedPeople(
																			// 	e.target.checked
																			// 		? [...selectedPeople, row.original]
																			// 		: selectedPeople.filter((p) => p !== row.original)
																			// )
																		}
																		}
																	/>
																</td>}
																{row.getVisibleCells().map(cell => (
																	<td key={cell.id} className="whitespace-nowrap px-3 py-4 text-xs">
																		{flexRender(cell.column.columnDef.cell, cell.getContext())}
																	</td>
																))}
															</tr>
														)
													})}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div className={`grid grid-cols-4 divide-x-2 mt-4 ${hidePageButtons ? 'hidden' : ''}`}>
								<div className="flex w-full justify-center bg-slate-400 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
    focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 rounded-s-lg">
									Rows:
								</div>
								<div className="flex w-full justify-center bg-slate-400 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
    focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
    focus-visible:outline-slate-500">
									{table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} - {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + table.getRowModel().rows.length} of {data.length}
								</div>
								<div className="flex w-full justify-center bg-slate-400 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
    focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
									Pages:
								</div>
								<div className="w-full">
									<select
										value={table.getState().pagination.pageSize}
										onChange={e => {
											table.setPageSize(Number(e.target.value))
										}}
										className="flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold leading-6 text-black shadow-sm
		cursor-pointer rounded-e-lg outline-none focus-visible:outline-none rounded-r-lg border-gray-700 -mr-1"
									>
										{pageChoices.map(pageSize => (
											<option key={pageSize} value={pageSize} className="text-center">
												{pageSize}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className={`grid grid-cols-4 divide-x-2 mt-4 ${hidePageButtons ? 'hidden' : ''}`}>
								<button onClick={() => table.setPageIndex(0)}
									className="flex w-full justify-center bg-indigo-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
    hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
    focus-visible:outline-indigo-500 cursor-pointer rounded-s-lg"
								>
									First page
								</button>
								<button disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}
									className="flex w-full justify-center bg-indigo-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
    hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
    focus-visible:outline-indigo-500 cursor-pointer"
								>
									Previous page
								</button>
								{/* <div className="flex w-full justify-center align-middle text-md text-slate-800">
    <p className="mt-2">Page 1 of 200</p>
  </div> */}
								<button disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}
									className="flex w-full justify-center bg-indigo-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
    hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
    focus-visible:outline-indigo-500 cursor-pointer"
								>
									Next page
								</button>
								<button onClick={() => table.setPageIndex(table.getPageCount() - 1)}
									className="flex w-full justify-center bg-indigo-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
    hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
    focus-visible:outline-indigo-500 cursor-pointer rounded-e-lg"
								>
									Last page
								</button>
							</div>
						</div>
					</div >
				</div >
			</div >
			<MessageModal messageModalOpen={showErrorModal} setMessageModalOpen={setShowErrorModal}>
				{errorBody}
				<button
					onClick={() => setShowErrorModal(false)}
					className="block w-full bg-blue-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 outline-none mt-8"
				>
					Close
				</button>
			</MessageModal>
		</>

	)
}