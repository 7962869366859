import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TemplateTable from "../../components/TemplateTable"
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Archive, CandidateCreate, CandidateDelete, CandidateIndex, CandidateShow, CandidateUpdate, CommenceTesting } from '../../lib/backend/candidate'
import SelectElement from '../../components/SelectElement'
import Gender from '../../components/test-widgets/Gender'
import HighSchool from '../../components/test-widgets/HighSchool'
import Occupation from '../../components/test-widgets/Occupation'
import College from '../../components/test-widgets/College'
import Vocational from '../../components/test-widgets/Vocational'
import { OccupationIndex } from '../../lib/backend/occupation'
import { JobIndex } from '../../lib/backend/job'
import CandidateJobs from '../../components/CandidateJobs'
import { ClientIndex } from '../../lib/backend/client'
import { User } from '../../react-app-env'
import MessageModal from '../../components/MessageModal'

const columns = [
	{
		header: "First Name",
		accessorKey: "first_name",
	},
	{
		header: "Last Name",
		accessorKey: "last_name",
	},
	{
		header: "Status",
		accessorKey: "candidate_status",
	},
	{
		header: "Occupation",
		accessorKey: "occupation_name",
	},
	{
		header: "Client",
		accessorKey: "client_name",
	},
	{
		header: "Candidate Status Updated",
		accessorKey: "date_changed",
	},
	{
		header: "Days in Testing",
		accessorKey: "days_awaiting",
	},
	{
		header: "Email",
		accessorKey: "email",
	},
]

const issues = [
	{
		value: "all",
		label: "All"
	},
	{
		value: "No Issues",
		label: "No Issues"
	},
	// {
	// 	value: "Rejected",
	// 	label: "Rejected"
	// },
	// {
	// 	value: "Approved",
	// 	label: "Approved"
	// },
	// {
	// 	value: "Do Not Hire",
	// 	label: "Do Not Hire"
	// },
	// {
	// 	value: "Expired",
	// 	label: "Expired"
	// },
]

export default function CandidatessPageEntered({ title = "Awaiting Testing" }: { title?: string }) {
	const [loadingData, setLoadingData] = useState(true)
	const [tableData, setTableData] = useState<any>([])
	const [originalTableData, setOriginalTableData] = useState<any>([])

	const [occupationTableData, setOccupationTableData] = useState<any>([])
	const [jobTableData, setJobTableData] = useState<any>([])
	const [clientTableData, setClientTableData] = useState<any>([])

	// const [files, setFiles] = useState([])
	// const [filesLoading, setFilesLoading] = useState(true)

	const [commenceLoading, setCommenceLoading] = useState(false)

	const [createModalOpen, setCreateModalOpen] = useState(false)
	const [createModalLoading, setcreateModalLoading] = useState(false)
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [editModalLoading, setEditModalLoading] = useState(false)
	const [showCreateError, setShowCreateError] = useState(false)
	const [showMaintainError, setShowMaintainError] = useState(false)

	const [confirmModalOpen, setConfirmModalOpen] = useState(false)

	const [validName, setValidName] = useState(false)

	const [approveConfirmOpen, setApproveConfirmOpen] = useState(false)
	const [rejectConfirmOpen, setRejectConfirmOpen] = useState(false)

	const [formDataID, setFormDataID] = useState(0)
	const [formDataEmail, setFormDataEmail] = useState("")
	const [formDataFirstName, setFormDataFirstName] = useState("")
	const [formDataLastName, setFormDataLastName] = useState("")
	const [formDataPhoneNumber, setFormDataPhoneNumber] = useState("")
	const [formDataStreetAddress, setFormDataStreetAddress] = useState("")
	const [formDataCity, setFormDataCity] = useState("")
	const [formDataStateProvince, setFormDataStateProvince] = useState("")
	const [formDataZipPostalCode, setFormDataZipPostalCode] = useState("")
	const [formDataCountry, setFormDataCountry] = useState("")
	const [formDataGender, setFormDataGender] = useState("Male")
	const [formDataHighSchool, setFormDataHighSchool] = useState("No")
	const [formDataVocational, setFormDataVocational] = useState("No")
	const [formDataCollege, setFormDataCollege] = useState("No")
	const [formDataOccupation, setFormDataOccupation] = useState(0)
	const [formDataWorkHistoryYears, setFormDataWorkHistoryYears] = useState(0)

	const [formDataJobs, setFormDataJobs] = useState<{ id: number, job_id: number }[]>([])
	const [formDataJobId, setFormDataJobId] = useState<number>(0)

	const [formDataNotes, setFormDataNotes] = useState("")

	const [statusType, setStatusType] = useState({ value: "all", label: "All" })
	const [issueType, setIssueType] = useState({ value: "all", label: "All" })
	const [selectedPeople, setSelectedPeople] = useState<User[]>([]);

	const [formDisabled, setFormDisabled] = useState<boolean>(true)

	const cancelButtonRef = useRef(null)

	const handleFocus = (event: any) => event.target.select();

	useEffect(() => {
		//
		setTableData(originalTableData.filter((row: any) => {
			if (statusType.value === "all" && issueType.value === "all") {
				return true
			}
			if (statusType.value === row.candidate_status && issueType.value === "all") {
				return true
			}
			if (issueType.value === row.issue && statusType.value === "all") {
				return true
			}
			if (issueType.value === row.issue && statusType.value === row.candidate_status) {
				return true
			}
			return false
		}))
		//
	}, [issueType, statusType, originalTableData])

	useEffect(() => {
		getData()
	}, [])

	async function getData() {
		const occupations = await fetchOccupations()
		// const jobs = await fetchJobs()
		//
		const candidates = await CandidateIndex("Awaiting Testing")
		const candidatesJson = await candidates.json()
		//
		candidatesJson.forEach((candidate: User) => candidate.occupation_name = occupations[candidate.occupation_id - 1]?.label)
		//
		candidatesJson.forEach((candidate: User) => candidate.date_changed = new Date(candidate.updated_at).toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' }));
		//
		candidatesJson.forEach((candidate: User) => candidate.days_awaiting = Math.floor((new Date().getTime() - new Date(candidate.updated_at).getTime()) / (1000 * 60 * 60 * 24)));
		//
		// candidatesJson.forEach((candidate: User) => candidate.client_name = jobs[candidate.client_id -1]?.client_name )
		//
		// console.log(clientTableData)
		//
		setTableData(candidatesJson);
		setOriginalTableData(candidatesJson);
		//
		setLoadingData(false)
		//
	}

	async function fetchOccupations() {
		//
		const occupations = await OccupationIndex();
		const occupationsJson = await occupations.json()
		occupationsJson.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
		//
		const cleanOccupationData: { id: number, label: string }[] = occupationsJson.map((occ: any) => {
			return {
				id: occ.id,
				label: occ.occupation_name
			}
		})
		//
		setOccupationTableData(cleanOccupationData);
		//
		return (cleanOccupationData)
	}

	async function fetchJobs() {
		//
		const jobs = await JobIndex();
		const jobsJson = await jobs.json()
		//
		// setJobTableData(jobsJson)
		//
		const clients = await ClientIndex();
		const clientsJson = await clients.json()
		//
		setClientTableData(clientsJson)
		//
		const updatedJobs = jobsJson.map((job: any) => {
			const clients = clientsJson.filter((client: any) => {
				return client.id === job.client_id
			})
			job.client_name = clients.length > 0 ? clients[0].client_name : ""
			return job
		})
		//
		//
		setJobTableData(updatedJobs)
		return (updatedJobs)
		//
	}

	async function openCreateModal() {
		//
		setcreateModalLoading(true)
		setCreateModalOpen(true)
		//
		// await fetchOccupations()
		await fetchJobs()
		//
		setFormDataEmail("")
		setFormDataFirstName("")
		setFormDataLastName("")
		setFormDataPhoneNumber("")
		setFormDataStreetAddress("")
		setFormDataCity("")
		setFormDataStateProvince("")
		setFormDataZipPostalCode("")
		setFormDataCountry("")
		//
		setFormDataGender("Male")
		setFormDataHighSchool("No")
		setFormDataVocational("No")
		setFormDataCollege("No")
		setFormDataOccupation(0)
		setFormDataWorkHistoryYears(0)
		//
		// setFormDataJobs([])
		setFormDataJobId(0)
		//
		setFormDataNotes("")
		//
		setcreateModalLoading(false)
		setShowCreateError(false)
	}

	async function openMaintainModal(id: number) {
		//
		setEditModalLoading(true)
		setEditModalOpen(true)
		//
		setFormDataID(id)
		//
		// getFilesData(id)
		await fetchOccupations()
		await fetchJobs()
		//
		const response = await CandidateShow(id)
		const body = await response.json()
		//
		setFormDataEmail(body.email)
		setFormDataFirstName(body.first_name)
		setFormDataLastName(body.last_name)
		setFormDataPhoneNumber(body.phone_number)
		//
		setFormDataStreetAddress(body.street_address)
		setFormDataCity(body.city)
		setFormDataStateProvince(body.state_province)
		setFormDataZipPostalCode(body.zip_postal_code)
		setFormDataCountry(body.country)
		//
		setFormDataGender(body.gender || "Male")
		setFormDataHighSchool(body.high_school || "No")
		setFormDataVocational(body.vocational || "No")
		setFormDataCollege(body.college || "No")
		setFormDataOccupation(parseInt(body.occupation_id) || 0)
		setFormDataWorkHistoryYears(parseInt(body.work_history_years) || 0)
		//
		// setFormDataJobs(body.jobs || [])
		setFormDataJobId(parseInt(body.job_id) || 0)
		//
		setFormDataNotes(body.notes || "")
		//
		formDataValidation()
		//
		setEditModalLoading(false)
		//
	}

	async function submitMaintainForm(event: any) {
		//
		event.preventDefault();
		//
		// setEditModalLoading(true)
		setShowMaintainError(true)
		//
		const response = await CandidateUpdate(formDataID, {
			candidate: {
				//
				email: formDataEmail,
				first_name: formDataFirstName,
				last_name: formDataLastName,
				phone_number: formDataPhoneNumber,
				//
				street_address: formDataStreetAddress,
				city: formDataCity,
				state_province: formDataStateProvince,
				zip_postal_code: formDataZipPostalCode,
				country: formDataCountry,
				//
				gender: formDataGender,
				high_school: formDataHighSchool,
				vocational: formDataVocational,
				college: formDataCollege,
				occupation_id: formDataOccupation,
				work_history_years: formDataWorkHistoryYears,
				//
				job_id: formDataJobId,
				// jobs: formDataJobs,
				//
				notes: formDataNotes,
				//
			}
		})
		//
		if (response.ok) {
			//
			const body = await response.json()
			//
			const tableDataUpdated: any = originalTableData.map((tableDataRow: any) => {
				if (tableDataRow.id === formDataID) {
					tableDataRow.first_name = body.first_name
					tableDataRow.last_name = body.last_name
					tableDataRow.candidate_status = body.candidate_status
					tableDataRow.issue = body.issue
					tableDataRow.job_name = body.job_name
					tableDataRow.occupation_name = body.occupation_name
				}
				return tableDataRow
			})
			setTableData(tableDataUpdated)
			setOriginalTableData(tableDataUpdated)
			setEditModalOpen(false)
		} else {
			setEditModalLoading(false)
			setShowMaintainError(true)
		}
	}

	async function submitArchive(event: any) {
		//
		if (event) {
			event.preventDefault()
		}
		setEditModalLoading(true)
		//
		const response = await Archive({ candidates: [formDataID] })
		//
		if (response.ok) {
			const tableDataUpdated: any = originalTableData.filter((tableDataRow: any) => {
				return tableDataRow.id !== formDataID
			})
			setTableData(tableDataUpdated)
			setOriginalTableData(tableDataUpdated)
			setEditModalOpen(false)
		} else {
			setShowMaintainError(true)
			setEditModalLoading(false)
		}
	}

	// async function submitDelete(event: any) {
	// 	//
	// 	event.preventDefault()
	// 	setEditModalLoading(true)
	// 	//
	// 	const response = await CandidateDelete(formDataID)
	// 	//
	// 	if (response.ok) {
	// 		const tableDataUpdated: any = originalTableData.filter((tableDataRow: any) => {
	// 			return tableDataRow.id !== formDataID
	// 		})
	// 		setTableData(tableDataUpdated)
	// 		setOriginalTableData(tableDataUpdated)
	// 		setEditModalOpen(false)
	// 	} else {
	// 		setShowMaintainError(true)
	// 		setEditModalLoading(false)
	// 	}
	// }

	async function commenceTesting() {
		//
		setCommenceLoading(true)
		//
		const selectedIds = selectedPeople.map(x => x.id)
		const response = await CommenceTesting({
			candidates: selectedIds
		})
		//
		setCommenceLoading(false)
		//
		// const response = await CandidateDelete(formDataID)
		// //
		if (response.ok) {
			//
			const body = await response.json()
			//
			const tableDataUpdated: any = originalTableData.filter((tableDataRow: any) => {
				return selectedIds.includes(formDataID)
			})
			setTableData(tableDataUpdated)
			setOriginalTableData(tableDataUpdated)
			setEditModalOpen(false)
		} else {
			setEditModalLoading(false)
			setShowMaintainError(true)
		}
	}

	function formDataValidation() {
		// if (formDataName.length > 0) {
		// 	setValidName(true)
		// } else {
		// 	setValidName(false)
		// }
	}

	return (
		<>
			<div className="text-2xl font-semibold">
				{title}
			</div>
			<div role="status" className="mt-8" hidden={!loadingData}>
				<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
					<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
				</svg>
			</div>
			<div hidden={loadingData}>
				{/* <TemplateTable selectedPeople={selectedPeople} setSelectedPeople={setSelectedPeople}  */}

				<TemplateTable
					data={tableData}
					columns={columns}
					openCreateModal={openCreateModal}
					openMaintainModal={openMaintainModal}
					object_name="Candidate"
					disableCreate={true}
					bulk={true}
					hidePageButtons={false}
					initialSortState={[{ id: "first_name", desc: false }]}
					UnderComponent={() => {
						return <div className="sm:flex sm:items-end w-full mt-5 gap-4">
							{/* <SelectElement items={issues} selected={issueType} setSelected={setIssueType} title="Issue" ></SelectElement> */}
						</div>
					}} />
			</div>
			<Transition.Root show={editModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={() => { }}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 xl:inset-x-72 xl:right-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
									<div className="w-full" hidden={!editModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={editModalLoading} onSubmit={submitMaintainForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														About Candidate
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setEditModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div className="space-y-4">
														<div hidden={!showMaintainError} className="text-red-400 text-center">
															Something has gone wrong
														</div>
														<div className="space-y-12">
															<div className="">
																<h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
																<div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">

																	<div className="sm:col-span-2">
																		<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
																			Email address
																		</label>
																		<div className="mt-2">
																			<input
																				disabled
																				id="email"
																				name="email"
																				type="email"
																				autoComplete="email"
																				required
																				onChange={(e) => setFormDataEmail(e.target.value)}
																				value={formDataEmail}
																				className="block w-full rounded-md border-0 py-1.5 bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																	<div className="sm:col-span-1">
																		<label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
																			First Name
																		</label>
																		<div className="mt-2">
																			<input
																				disabled
																				id="first_name"
																				name="first_name"
																				type="text"
																				autoComplete="first_name"
																				onChange={(e) => setFormDataFirstName(e.target.value)}
																				value={formDataFirstName}
																				className="block w-full rounded-md border-0 py-1.5  bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																	<div className="sm:col-span-1">
																		<label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
																			Last Name
																		</label>
																		<div className="mt-2">
																			<input
																				disabled
																				id="last_name"
																				name="last_name"
																				type="text"
																				autoComplete="last_name"
																				onChange={(e) => setFormDataLastName(e.target.value)}
																				value={formDataLastName}
																				className="block w-full rounded-md border-0 py-1.5  bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																	<div className="sm:col-span-1">
																		<label htmlFor="phone_number" className="block text-sm font-medium leading-6 text-gray-900">
																			Phone number
																		</label>
																		<div className="mt-2">
																			<input
																				disabled
																				id="phone_number"
																				name="phone_number"
																				type="text"
																				autoComplete="phone_number"
																				onChange={(e) => setFormDataPhoneNumber(e.target.value)}
																				value={formDataPhoneNumber}
																				className="block w-full rounded-md border-0 py-1.5  bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>

																</div>
															</div>

															<div className="border-b border-gray-900/10 pb-12">
																<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

																	<div className="col-span-full">
																		<label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
																			Street address
																		</label>
																		<div className="mt-2">
																			<input
																				disabled
																				type="text"
																				name="street-address"
																				id="street-address"
																				autoComplete="street-address"
																				onChange={(e) => setFormDataStreetAddress(e.target.value)}
																				value={formDataStreetAddress}
																				className="block w-full rounded-md border-0 py-1.5  bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>

																	<div className="sm:col-span-2 sm:col-start-1">
																		<label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
																			City
																		</label>
																		<div className="mt-2">
																			<input
																				disabled
																				type="text"
																				name="city"
																				id="city"
																				autoComplete="address-level2"
																				onChange={(e) => setFormDataCity(e.target.value)}
																				value={formDataCity}
																				className="block w-full rounded-md border-0 py-1.5  bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>

																	<div className="sm:col-span-2">
																		<label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
																			State / Province
																		</label>
																		<div className="mt-2">
																			<input
																				disabled
																				type="text"
																				name="region"
																				id="region"
																				autoComplete="address-level1"
																				onChange={(e) => setFormDataStateProvince(e.target.value)}
																				value={formDataStateProvince}
																				className="block w-full rounded-md border-0 py-1.5  bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>

																	<div className="sm:col-span-2">
																		<label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
																			ZIP / Postal code
																		</label>
																		<div className="mt-2">
																			<input
																				disabled
																				type="text"
																				name="postal-code"
																				id="postal-code"
																				autoComplete="postal-code"
																				onChange={(e) => setFormDataZipPostalCode(e.target.value)}
																				value={formDataZipPostalCode}
																				className="block w-full rounded-md border-0 py-1.5  bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																	<div className="col-span-full">
																		<label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
																			Country
																		</label>
																		<div className="mt-2">
																			<input
																				disabled
																				type="text"
																				name="country"
																				id="country"
																				autoComplete="country"
																				onChange={(e) => setFormDataCountry(e.target.value)}
																				value={formDataCountry}
																				className="block w-full rounded-md border-0 py-1.5  bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div className="border-b border-gray-900/10 pb-8 space-y-6">
															<h2 className="text-base font-semibold leading-7 text-gray-900 mt-2">Demographics</h2>
															<div className="sm:col-span-2 sm:col-start-1 mt-6 pointer-events-none cursor-not-allowed">
																<div className="mt-2">
																	<Gender formDataGender={formDataGender} setFormDataGender={setFormDataGender}></Gender>
																</div>
															</div>
															<div className="sm:col-span-2 sm:col-start-1 pointer-events-none cursor-not-allowed">
																<div className="mt-2">
																	<HighSchool formDataHighSchool={formDataHighSchool} setFormDataHighSchool={setFormDataHighSchool}></HighSchool>
																</div>
															</div>
															<div className="sm:col-span-2 sm:col-start-1">
																<div className="mt-2 pointer-events-none cursor-not-allowed">
																	<Vocational formDataVocational={formDataVocational} setFormDataVocational={setFormDataVocational}></Vocational>
																</div>
															</div>
															<div className="sm:col-span-2 sm:col-start-1">
																<div className="mt-2 pointer-events-none cursor-not-allowed">
																	<College formDataCollege={formDataCollege} setFormDataCollege={setFormDataCollege}></College>
																</div>
															</div>
															<div className="sm:col-span-2 sm:col-start-1">
																<div className="mt-2 pointer-events-none cursor-not-allowed">
																	<Occupation formDataOccupation={formDataOccupation} setFormDataOccupation={setFormDataOccupation} list={occupationTableData}></Occupation>
																</div>
															</div>

															<div className="sm:col-span-2">
																<label htmlFor="work_history_years" className="block text-sm font-medium leading-6 text-gray-900">
																	Work History (Years)
																</label>
																<div className="mt-2 pointer-events-none cursor-not-allowed">
																	<input
																		disabled
																		type="number"
																		name="work_history_years"
																		id="work_history_years"
																		autoComplete="address-level1"
																		onFocus={handleFocus}
																		onChange={(e) => setFormDataWorkHistoryYears(e.target.value ? parseInt(e.target.value) : 0)}
																		value={formDataWorkHistoryYears}
																		className="block bg-gray-100 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																	/>
																</div>
															</div>

														</div>

														<div className="pointer-events-none border-b border-gray-900/10 pb-8 space-y-6">
															<h2 className="text-base font-semibold leading-7 text-gray-900 mt-2">Jobs</h2>
															<CandidateJobs setJobId={setFormDataJobId} masterJobList={jobTableData} jobId={formDataJobId} />
														</div>

														<div className="mt-4">
															<label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">
																Notes
															</label>
															<div className="mt-2 pointer-events-none ">
																<textarea
																	disabled
																	id="notes"
																	name="notes"
																	rows={4}
																	autoComplete="notes"
																	value={formDataNotes}
																	onChange={(e) => {
																		setFormDataNotes(e.target.value)
																		formDataValidation()
																	}}
																	className="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																/>
															</div>
														</div>

														<div>
															{/* <button
                                id="role"
                                type="button"
                                disabled={commenceLoading}
                                className="block w-full bg-green-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 outline-none"
                                onClick={commenceTesting}
                              >
                                {commenceLoading ? "Loading..." : "Commence Testing"}
                              </button> */}
														</div>
														<div>
															<button
																id="role"
																type="button"
																// disabled={true}
																className="block w-full bg-red-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 outline-none"
																onClick={() => setConfirmModalOpen(true)}
															>
																Archive Candidate
															</button>
														</div>
													</div>
												</div>
											</div>
											<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													disabled
													type="submit"
													// disabled={!validName}
													className=" cursor-not-allowed inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Update
													<div id="user-selected-tooltip" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-250 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
														Candidate may not be edited while awaiting testing.
														<div className="tooltip-arrow" data-popper-arrow></div>
													</div>
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setEditModalOpen(false)}
													ref={cancelButtonRef}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<MessageModal messageModalOpen={confirmModalOpen} setMessageModalOpen={setConfirmModalOpen}>
				<h2 className="w-full text-xl font-bold">
					Are you sure you would like to archive this candidate?
				</h2>
				<div className="flex">
					<button
						autoFocus={true}
						onClick={() => setConfirmModalOpen(false)}
						className="w-full mr-3 bg-blue-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 outline-none mt-8 border-none"
					>
						Cancel
					</button>
					<button
						onClick={() => {
							setConfirmModalOpen(false)
							submitArchive(false)
						}}
						className="w-full bg-red-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 outline-none mt-8 border-none"
					>
						Archive
					</button>
				</div>
			</MessageModal>

		</>
	)
}