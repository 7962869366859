import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TemplateTable from "../../components/TemplateTable"
import { XMarkIcon } from '@heroicons/react/24/outline'
import { OccupationTestCreate, OccupationTestDelete, OccupationTestIndex, OccupationTestShow, OccupationTestUpdate } from '../../lib/backend/occupation-test'
import { OccupationIndex } from '../../lib/backend/occupation'
import { TestIndex } from '../../lib/backend/test'
import { Occupation } from "../../../../backend/models/occupation"
import { Test } from "../../../../backend/models/test"
import SelectElement from '../../components/SelectElement'
import MessageModal from '../../components/MessageModal'

const columns = [
	{
		header: "Occupation Name",
		accessorKey: "occupation_name",
	},
	{
		header: "Test Name",
		accessorKey: "test_code",
	},
]

export default function OccupationTestPage() {
	const [loadingData, setLoadingData] = useState(true)
	const [tableData, setTableData] = useState<any>([])
	const [originalTableData, setOriginalTableData] = useState<any>([])

	const [createModalOpen, setCreateModalOpen] = useState(false)
	const [createModalLoading, setcreateModalLoading] = useState(false)
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [editModalLoading, setEditModalLoading] = useState(false)
	const [showCreateError, setShowCreateError] = useState(false)
	const [showMaintainError, setShowMaintainError] = useState(false)

	const [errorModalOpen, setErrorModalOpen] = useState(false)
	const [errorModalChildren, setErrorModalChildren] = useState(<div></div>)

	const [formDataID, setFormDataID] = useState(0)
	//
	const [formDataOccupationID, setFormDataOccupationID] = useState(0)
	const [formDataTestID, setFormDataTestID] = useState(0)
	//
	const [formDataOccupationFilter, setFormDataOccupationFilter] = useState([])
	const [formDataTestFilter, setFormDataTestFilter] = useState([])
	const [formDataOccupationIDFilter, setFormDataOccupationIDFilter] = useState({ value: "all", label: "All" })
	const [formDataTestIDFilter, setFormDataTestIDFilter] = useState({ value: "all", label: "All" })
	//
	const [occupationList, setOccupationList] = useState([])
	const [testList, setTestList] = useState([])

	const cancelButtonRef = useRef(null)

	useEffect(() => {
		getData()
	}, [])

	async function getData() {
		//
		// setLoadingData(true)
		//
		const occupations = await OccupationTestIndex()
		const occupationTestsJson = await occupations.json()
		//@ts-ignore
		const occupationTestsJsonSorted = occupationTestsJson.sort((a, b) => a.occupation_name.localeCompare(b.occupation_name))
		setTableData(occupationTestsJsonSorted)
		setOriginalTableData(occupationTestsJsonSorted)
		//
		const occupationResult = await OccupationIndex()
		const occupationData = await occupationResult.json()
		const occupationDataOptions = occupationData.map((occRow: Occupation) => {
			return {
				value: occRow.id,
				label: occRow.occupation_name
			}
		})
		occupationDataOptions.unshift({ value: "all", label: "All" })
		setFormDataOccupationFilter(occupationDataOptions)
		setOccupationList(occupationData)
		//
		const testResult = await TestIndex()
		const testData = await testResult.json()
		const testDataFiltered = testData.filter((testRow: Test) => testRow.test_status !== "Retired")
		// console.log({ testDataFiltered })
		const testDataOptions = testDataFiltered.map((testRow: Test) => {
			return {
				value: testRow.id,
				label: testRow.test_code
			}
		})
		testDataOptions.unshift({ value: "all", label: "All" })
		setFormDataTestFilter(testDataOptions)
		setTestList(testDataFiltered)
		//
		setLoadingData(false)
		//
	}

	useEffect(() => {
		//
		//@ts-ignore
		const originalTableDataSorted = originalTableData.sort((a, b) => a.occupation_name.localeCompare(b.occupation_name))
		//
		setTableData(originalTableDataSorted.filter((row: any) => {
			if (formDataOccupationIDFilter.value === "all" && formDataTestIDFilter.value === "all") {
				return true
			}
			if (formDataOccupationIDFilter.value === row.occupation_id && formDataTestIDFilter.value === "all") {
				return true
			}
			if (formDataTestIDFilter.value === row.test_id && formDataOccupationIDFilter.value === "all") {
				return true
			}
			if (formDataTestIDFilter.value === row.test_id && formDataOccupationIDFilter.value === row.occupation_id) {
				return true
			}
			return false
		}))
		//
	}, [formDataTestIDFilter, formDataOccupationIDFilter, originalTableData])

	async function openCreateModal() {
		//
		setCreateModalOpen(true)
		setcreateModalLoading(true)
		//
		setFormDataOccupationID(0)
		setFormDataTestID(0)
		//
		setcreateModalLoading(false)
		setShowCreateError(false)
	}

	async function submitCreateForm(event: any) {
		//
		event.preventDefault();
		//
		setcreateModalLoading(true)
		setShowCreateError(false)
		//
		const createResponse = await OccupationTestCreate({
			occupation_test: {
				//
				occupation_id: formDataOccupationID,
				test_id: formDataTestID,
				//
			}
		})
		//
		const body = await createResponse.json()
		//
		if (createResponse.ok) {
			//
			const originalTableDataUpdated: any = JSON.parse(JSON.stringify(originalTableData))
			originalTableDataUpdated.push({
				id: body.id,
				occupation_name: body.occupation_name,
				test_code: body.test_code,
				occupation_id: body.occupation_id || 0,
				test_id: body.test_id || 0
			})
			setOriginalTableData(originalTableDataUpdated)
			setCreateModalOpen(false)
		} else {
			//
			setErrorModalChildren(TestCheck(body))
			//
			setErrorModalOpen(true)
			//
			setcreateModalLoading(false)
			setShowCreateError(true)
		}
		//
	}

	async function openMaintainModal(id: number) {
		//
		setEditModalLoading(true)
		setEditModalOpen(true)
		//
		setFormDataID(id)
		//
		// const occupationResult = await OccupationIndex()
		// const occupationData = await occupationResult.json()
		// setOccupationList(occupationData)
		// //
		// const testResult = await TestIndex()
		// const testData = await testResult.json()
		// setTestList(testData)
		//
		const response = await OccupationTestShow(id)
		const body = await response.json()
		// console.log({ body })
		//
		setFormDataOccupationID(body.occupation_id)
		setFormDataTestID(body.test_id)
		//
		// formDataValidation()
		//
		setEditModalLoading(false)
		//
	}

	async function submitMaintainForm(event: any) {
		//
		event.preventDefault();
		//
		setEditModalLoading(true)
		setShowMaintainError(false)
		//
		const response = await OccupationTestUpdate(formDataID, {
			occupation_test: {
				//
				occupation_id: formDataOccupationID,
				test_id: formDataTestID,
				//
			}
		})
		//
		const body = await response.json()
		//
		if (response.ok) {
			//
			const tableDataUpdated: any = originalTableData.map((tableDataRow: any) => {
				if (tableDataRow.id === formDataID) {
					tableDataRow.occupation_id = body.occupation_id
					tableDataRow.test_id = body.test_id
					//@ts-ignore
					tableDataRow.occupation_name = occupationList.filter((occRow: Occupation) => occRow.id === body.occupation_id)[0].occupation_name
					//@ts-ignore
					tableDataRow.test_code = testList.filter((testRow: Row) => testRow.id === body.test_id)[0].test_code
				}
				return tableDataRow
			})
			setOriginalTableData(tableDataUpdated)
			setEditModalOpen(false)
		} else {
			//
			setErrorModalChildren(TestCheck(body))
			//
			setErrorModalOpen(true)
			//
			setEditModalLoading(false)
			setShowCreateError(true)
		}
	}

	async function submitDelete(event: any) {
		//
		event.preventDefault()
		setEditModalLoading(true)
		//
		const response = await OccupationTestDelete(formDataID)
		//
		if (response.ok) {
			const tableDataUpdated: any = originalTableData.filter((tableDataRow: any) => {
				return tableDataRow.id !== formDataID
			})
			setOriginalTableData(tableDataUpdated)
			setEditModalOpen(false)
		} else {
			setShowMaintainError(true)
			setEditModalLoading(false)
		}
	}

	// function formDataValidation() {
	// 	// if (formDataOccupationID.length > 0) {
	// 	// 	setValidName(true)
	// 	// } else {
	// 	// 	setValidName(false)
	// 	// }
	// }

	let cleanOccupationList = occupationList
	let cleanTestList = testList
	///@ts-ignore
	if ((formDataOccupationID === 0 || formDataOccupationID === "all") && (formDataTestID === 0 || formDataTestID === "all")) {
		// No problem. Show everything
		///@ts-ignore
	} else if (formDataOccupationID !== 0 && formDataOccupationID !== "all") {
		cleanTestList = cleanTestList.filter((testRow: Test) => {
			const filteredList = originalTableData.filter((tableRow: any) => {
				//
				return tableRow.id !== formDataID && tableRow.occupation_id === formDataOccupationID && testRow.id === tableRow.test_id
				//
			})
			if (filteredList.length > 0) {
				return false
			} else {
				return true
			}
		})
		///@ts-ignore
	} else if (formDataTestID !== 0 && formDataTestID !== "all") {
		cleanOccupationList = cleanOccupationList.filter((occRow: Occupation) => {
			const filteredList = originalTableData.filter((tableRow: any) => {
				//
				return tableRow.id !== formDataID && tableRow.test_id === formDataTestID && occRow.id === tableRow.occupation_id
				//
			})
			if (filteredList.length > 0) {
				return false
			} else {
				return true
			}
		})
	}

	return (
		<div>
			<div className="text-2xl font-semibold">
				Tests by Occupation
			</div>
			<div role="status" className="mt-8" hidden={!loadingData}>
				<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
					<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
				</svg>
			</div>
			<div hidden={loadingData}>
				<TemplateTable
					data={tableData}
					columns={columns}
					openCreateModal={openCreateModal}
					openMaintainModal={openMaintainModal}
					object_name="Occupation/Test Link"
					disableCreate={false}
					bulk={false}
					hidePageButtons={false}
					initialSortState={[{ id: "occupation_name", desc: false }]}
					UnderComponent={() => {
						return <div className="sm:flex sm:items-end w-full mt-5 gap-4">
							<SelectElement items={formDataOccupationFilter} selected={formDataOccupationIDFilter} setSelected={setFormDataOccupationIDFilter} title="Occupation" ></SelectElement>
							<SelectElement items={formDataTestFilter} selected={formDataTestIDFilter} setSelected={setFormDataTestIDFilter} title="Test" ></SelectElement>
						</div>
					}} />
			</div>
			<Transition.Root show={createModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100"
					initialFocus={cancelButtonRef}
					onClose={() => { }}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 xl:inset-x-72 xl:right-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
									<div className="w-full" hidden={!createModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={createModalLoading} onSubmit={submitCreateForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full sm:max-w-sm">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														Create Occupation/Test Link
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setCreateModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div hidden={!showCreateError} className="text-red-400 text-center">
														Something has gone wrong. Please try again.
													</div>
													<div className="space-y-12">
														<div className="border-b border-gray-900/10 pb-12 mt-4">
															<div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">

																<div className="sm:col-span-2">
																	<label htmlFor="occupation_id" className="block text-sm font-medium leading-6 text-gray-900">
																		Occupation
																	</label>
																	<div className="mt-2">
																		<select
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			value={formDataOccupationID}
																			onChange={(e) => { setFormDataOccupationID(parseInt(e.target.value)) }}
																		>
																			<option value={0}>Select an Occupation</option>
																			{cleanOccupationList.map((occupationRow: Occupation, index) => {
																				return <option key={index} value={occupationRow.id}>
																					{occupationRow.occupation_name}
																				</option>
																			})}
																		</select>
																	</div>
																</div>

																<div className="sm:col-span-2">
																	<label htmlFor="test_id" className="block text-sm font-medium leading-6 text-gray-900">
																		Test
																	</label>
																	<div className="mt-2">
																		<select
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			value={formDataTestID}
																			onChange={(e) => { setFormDataTestID(parseInt(e.target.value)) }}
																		>
																			<option value={0}>Select a Test</option>
																			{cleanTestList.map((testRow: Test, index) => {
																				return <option key={index} value={testRow.id}>
																					{testRow.test_code}
																				</option>
																			})}
																		</select>
																	</div>
																</div>

															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													type="submit"
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Create
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setCreateModalOpen(false)}
													ref={cancelButtonRef}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root >


			<Transition.Root show={editModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={() => { }}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 xl:inset-x-72 xl:right-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
									<div className="w-full" hidden={!editModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={editModalLoading} onSubmit={submitMaintainForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														Maintain Occupation/Test Link
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setEditModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div className="space-y-4">
														<div hidden={!showMaintainError} className="text-red-400 text-center">
															Something has gone wrong
														</div>
														<div className="space-y-12">
															<div className="">
																<div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">

																	<div className="sm:col-span-2">
																		<label htmlFor="occupation_id" className="block text-sm font-medium leading-6 text-gray-900">
																			Occupation
																		</label>
																		<div className="mt-2">
																			<select
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																				value={formDataOccupationID}
																				onChange={(e) => { setFormDataOccupationID(parseInt(e.target.value)) }}
																			>
																				<option value={0}>Select an Occupation</option>
																				{cleanOccupationList.map((occupationRow: Occupation, index) => {
																					return <option key={index} value={occupationRow.id}>
																						{occupationRow.occupation_name}
																					</option>
																				})}
																			</select>
																		</div>
																	</div>

																	<div className="sm:col-span-2">
																		<label htmlFor="test_id" className="block text-sm font-medium leading-6 text-gray-900">
																			Test
																		</label>
																		<div className="mt-2">
																			<select
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																				value={formDataTestID}
																				onChange={(e) => { setFormDataTestID(parseInt(e.target.value)) }}
																			>
																				<option value={0}>Select a Test</option>
																				{cleanTestList.map((testRow: Test, index) => {
																					return <option key={index} value={testRow.id}>
																						{testRow.test_code}
																					</option>
																				})}
																			</select>
																		</div>
																	</div>

																</div>
															</div>
														</div>

														<div>
															<button
																id="role"
																// disabled={true}
																className="block w-full bg-red-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 outline-none"
																onClick={submitDelete}
															>
																Delete
															</button>
														</div>
													</div>
												</div>
											</div>
											<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													type="submit"
													// disabled={!validName}
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Update
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setEditModalOpen(false)}
													ref={cancelButtonRef}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<MessageModal messageModalOpen={errorModalOpen} setMessageModalOpen={setErrorModalOpen}>
				{errorModalChildren}
				<button
					onClick={() => setErrorModalOpen(false)}
					className="block w-full bg-blue-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 outline-none mt-8"
				>
					Close
				</button>
			</MessageModal>
		</div>
	)
}


export function TestCheck(body: any) {
	//
	// console.log({ body })
	const questionsWithNoAnswers = body.length === 0
	const questionsWithNoCorrectAnswers = body.filter((question: any) => question.question.length > 0)
	//
	return (
		<div>
			<div className="text-2xl font-semibold mb-4">
				Occupation/Test Link - Issue
			</div>
			<div className="bg-slate-200 rounded-lg p-4 mb-4" hidden={!questionsWithNoAnswers}>
				{/* First, the test with no answers at all */}
				<div>
					<div>
						<span className="w-full underline mb-2 font-semibold">This test has no questions.</span>
					</div>
				</div>
			</div>
			<div className="bg-slate-200 rounded-lg p-4" hidden={questionsWithNoCorrectAnswers.length === 0}>
				{/* Second, the tests with no correct answers */}
				<div className="w-full underline mb-2 font-semibold">
					These questions have no correct answers:
				</div>
				{questionsWithNoCorrectAnswers.map((question: any, index: number) => {
					return <div key={index} className='mt-4'>
						<div>
							<span className="font-bold">Test: </span>{question.test}
						</div>
						<div>
							<span className="font-bold">Question Number: </span>{question.question_number}
						</div>
						<div>
							<span className="font-bold">Question: </span>{question.question}
						</div>
					</div>
				})}
			</div>
		</div>
	)
}