export default function Occupation({ required, formDataOccupation, setFormDataOccupation, list }: { formDataOccupation: any, setFormDataOccupation: any, list: { id: number, label: string}[], required?: boolean }) {
	return (<div>
		<div className="flex items-center">
			<label htmlFor="occupation" className={`${required && 'required'} block text-sm font-medium leading-6 text-gray-900`}>
				Occupation
			</label>
		</div>
		<div className="mt-2">
			<select
				id="occupation"
				name="occupation"
				onChange={(e) => setFormDataOccupation(e.target.value)}
				value={formDataOccupation}
				className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
			>
				<option value="0">Select an occupation</option>
				{list.length > 0 && list.map(item => {
					return <option key={item.id} value={item.id}>{item.label}</option>
				})}
			</select>
		</div>
	</div>
	)
}
