import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function CandidatePracticalTestIndex(job_id: number, candidate_id: number) {
	// if (job_id && job_id > 0) {
	// 	//
	return FetchRequestGET(`/candidate-practical-tests?job_id=${job_id}&candidate_id=${candidate_id}`)
	// 	//
	// } else {
	// 	//
	// 	return FetchRequestGET(`/candidate-practical-tests`)
	// 	//
	// }
}

export async function CandidatePracticalTestShow(id: number) {
	return FetchRequestGET(`/candidate-practical-tests/${id}`)
}

export async function CandidatePracticalTestUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/candidate-practical-tests/${id}`,
		body
	)
}

export async function CandidatePracticalTestCreate(body: any) {
	return FetchRequestPOST(
		`/candidate-practical-tests`,
		body
	)
}

export async function CandidatePracticalTestDelete(id: number) {
	return FetchRequestDELETE(
		`/candidate-practical-tests/${id}`)
}
