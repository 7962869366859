import { useNavigate } from 'react-router-dom'
import { Test } from '../../../backend/models/test'

export type props = {
	id: Test["id"],
	type: "NotStarted" | "Partial" | "Complete",
	test_code: Test["test_code"],
	test_description: Test["test_description"],
	test_heading: Test["test_heading"],
	question_count: number,
	answer_count: number,
	clicked: Function
}

export default function Card(props: props) {

	const navigate = useNavigate();

	let cardClass = ""
	if (props.type === "NotStarted") {
		cardClass = "h-full bg-red-200 px-4 py-5 sm:px-6 rounded-lg max-w-xl cursor-pointer"
	} else if (props.type === "Partial") {
		cardClass = "h-full bg-orange-200 px-4 py-5 sm:px-6 rounded-lg max-w-xl cursor-pointer"
	} else if (props.type === "Complete") {
		cardClass = "h-full bg-green-200 px-4 py-5 sm:px-6 rounded-lg max-w-xl cursor-pointer"
	}

	return (
		<div className={cardClass} onClick={e => navigate(`/home/test/${props.id}`)}>
			<div className="flex space-x-3">
				<div className="min-w-0 flex-1">
					<div className="text-sm font-semibold text-gray-900 underline">
						{props.test_code} - {props.test_description}
					</div>
					<div className="text-sm font-semibold text-gray-800 mt-3">
						{props.test_heading}
					</div>
					<div className="text-sm font-semibold text-gray-600 mt-3">
						Questions Completed:
					</div>
					<div className="text-sm text-gray-500">
						{props.answer_count} / {props.question_count}
					</div>
				</div>
				<div className="flex flex-shrink-0 self-center">
				</div>
			</div>
		</div>
	)
}
