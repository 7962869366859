
interface masterJob {
	id: number,
	job_number: string,
	client_name: string
}

interface props {
	setJobId: any,
	masterJobList: masterJob[],
	jobId: number,
}

export default function ({ setJobId, masterJobList, jobId }: props) {
	//
	return (
		<>
			<select
				className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
				value={`${jobId?.toString() || "0"}`}
				onChange={(e) => {
					const selectedJobId: number = parseInt(e.target.value);
					setJobId(selectedJobId);
				}}
			>
				<option value="0">Select a Job</option>
				{masterJobList.map((masterJob: masterJob, index: number) => (
					<option key={index} value={masterJob.id}>
						Client: {masterJob.client_name} - Job: {masterJob.job_number}
					</option>
				))}
			</select>
		</>
	)
}

