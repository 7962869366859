import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function ClientTaskMasterIndex(job_id?: number) {
	if (job_id && job_id > 0) {
		//
		return FetchRequestGET(`/client-task-masters?job_id=${job_id}`)
		//
	} else {
		//
		return FetchRequestGET(`/client-task-masters`)
		//
	}
}

export async function ClientTaskMasterShow(id: number) {
	return FetchRequestGET(`/client-task-masters/${id}`)
}

export async function ClientTaskMasterUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/client-task-masters/${id}`,
		body
	)
}

export async function ClientTaskMasterCreate(body: any) {
	return FetchRequestPOST(
		`/client-task-masters`,
		body
	)
}

export async function ClientTaskMasterDelete(id: number) {
	return FetchRequestDELETE(
		`/client-task-masters/${id}`)
}
