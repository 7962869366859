import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function CandidateIndex(type: string, job_id?: number) {
	if (job_id) {
		return FetchRequestGET(`/candidates?type=${type}&job_id=${job_id}`)
	} else {
		return FetchRequestGET(`/candidates?type=${type}`)
	}
}

export async function CandidateShow(id: number) {
	return FetchRequestGET(`/candidates/${id}`)
}

export async function CandidateUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/candidates/${id}`,
		body
	)
}

export async function CandidateCreate(body: any) {
	return FetchRequestPOST(
		`/candidates`,
		body
	)
}

export async function CommenceTesting(body: { candidates: number[] }) {
	return FetchRequestPOST(
		`/candidates/commence-testing`,
		body
	)
}

export async function Archive(body: { candidates: number[] }) {
	return FetchRequestPOST(
		`/candidates/archive-candidates`,
		body
	)
}

export async function Advance(body: { candidates: number[] }) {
	return FetchRequestPOST(
		`/candidates/advance-candidates`,
		body
	)
}

export async function CandidateDelete(id: number) {
	return FetchRequestDELETE(
		`/candidates/${id}`)
}

