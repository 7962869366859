import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TemplateTable from "../../components/TemplateTable"
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ClientTaskMasterCreate, ClientTaskMasterDelete, ClientTaskMasterIndex, ClientTaskMasterShow, ClientTaskMasterUpdate } from '../../lib/backend/client-task-master'
import { TaskIndex } from '../../lib/backend/task'
import { useLocation } from 'react-router-dom'
import { table } from 'console'

const columns = [
	{
		header: "Task",
		accessorKey: "task_name",
	},
	{
		header: "Weight",
		accessorKey: "weight",
	},
]

export default function TestWeightingPage() {
	const [loadingData, setLoadingData] = useState(true)
	const [tableData, setTableData] = useState<any>([])
	const [taskData, setTaskData] = useState<any>([])

	const [createModalOpen, setCreateModalOpen] = useState(false)
	const [createModalLoading, setcreateModalLoading] = useState(false)
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [editModalLoading, setEditModalLoading] = useState(false)
	const [showCreateError, setShowCreateError] = useState(false)
	const [showMaintainError, setShowMaintainError] = useState(false)

	const [validName, setValidName] = useState(false)

	const [formDataID, setFormDataID] = useState(0)
	//
	const [formDataTaskId, setFormDataTaskId] = useState(0)
	const [formDataWeight, setFormDataWeight] = useState(0)
	//
	const [maxWeight, setMaxWeight] = useState(0)
	//

	const [jobId, setJobId] = useState(0)

	const [totalWeight, setTotalWeight] = useState(0)

	const cancelButtonRef = useRef(null)

	const location = useLocation();

	useEffect(() => {
		getData()
	}, [location])

	async function getData() {
		//
		setLoadingData(true)
		//
		const lastElement = window.location.href.split("/").slice(-1)[0]
		const queryParams = lastElement.split("?").slice(-1)[0]
		const job_id = queryParams.split("=").slice(-1)[0]
		setJobId(parseInt(job_id))
		//
		const clientTaskMaster = await ClientTaskMasterIndex(parseInt(job_id))
		const clientTaskMasterJson = await clientTaskMaster.json()
		//
		setTableData(clientTaskMasterJson);
		//
		refreshTotalWeight(clientTaskMasterJson)
		//
		await refreshTaskList()
		//
		setLoadingData(false)
		//
	}

	// async function getFilesData(id: any) {
	// 	//
	// 	// console.log("getFilesData called")
	// 	let userID
	// 	if (id) {
	// 		userID = id
	// 	} else {
	// 		userID = formDataID
	// 	}
	// 	// console.log({id})
	// 	// console.log({formDataID})
	// 	// console.log({userID})
	// 	//
	// 	setFilesLoading(true)
	// 	const responseIndex = await FileIndexUser(userID)
	// 	const bodyIndex = await responseIndex.json()
	// 	// console.log({bodyIndex})
	// 	setFiles(bodyIndex)
	// 	setFilesLoading(false)
	// 	// bodyIndex.forEach((file: any) => {
	// 	// 	if (file.filename === "None") {
	// 	// 		setShowModal(true)
	// 	// 	}
	// 	// })
	// }

	function refreshTotalWeight(data: any) {
		//
		let total = 0
		data.forEach((record: any) => {
			total += record.weight
		})
		setTotalWeight(total)
		//
	}

	async function refreshTaskList() {
		//
		const tasks = await TaskIndex()
		const taskBody = await tasks.json()
		setTaskData(taskBody)
		//
	}

	async function openCreateModal() {
		//
		setFormDataTaskId(0)
		setFormDataWeight(0)
		//
		await refreshTaskList()
		//
		buildTotalWeight(0)
		//
		setcreateModalLoading(false)
		setCreateModalOpen(true)
		setShowCreateError(false)
	}

	async function submitCreateForm(task_id: number, weight: number) {
		//
		// event.preventDefault();
		//
		if (task_id === 0) {
			alert("A task is required")
			return
		}
		//
		//
		setcreateModalLoading(true)
		setShowCreateError(false)
		//
		const createResponse = await ClientTaskMasterCreate({
			client_task_master: {
				//
				job_id: jobId,
				task_id: task_id,
				weight: weight,
				//
			}
		})
		if (createResponse.ok) {
			const body = await createResponse.json()
			//
			const tableDataUpdated: any = tableData.map((tableDataRow: any) => {
				if (tableDataRow.id === 0) {
					tableDataRow.client_id = body.client_id
					tableDataRow.client_name = body.client_name
					tableDataRow.id = body.id
					tableDataRow.job_id = body.job_id
					tableDataRow.job_number = body.job_number
					tableDataRow.task_id = body.task_id
					tableDataRow.task_name = body.task_name
					tableDataRow.weight = body.weight
				}
				return tableDataRow
			})
			setTableData(tableDataUpdated)
			// const tableDataUpdated: any = JSON.parse(JSON.stringify(tableData))
			// tableDataUpdated.push({
			// 	client_id: body.client_id,
			// 	client_name: body.client_name,
			// 	id: body.id,
			// 	job_id: body.job_id,
			// 	job_number: body.job_number,
			// 	task_id: body.task_id,
			// 	task_name: body.task_name,
			// 	weight: body.weight
			// })
			// setTableData(tableDataUpdated)
			//
			refreshTotalWeight(tableDataUpdated)
			//
			setCreateModalOpen(false)
			//
		} else {
			//
			setcreateModalLoading(false)
			setShowCreateError(true)
			//
		}
		//
	}

	async function openMaintainModal(id: number) {
		//
		setEditModalLoading(true)
		setEditModalOpen(true)
		//
		await refreshTaskList()
		//
		setFormDataID(id)
		//
		// getFilesData(id)
		//
		const response = await ClientTaskMasterShow(id)
		const body = await response.json()
		//
		setFormDataTaskId(body.task_id)
		setFormDataWeight(body.weight)
		//
		buildTotalWeight(body.task_id)
		//
		formDataValidation()
		//
		setEditModalLoading(false)
		//
	}

	async function submitMaintainForm(row_id: number, task_id: number, weight: number) {
		//
		// event.preventDefault();
		// console.log({task_id})
		//
		if (task_id === 0) {
			alert("A task is required")
			return
		}
		//
		// setEditModalLoading(true)
		// setShowMaintainError(false)
		//
		const response = await ClientTaskMasterUpdate(row_id, {
			client_task_master: {
				//
				task_id: task_id,
				weight: weight
				//
			}
		})
		//
		if (response.ok) {
			//
			const body = await response.json()
			//
			const tableDataCopy = JSON.parse(JSON.stringify(tableData))
			//
			const tableDataUpdated: any = tableDataCopy.map((tableDataRow: any) => {
				if (tableDataRow.id === row_id) {
					tableDataRow.client_id = body.client_id
					tableDataRow.client_name = body.client_name
					tableDataRow.id = body.id
					tableDataRow.job_id = body.job_id
					tableDataRow.job_number = body.job_number
					tableDataRow.task_id = body.task_id
					tableDataRow.task_name = body.task_name
					tableDataRow.weight = body.weight
				}
				return tableDataRow
			})
			setTableData(tableDataUpdated)
			// setEditModalOpen(false)
			//
			refreshTotalWeight(tableDataUpdated)
			//
		}
		//  else {
		// 	// setEditModalLoading(false)
		// 	// setShowMaintainError(true)
		// }
	}

	async function submitDelete(row_id: number) {
		//
		// event.preventDefault()
		// setEditModalLoading(true)
		if (row_id !== 0) {
			//
			const response = await ClientTaskMasterDelete(row_id)
			//
			if (response.ok) {
				//
				const tableDataCopy = JSON.parse(JSON.stringify(tableData))
				//
				const tableDataUpdated: any = tableDataCopy.filter((tableDataRow: any) => {
					return tableDataRow.id !== row_id
				})
				setTableData(tableDataUpdated)
				// setEditModalOpen(false)
				//
				refreshTotalWeight(tableDataUpdated)
				//
			}
		} else {
			//
			const tableDataCopy = JSON.parse(JSON.stringify(tableData))
			//
			const tableDataUpdated: any = tableDataCopy.filter((tableDataRow: any) => {
				return tableDataRow.id !== row_id
			})
			setTableData(tableDataUpdated)
			// setEditModalOpen(false)
			//
			refreshTotalWeight(tableDataUpdated)
			//
		}
		// else {
		// 	// setShowMaintainError(true)
		// 	// setEditModalLoading(false)
		// }
	}

	function formDataValidation() {
		// if (formDataClientName.length > 0) {
		// 	setValidName(true)
		// } else {
		// 	setValidName(false)
		// }
	}

	function buildTaskOptionsCreate(includeFirstRow: boolean, existing_task_id: number = 0) {
		//
		// let taskList = JSON.parse(JSON.stringify(taskData))
		//
		// taskList = taskList.filter((record: any) => tableData.filter((task: any) => task.task_id === record.id && task.task_id !== existing_id).length === 0)
		//
		const returnList = taskData
			// .filter((record: any) => tableData.filter((task: any) => task.task_id === record.id).length === 0)
			.filter((record: any) => tableData.filter((task: any) => task.task_id === record.id && task.task_id !== existing_task_id).length === 0)
			.map((record: any) => {
				return <option key={record.id} value={record.id}>
					{record.task_name}
				</option>
			})
		//
		if (includeFirstRow) {
			returnList.unshift(<option key={0} value={0}>Select a task</option>)
		}
		//
		return returnList
		//
	}

	// function buildTaskOptionsUpdate() {
	// 	//
	// 	return taskData
	// 		.filter((record: any) => tableData.filter((task: any) => task.task_id === record.id && task.task_id !== formDataTaskId).length === 0)
	// 		.map((record: any) => {
	// 			return <option key={record.id} value={record.id}>
	// 				{record.task_name}
	// 			</option>
	// 		})
	// 	//
	// }

	function buildTotalWeight(taskId: number = 0) {
		//
		let maxWeightCalc = 0
		tableData.forEach((record: any) => {
			if (record.task_id === taskId) {
				//
			} else {
				maxWeightCalc += record.weight
			}
		})
		setMaxWeight(100 - maxWeightCalc)
		//
	}

	// console.log(maxWeight)
	// console.log({ tableData })
	// console.log(t)

	return (
		<>
			<div className="text-2xl font-semibold mt-4">
				Test Weightings
			</div>
			<div role="status" className="mt-8" hidden={!loadingData}>
				<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
					<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
				</svg>
			</div>
			<div className={`w-full flex flex-col text-2xl text-center ${totalWeight === 100 ? "text-green-500" : "text-red-500"}`}>
				{totalWeight}/100
			</div>
			<div className="w-full my-8 text-gray-500">
				<p className="mb-4">
					Please select the criteria for the practical exam for the following evaluation.
				</p>
				<p className="mb-4">
					Once you have selected the requirements for the practical exam, please add your weighting to gauge the importance of each element of the exam.
				</p>
				<p>
					This total must add up to 100.
				</p>
			</div>
			<div hidden={loadingData}>
				<div className="px-2">
					<div className="sm:flex sm:items-center">
						<div className="mt-4 sm:mt-0 sm:flex-none">
							<button
								type="button"
								className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								disabled={tableData.filter((row: any) => row.task_name.length === 0).length > 0}
								onClick={async () => {
									const tableDataCopy = JSON.parse(JSON.stringify(tableData))
									tableDataCopy.push({
										id: 0,
										task_id: 0,
										task_name: "",
										weight: 0
									})
									setTableData(tableDataCopy)
								}}
							>
								Add test requirement
							</button>
						</div>
					</div>
					<div className="mt-8 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-gray-50">
											<tr className="grid grid-cols-12 gap-1">
												<th scope="col" className="col-span-7 sm:col-span-4 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
													Task
												</th>
												<th scope="col" className="col-span-3 sm:col-span-6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													Weight
												</th>
												<th scope="col" className="col-span-2 sm:col-span-2 relative py-3.5 pl-3 pr-4 sm:pr-6">
													<span className="sr-only">Edit</span>
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{tableData.sort((a: any, b: any) => a.id === 0 ? 1 : b.task_id - a.task_id).map((dataRow: any, index: number) => (
												<tr key={index} className="grid grid-cols-12 gap-0">
													<td key={index + 0.01} className="col-span-7 sm:col-span-4 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
														<select
															id="task"
															name="task"
															required={true}
															autoComplete="task"
															onChange={async (e) => {
																// console.log(dataRow.task_name)
																// console.log(parseInt(e.target.value))
																if (dataRow.id === 0) {
																	await submitCreateForm(parseInt(e.target.value), dataRow.weight)
																} else {
																	await submitMaintainForm(dataRow.id, parseInt(e.target.value), dataRow.weight)
																}
															}}
															value={dataRow.task_id}
															className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
														>
															{buildTaskOptionsCreate(dataRow.id === 0, dataRow.task_id)}
														</select>
													</td>
													<td key={index + 0.02} className="col-span-3 sm:col-span-6 whitespace-nowrap px-3 py-4 text-sm text-gray-500 grid grid-cols-12">
														<input
															type="number"
															min={0}
															max={100}
															value={dataRow.weight}
															className="col-span-12 sm:col-span-4 md:col-span-2 text-center rounded-lg h-9 w-20"
															onChange={async (e) => {
																const tableDataCopy = JSON.parse(JSON.stringify(tableData))
																tableDataCopy.forEach((row: any) => {
																	if (row.task_name === dataRow.task_name) {
																		row.weight = parseInt(e.target.value)
																		//
																		const otherTasks = tableDataCopy.filter((record: any) => record.task_name !== dataRow.task_name)
																		const totalOtherTasks = otherTasks.reduce((acc: number, record: any) => acc + record.weight, 0)
																		if (totalOtherTasks + parseInt(e.target.value) > 100) {
																			row.weight = 100 - totalOtherTasks
																		}
																		//
																		// The update to the backend after 0.5 seconds of no changes
																		clearTimeout(row.interval)
																		row.interval = setTimeout(async () => {
																			//
																			// console.log("Call to backend")
																			//
																			await submitMaintainForm(dataRow.id, dataRow.task_id, parseInt(e.target.value))
																			//
																		}, 500)
																		//
																	}
																})
																setTableData(tableDataCopy)
																refreshTotalWeight(tableDataCopy)
																//
															}}
														/>
														<input
															disabled={dataRow.id === 0}
															className="hidden sm:block sm:col-span-8 md:col-span-10 ml-4"
															type="range"
															min="0"
															max="100"
															value={dataRow.weight}
															onChange={e => {
																const tableDataCopy = JSON.parse(JSON.stringify(tableData))
																tableDataCopy.forEach((row: any) => {
																	if (row.task_name === dataRow.task_name) {
																		row.weight = parseInt(e.target.value)
																		//
																		const otherTasks = tableDataCopy.filter((record: any) => record.task_name !== dataRow.task_name)
																		const totalOtherTasks = otherTasks.reduce((acc: number, record: any) => acc + record.weight, 0)
																		if (totalOtherTasks + parseInt(e.target.value) > 100) {
																			row.weight = 100 - totalOtherTasks
																		}
																		//
																		// The update to the backend after 0.5 seconds of no changes
																		clearTimeout(row.interval)
																		row.interval = setTimeout(async () => {
																			//
																			// console.log("Call to backend")
																			//
																			await submitMaintainForm(dataRow.id, dataRow.task_id, parseInt(e.target.value))
																			//
																		}, 500)
																		//
																	}
																})
																setTableData(tableDataCopy)
																refreshTotalWeight(tableDataCopy)
																//
															}} />
													</td>
													<td key={index + 0.03} className="col-span-2 sm:col-span-2 whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
														<button
															type="button"
															className="rounded-md bg-indigo-600 px-1 sm:px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
															onClick={async () => {
																await submitDelete(dataRow.id)
															}}
														>
															Delete
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <TemplateTable
					data={tableData}
					columns={columns}
					openCreateModal={openCreateModal}
					openMaintainModal={openMaintainModal}
					object_name="Weighting"
					disableCreate={false}
					initialSortState={[{ id: "task_name", desc: false }]}
					bulk={false}
					hidePageButtons={false} /> */}
			</div>
			{/* <Transition.Root show={createModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100"
					initialFocus={cancelButtonRef}
					onClose={() => { }}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 xl:inset-x-72 xl:right-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
									<div className="w-full" hidden={!createModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={createModalLoading} onSubmit={submitCreateForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full sm:max-w-sm">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														Create Weighting
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setCreateModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div hidden={!showCreateError} className="text-red-400 text-center">
														Something has gone wrong. Please try again.
													</div>
													<div className="space-y-12">
														<div className="border-b border-gray-900/10 pb-12 mt-4">
															<div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">

																<div className="col-span-2">
																	<label htmlFor="task" className="block text-sm font-medium leading-6 text-gray-900">
																		Task
																	</label>
																	<div className="mt-2">
																		<select
																			id="task"
																			name="task"
																			required={true}
																			autoComplete="task"
																			onChange={(e) => setFormDataTaskId(parseInt(e.target.value))}
																			value={formDataTaskId}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		>
																			<option value={0}>Select a task</option>
																			{buildTaskOptionsCreate()}
																		</select>
																	</div>
																</div>

																<div className="sm:col-span-2">
																	<label htmlFor="weight" className="block text-sm font-medium leading-6 text-gray-900">
																		Weight
																	</label>
																	<div className="mt-2">
																		<input
																			id="weight"
																			name="weight"
																			type="number"
																			autoComplete="weight"
																			autoFocus
																			max={maxWeight}
																			min={0}
																			onFocus={() => {
																				const a = document.getElementById("weight")
																				if (a) {
																					a.focus();
																					//@ts-ignore
																					a.select();
																				}
																			}}
																			required
																			onChange={(e) => {
																				if (e.target.value === "") {
																					//
																					setFormDataWeight(0)
																					//
																					const a = document.getElementById("weight")
																					if (a) {
																						a.focus();
																						//@ts-ignore
																						a.select();
																					}
																					//
																				} else {
																					//
																					setFormDataWeight(parseInt(e.target.value))
																					//
																				}
																			}}
																			value={formDataWeight}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>

															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													type="submit"
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Create
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setCreateModalOpen(false)}
													ref={cancelButtonRef}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root > */}


			{/* <Transition.Root show={editModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={() => { }}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 xl:inset-x-72 xl:right-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
									<div className="w-full" hidden={!editModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={editModalLoading} onSubmit={submitMaintainForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														Maintain Weighting
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setEditModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div className="space-y-4">
														<div hidden={!showMaintainError} className="text-red-400 text-center">
															Something has gone wrong
														</div>
														<div className="space-y-12">
															<div className="">
																<div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">

																	<div className="col-span-2">
																		<label htmlFor="task" className="block text-sm font-medium leading-6 text-gray-900">
																			Task
																		</label>
																		<div className="mt-2">
																			<select
																				id="task"
																				name="task"
																				autoFocus
																				required={true}
																				autoComplete="task"
																				onChange={(e) => setFormDataTaskId(parseInt(e.target.value))}
																				value={formDataTaskId}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			>
																				<option value={0}>Select a task</option>
																				{buildTaskOptionsUpdate()}
																			</select>
																		</div>
																	</div>

																	<div className="sm:col-span-2">
																		<label htmlFor="weight" className="block text-sm font-medium leading-6 text-gray-900">
																			Weight
																		</label>
																		<div className="mt-2">
																			<input
																				id="weight"
																				name="weight"
																				type="number"
																				autoComplete="weight"
																				max={maxWeight}
																				min={0}
																				onFocus={() => {
																					const a = document.getElementById("weight")
																					if (a) {
																						a.focus();
																						//@ts-ignore
																						a.select();
																					}
																				}}
																				required
																				onChange={(e) => {
																					if (e.target.value === "") {
																						//
																						setFormDataWeight(0)
																						//
																						const a = document.getElementById("weight")
																						if (a) {
																							a.focus();
																							//@ts-ignore
																							a.select();
																						}
																						//
																					} else {
																						//
																						setFormDataWeight(parseInt(e.target.value))
																						//
																					}
																				}}
																				value={formDataWeight}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div>
															<button
																id="role"
																// disabled={true}
																type="button"
																className="block w-full bg-red-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 outline-none"
																onClick={submitDelete}
															>
																Delete
															</button>
														</div>
													</div>
												</div>
											</div>
											<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													type="submit"
													// disabled={!validName}
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Update
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setEditModalOpen(false)}
													ref={cancelButtonRef}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root> */}

		</>
	)
}