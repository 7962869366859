import { UserJob } from "../../react-app-env";
import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function JobIndex(client_id?: number) {
	if (client_id) {
		//
		return FetchRequestGET(`/jobs?client_id=${client_id}`)
		//
	} else {
		//
		return FetchRequestGET(`/jobs`)
		//
	}
}

export async function JobIdsForUser(userId: number) {
	// const userJSON = await FetchRequestGET(`/users/${userId}`)
	// const user = await userJSON.json()
	const userJobsJSON = await FetchRequestGET(`/user-jobs`)
	const userJobs = await userJobsJSON.json()
	const jobIds = userJobs.reduce((ids: number[], userJob: UserJob) => userJobs.user_id !== userId ? ids : ids.concat(userJob.job_id))
	return jobIds
	// return Promise.all (jobsId.map((id: number) => JobShow(id)))
}


export async function JobShow(id: number) {
	return FetchRequestGET(`/jobs/${id}`)
}

export async function JobUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/jobs/${id}`,
		body
	)
}

export async function JobCreate(body: any) {
	return FetchRequestPOST(
		`/jobs`,
		body
	)
}

export async function JobDelete(id: number) {
	return FetchRequestDELETE(
		`/jobs/${id}`)
}

