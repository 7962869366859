import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function OccupationIndex() {
	return FetchRequestGET(`/occupations`)
}

export async function OccupationShow(id: number) {
	return FetchRequestGET(`/occupations/${id}`)
}

export async function OccupationUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/occupations/${id}`,
		body
	)
}

export async function OccupationCreate(body: any) {
	return FetchRequestPOST(
		`/occupations`,
		body
	)
}

export async function OccupationDelete(id: number) {
	return FetchRequestDELETE(
		`/occupations/${id}`)
}

