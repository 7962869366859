import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function TaskIndex() {
	return FetchRequestGET(`/tasks`)
}

export async function TaskShow(id: number) {
	return FetchRequestGET(`/tasks/${id}`)
}

export async function TaskUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/tasks/${id}`,
		body
	)
}

export async function TaskCreate(body: any) {
	return FetchRequestPOST(
		`/tasks`,
		body
	)
}

export async function TaskDelete(id: number) {
	return FetchRequestDELETE(
		`/tasks/${id}`)
}

