import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TemplateTable from "../../components/TemplateTable"
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Archive, CandidateCreate, CandidateDelete, CandidateIndex, CandidateShow, CandidateUpdate, CommenceTesting } from '../../lib/backend/candidate'
import SelectElement from '../../components/SelectElement'
import Gender from '../../components/test-widgets/Gender'
import HighSchool from '../../components/test-widgets/HighSchool'
import Occupation from '../../components/test-widgets/Occupation'
import College from '../../components/test-widgets/College'
import Vocational from '../../components/test-widgets/Vocational'
import { OccupationIndex } from '../../lib/backend/occupation'
import { JobIndex } from '../../lib/backend/job'
import CandidateJobs from '../../components/CandidateJobs'
import { ClientIndex } from '../../lib/backend/client'
import { User } from '../../react-app-env'
import MessageModal from '../../components/MessageModal'
import CandidateQuestions from '../candidate/CandidateQuestions'
import { useLocation } from 'react-router-dom'
import { ClientTaskMasterIndex } from '../../lib/backend/client-task-master'
import { CandidatePracticalTestCreate, CandidatePracticalTestIndex, CandidatePracticalTestUpdate } from '../../lib/backend/candidate-practical-test'
import { CandidatePracticalTest } from '../../../../backend/models/candidate_practical_test'
import { convertDateFieldToFieldDateFormat } from '../../lib/date-functions'
import { toNamespacedPath } from 'path'
import { CalcWeightedScore, CalcWeightedScores } from '../../lib/candidateWeightedScore'

// const statuses = [
// 	{
// 		value: "all",
// 		label: "All"
// 	},
// 	{
// 		value: "Entered",
// 		label: "Entered"
// 	},
// 	{
// 		value: "Awaiting Testing",
// 		label: "Awaiting Testing"
// 	},
// 	// {
// 	// 	value: "Rejected",
// 	// 	label: "Rejected"
// 	// },
// 	// {
// 	// 	value: "Approved",
// 	// 	label: "Approved"
// 	// },
// 	// {
// 	// 	value: "Do Not Hire",
// 	// 	label: "Do Not Hire"
// 	// },
// 	// {
// 	// 	value: "Expired",
// 	// 	label: "Expired"
// 	// },
// ]

// const issues = [
// 	{
// 		value: "all",
// 		label: "All"
// 	},
// 	{
// 		value: "No Issues",
// 		label: "No Issues"
// 	},
// 	// {
// 	// 	value: "Rejected",
// 	// 	label: "Rejected"
// 	// },
// 	// {
// 	// 	value: "Approved",
// 	// 	label: "Approved"
// 	// },
// 	// {
// 	// 	value: "Do Not Hire",
// 	// 	label: "Do Not Hire"
// 	// },
// 	// {
// 	// 	value: "Expired",
// 	// 	label: "Expired"
// 	// },
// ]

export default function ClientActiveLineUp() {
	const [loadingData, setLoadingData] = useState(true)
	// const [commenceLoading, setCommenceLoading] = useState(false)
	const [tableData, setTableData] = useState<any>([])
	const [originalTableData, setOriginalTableData] = useState<any>([])

	// const [occupationTableData, setOccupationTableData] = useState<any>([])
	// const [jobTableData, setJobTableData] = useState<any>([])
	// const [clientTableData, setClientTableData] = useState<any>([])

	// const [files, setFiles] = useState([])
	// const [filesLoading, setFilesLoading] = useState(true)

	// const [createModalOpen, setCreateModalOpen] = useState(false)
	// const [createModalLoading, setcreateModalLoading] = useState(false)
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [editModalLoading, setEditModalLoading] = useState(false)
	// const [showCreateError, setShowCreateError] = useState(false)
	const [showMaintainError, setShowMaintainError] = useState(false)

	// const [candidateFormMode, setCandidateFormMode] = useState<"N" | "Y">("N")

	const [confirmModalOpen, setConfirmModalOpen] = useState(false)

	const [validName, setValidName] = useState(false)

	const [columns, setColumns] = useState<any>([])

	const [formDataID, setFormDataID] = useState(0)
	const [formDataTableData, setFormDataTableData] = useState<any>([])
	// const [formDataEmail, setFormDataEmail] = useState("")
	// const [formDataFirstName, setFormDataFirstName] = useState("")
	// const [formDataLastName, setFormDataLastName] = useState("")
	// const [formDataPhoneNumber, setFormDataPhoneNumber] = useState("")
	// const [formDataStreetAddress, setFormDataStreetAddress] = useState("")
	// const [formDataCity, setFormDataCity] = useState("")
	// const [formDataStateProvince, setFormDataStateProvince] = useState("")
	// const [formDataZipPostalCode, setFormDataZipPostalCode] = useState("")
	// const [formDataCountry, setFormDataCountry] = useState("")
	// const [formDataGender, setFormDataGender] = useState("Male")
	// const [formDataHighSchool, setFormDataHighSchool] = useState("No")
	// const [formDataVocational, setFormDataVocational] = useState("No")
	// const [formDataCollege, setFormDataCollege] = useState("No")
	// const [formDataOccupationName, setFormDataOccupationName] = useState("")
	// const [formDataWorkHistoryYears, setFormDataWorkHistoryYears] = useState(0)
	// const [formDataJobNumber, setFormDataJobNumber] = useState("")
	// const [formDataNotes, setFormDataNotes] = useState("")
	// const [formDataTestId, setFormDataTestId] = useState(0)

	const [jobId, setJobId] = useState(0)

	// These are the selected options
	// const [statusType, setStatusType] = useState({ value: "all", label: "All" })
	// const [clientType, setClientType] = useState({ value: "all", label: "All" })
	// const [jobType, setJobType] = useState({ value: "all", label: "All" })

	// These are all the options to pick from
	// const [statusTypes, setStatusTypes] = useState<any[]>([])
	// const [clientTypes, setClientTypes] = useState([])
	// const [jobTypes, setJobTypes] = useState([])

	const cancelButtonRef = useRef(null)

	const handleFocus = (event: any) => event.target.select();

	const location = useLocation().key;

	useEffect(() => {
		getData()
	}, [location])

	// useEffect(() => {
	// 	//
	// 	setTableData(originalTableData.filter((row: any) => {
	// 		if (statusType.value === "all") {
	// 			return true
	// 		}
	// 		if (statusType.value === row.line_up_status) {
	// 			return true
	// 		}
	// 		return false
	// 	}))
	// 	//
	// }, [statusType, originalTableData])
	// }, [clientType, jobType, originalTableData])

	useEffect(() => {
		getData()
	}, [])

	async function getData() {
		//
		setLoadingData(true)
		//
		const lastElement = window.location.href.split("/").slice(-1)[0]
		const queryParams = lastElement.split("?").slice(-1)[0]
		const job_id = queryParams.split("=").slice(-1)[0]
		setJobId(parseInt(job_id))
		//
		const clientTaskMaster = await ClientTaskMasterIndex(parseInt(job_id))
		const clientTaskMasterJson = await clientTaskMaster.json()
		//
		const candidates = await CandidateIndex("Line Up", parseInt(job_id))
		let candidatesJson = await candidates.json()
		//
		candidatesJson = candidatesJson.map((candidate: any) => {
			candidate.full_name = `${candidate.first_name} ${candidate.last_name}`
			return candidate
		})
		//
		const taskMaster = await ClientTaskMasterIndex(parseInt(job_id))
		const taskMasterJson = await taskMaster.json()
		//
		let columns = [
			{
				header: "Name",
				accessorKey: "full_name",
			},
			// {
			// 	header: "Last Name",
			// 	accessorKey: "last_name",
			// },
		]
		//
		taskMasterJson.sort((a: any, b: any) => b.task_id - a.task_id).forEach((task: any, index: number) => {
			columns.push({
				header: task.task_name,
				accessorKey: `test_${index + 1}`,
			})
		})
		//
		columns.push({
			header: "Total",
			accessorKey: "total",
		})
		//
		columns.push({
			header: "Score",
			accessorKey: "score",
		})
		//
		columns.push({
			header: "Weighted Score",
			accessorKey: "weighted_score",
		})
		//
		setColumns(columns)
		//
		const candidatesJsonUpdated = await CalcWeightedScores(candidatesJson, parseInt(job_id), taskMasterJson)
		//
		setTableData(candidatesJsonUpdated);
		setOriginalTableData(candidatesJsonUpdated);
		//
		setLoadingData(false)
		//
	}

	// async function getFilesData(id: any) {
	// 	//
	// 	// console.log("getFilesData called")
	// 	let userID
	// 	if (id) {
	// 		userID = id
	// 	} else {
	// 		userID = formDataID
	// 	}
	// 	// console.log({id})
	// 	// console.log({formDataID})
	// 	// console.log({userID})
	// 	//
	// 	setFilesLoading(true)
	// 	const responseIndex = await FileIndexUser(userID)
	// 	const bodyIndex = await responseIndex.json()
	// 	// console.log({bodyIndex})
	// 	setFiles(bodyIndex)
	// 	setFilesLoading(false)
	// 	// bodyIndex.forEach((file: any) => {
	// 	// 	if (file.filename === "None") {
	// 	// 		setShowModal(true)
	// 	// 	}
	// 	// })
	// }

	// async function fetchOccupations() {
	// 	//
	// 	const occupations = await OccupationIndex();
	// 	const occupationsJson = await occupations.json()
	// 	occupationsJson.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
	// 	//
	// 	const cleanOccupationData: { id: number, label: string }[] = occupationsJson.map((occ: any) => {
	// 		//   console.log(occ)
	// 		return {
	// 			id: occ.id,
	// 			label: occ.occupation_name
	// 		}
	// 	})
	// 	//
	// 	setOccupationTableData(cleanOccupationData);
	// 	//
	// 	return (cleanOccupationData)
	// }

	// async function fetchJobs() {
	// 	//
	// 	const jobs = await JobIndex();
	// 	const jobsJson = await jobs.json()
	// 	//
	// 	// setJobTableData(jobsJson)
	// 	//
	// 	const clients = await ClientIndex();
	// 	const clientsJson = await clients.json()
	// 	//
	// 	setClientTableData(clientsJson)
	// 	//
	// 	const updatedJobs = jobsJson.map((job: any) => {
	// 		const clients = clientsJson.filter((client: any) => {
	// 			return client.id === job.client_id
	// 		})
	// 		job.client_name = clients.length > 0 ? clients[0].client_name : ""
	// 		return job
	// 	})
	// 	//
	// 	setJobTableData(updatedJobs)
	// 	//
	// }

	async function openMaintainModal(id: number) {
		//
		setEditModalLoading(true)
		setEditModalOpen(true)
		//
		setFormDataID(id)
		//
		const taskMaster = await ClientTaskMasterIndex(jobId)
		const taskMasterJson = await taskMaster.json()
		// console.log({ taskMasterJson })
		//
		const candidatePracticalTest = await CandidatePracticalTestIndex(jobId, id)
		const candidatePracticalTestJson = await candidatePracticalTest.json()
		// console.log({ candidatePracticalTestJson })
		//
		const tableData: CandidatePracticalTest[] = taskMasterJson.sort((a: any, b: any) => a.task_id - b.task_id).map((tm: any) => {
			//
			const candidatePracticalTestFiltered = candidatePracticalTestJson.filter((cpt: any) => {
				return cpt.task_id === tm.task_id
			})
			if (candidatePracticalTestFiltered.length > 0) {
				//
				return {
					id: candidatePracticalTestFiltered[0].id,
					task_id: candidatePracticalTestFiltered[0].task_id,
					task_name: tm.task_name,
					job_id: candidatePracticalTestFiltered[0].job_id,
					candidate_id: candidatePracticalTestFiltered[0].candidate_id,
					test_result: candidatePracticalTestFiltered[0].test_result,
					test_date: candidatePracticalTestFiltered[0].test_date ? convertDateFieldToFieldDateFormat(new Date(candidatePracticalTestFiltered[0].test_date)) : ""
				}
				//
			} else {
				//
				return {
					id: 0,
					task_id: tm.task_id,
					task_name: tm.task_name,
					job_id: jobId,
					candidate_id: id,
					test_result: 0,
					test_date: ""
				}
				//
			}
			//
		})
		setFormDataTableData(tableData)
		//
		//
		// getFilesData(id)
		// await fetchOccupations()
		// await fetchJobs()
		//
		// const response = await CandidateShow(id)
		// const body = await response.json()
		//
		// const filteredTableData = originalTableData.filter((row: any) => row.id === id)
		//
		// setFormDataEmail(filteredTableData[0].email)
		// setFormDataFirstName(filteredTableData[0].first_name)
		// setFormDataLastName(filteredTableData[0].last_name)
		// setFormDataPhoneNumber(filteredTableData[0].phone_number)
		//
		// setFormDataStreetAddress(filteredTableData[0].street_address)
		// setFormDataCity(filteredTableData[0].city)
		// setFormDataStateProvince(filteredTableData[0].state_province)
		// setFormDataZipPostalCode(filteredTableData[0].zip_postal_code)
		// setFormDataCountry(filteredTableData[0].country)
		//
		// setFormDataGender(filteredTableData[0].gender || "Male")
		// setFormDataHighSchool(filteredTableData[0].high_school || "No")
		// setFormDataVocational(filteredTableData[0].vocational || "No")
		// setFormDataCollege(filteredTableData[0].college || "No")
		// setFormDataOccupationName(filteredTableData[0].occupation_name)
		// setFormDataWorkHistoryYears(parseInt(filteredTableData[0].work_history_years) || 0)
		//
		// setFormDataJobNumber(`Client: ${filteredTableData[0].client_name} - Job: ${filteredTableData[0].job_number}`)
		//
		// setFormDataNotes(filteredTableData[0].notes || "")
		// setFormDataTestId(filteredTableData[0].test_id || 0)
		//
		formDataValidation()
		//
		setEditModalLoading(false)
		//
	}

	async function submitMaintainForm(event: any) {
		//
		event.preventDefault();
		//
		setEditModalLoading(true)
		setShowMaintainError(false)
		//
		let issues = false
		//
		for (const row of formDataTableData) {
			//
			if (row.id === 0) {
				//
				const response = await CandidatePracticalTestCreate({
					candidatePracticalTest: row
				})
				//
				if (response.ok) {
					//
					const jsonData = await response.json()
					let tableDataUpdated = JSON.parse(JSON.stringify(formDataTableData))
					tableDataUpdated = tableDataUpdated.filter((dataRow: any) => dataRow.task_id !== row.task_id)
					tableDataUpdated.push(jsonData)
					setFormDataTableData(tableDataUpdated)
					//
				} else {
					//
					issues = true
					//
				}
				//
			} else {
				//
				const response = await CandidatePracticalTestUpdate(row.id, {
					candidatePracticalTest: row
				})
				//
				if (response.ok) {
					//
					const jsonData = await response.json()
					let tableDataUpdated = JSON.parse(JSON.stringify(formDataTableData))
					tableDataUpdated = tableDataUpdated.map((dataRow: any) => {
						if (dataRow.id = row.id) {
							dataRow = jsonData
						}
						return dataRow
					})
					setFormDataTableData(tableDataUpdated)
					//
				} else {
					//
					issues = true
					//
				}
				//
			}
		}
		//
		if (issues) {
			//
			setEditModalLoading(false)
			setShowMaintainError(true)
			//
		} else {
			//
			const candidates = await CandidateIndex("Line Up", jobId)
			const candidatesJson = await candidates.json()
			//
			// console.log({ candidatesJson })
			//
			setTableData(candidatesJson);
			setOriginalTableData(candidatesJson);
			//
			//
			setEditModalOpen(false)
			//
		}
		//
		//
		// const response = await CandidatePracticalTestUpdate(formDataID, {
		// 	candidate: {
		// 		//
		// 		// email: formDataEmail,
		// 		// first_name: formDataFirstName,
		// 		// last_name: formDataLastName,
		// 		// phone_number: formDataPhoneNumber,
		// 		// //
		// 		// street_address: formDataStreetAddress,
		// 		// city: formDataCity,
		// 		// state_province: formDataStateProvince,
		// 		// zip_postal_code: formDataZipPostalCode,
		// 		// country: formDataCountry,
		// 		// //
		// 		// gender: formDataGender,
		// 		// high_school: formDataHighSchool,
		// 		// vocational: formDataVocational,
		// 		// college: formDataCollege,
		// 		// occupation_id: formDataOccupation,
		// 		// work_history_years: formDataWorkHistoryYears,
		// 		// //
		// 		// jobs: formDataJobNumber,
		// 		// //
		// 		// notes: formDataNotes,
		// 		//
		// 	}
		// })
		// //
		// if (response.ok) {
		// 	//
		// 	const body = await response.json()
		// 	//
		// 	const tableDataUpdated: any = originalTableData.map((tableDataRow: any) => {
		// 		if (tableDataRow.id === formDataID) {
		// 			// tableDataRow.first_name = body.first_name
		// 			// tableDataRow.last_name = body.last_name
		// 			// tableDataRow.candidate_status = body.candidate_status
		// 			// tableDataRow.issue = body.issue
		// 		}
		// 		return tableDataRow
		// 	})
		// 	setTableData(tableDataUpdated)
		// 	setOriginalTableData(tableDataUpdated)
		// 	setEditModalOpen(false)
		// } else {
		// 	setEditModalLoading(false)
		// 	setShowMaintainError(true)
		// }
	}

	async function submitArchive(event: any) {
		//
		if (event) {
			event.preventDefault()
		}
		setEditModalLoading(true)
		//
		const response = await Archive({ candidates: [formDataID] })
		//
		if (response.ok) {
			const tableDataUpdated: any = originalTableData.filter((tableDataRow: any) => {
				return tableDataRow.id !== formDataID
			})
			setTableData(tableDataUpdated)
			setOriginalTableData(tableDataUpdated)
			setEditModalOpen(false)
		} else {
			setShowMaintainError(true)
			setEditModalLoading(false)
		}
	}

	function formDataValidation() {
		// if (formDataName.length > 0) {
		// 	setValidName(true)
		// } else {
		// 	setValidName(false)
		// }
	}

	// function turnOffTest() {
	// 	let element = document.getElementById('ModalPanel');
	// 	if (element) {
	// 		element.scrollIntoView(true);
	// 	}
	// 	setCandidateFormMode("N")
	// }

	return (
		<>
			<div className="text-2xl font-semibold">
				Test Results
			</div>
			<div role="status" className="mt-8" hidden={!loadingData}>
				<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
					<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
				</svg>
			</div>
			<div hidden={loadingData}>
				<TemplateTable
					data={tableData}
					columns={columns}
					openCreateModal={() => { }}
					openMaintainModal={openMaintainModal}
					object_name="Candidate"
					disableCreate={true}
					bulk={true}
					hidePageButtons={false}
					initialSortState={[{ id: "total", desc: true }]}
					UnderComponent={() => {
						return <div></div>
						// return <div className="sm:flex sm:items-end w-full mt-5 gap-4">
						// 	<SelectElement items={statusTypes} selected={statusType} setSelected={setStatusType} title="Status" ></SelectElement>
						// </div>
					}} />
			</div>

			<Transition.Root show={editModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={() => { }}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 xl:inset-x-72 xl:right-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel id="ModalPanel" className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
									<div className="w-full" hidden={!editModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={editModalLoading} onSubmit={submitMaintainForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														Practical Test Scores
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setEditModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div className="space-y-4">
														<div hidden={!showMaintainError} className="text-red-400 text-center">
															Something has gone wrong
														</div>
														<div className="w-full mb-8 shadow ring-1 ring-black ring-opacity-5 rounded-lg p-3">
															Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse placeat voluptatibus fugit debitis, mollitia necessitatibus veritatis nisi exercitationem? Numquam deleniti, non voluptate repellendus impedit aperiam vitae eum rem autem aut.
														</div>
														{/* <div className="w-full mt-8 shadow ring-1 ring-black ring-opacity-5 rounded-lg"> */}
														<div className="w-full mt-8 ">
															<table className="w-full divide-y divide-gray-300">
																{/* <thead className="bg-gray-50 h-12">
																	<tr className="p-4">
																		<th>
																			<span className="ml-2">Task</span>
																		</th>
																		<th>
																			<span>Score</span>
																		</th>
																	</tr>
																</thead> */}
																{/* <div className="p-4 divide-y divide-gray-200"> */}
																<div className="w-full">
																	{formDataTableData.map((row: any, index: number) => {
																		return <div
																			key={index}
																			// className="w-full flex flex-col bg-gray-200 rounded-lg p-4 border-gray-500 shadow-lg"
																			className='py-2 w-full flex flex-col border-gray-700 p-4'
																		>
																			{/* <div>{row.id}</div> */}
																			{/* <div>{row.task_id}</div> */}
																			<div className="py-2 w-full text-center font-bold text-lg">
																				{row.task_name}
																			</div>
																			{/* <div>{row.job_id}</div> */}
																			{/* <div>{row.candidate_id}</div> */}
																			{/* <div className="w-full flex py-4 pr-4 flex-col"> */}
																			<div className="flex">
																				<input
																					// disabled={dataRow.id === 0}
																					id={(index + 0.02).toString()}
																					className="w-full mr-4"
																					type="range"
																					disabled={true}
																					min="0"
																					max="10"
																					value={row.test_result}
																					onChange={e => {
																						let tableDataCopy = JSON.parse(JSON.stringify(formDataTableData))
																						tableDataCopy = tableDataCopy.map((dataRow: any) => {
																							if (row.task_id === dataRow.task_id) {
																								//
																								dataRow.test_result = parseInt(e.target.value)
																								//
																							}
																							return dataRow
																						})
																						setFormDataTableData(tableDataCopy)
																						//
																					}} />
																				<input
																					id={(index + 0.01).toString()}
																					type="number"
																					disabled={true}
																					min={0}
																					max={10}
																					value={row.test_result}
																					className="text-center rounded-lg"
																					onChange={async (e) => {
																						let tableDataCopy = JSON.parse(JSON.stringify(formDataTableData))
																						tableDataCopy = tableDataCopy.map((dataRow: any) => {
																							if (row.task_id === dataRow.task_id) {
																								//
																								dataRow.test_result = parseInt(e.target.value)
																								//
																							}
																							return dataRow
																						})
																						setFormDataTableData(tableDataCopy)
																						//
																					}}
																				/>
																			</div>
																			<div className="mt-2 mb-4">
																				<input
																					type="date"
																					disabled={true}
																					className="w-full rounded-lg"
																					value={row.test_date}
																				/>
																			</div>
																			{/* </div> */}
																		</div>
																	})}
																</div>
															</table>
														</div>


														{/* <div className="mt-5 sm:mt-6">
															<button
																type="button"
																className="mt-3 inline-flex w-full justify-center rounded-md bg-orange-400 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 hover:bg-orange-300 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
																onClick={() => {
																	let element = document.getElementById('ModalPanel');
																	if (element) {
																		element.scrollIntoView(true);
																	}
																	setCandidateFormMode("Y")
																}}
															>
																View Test Results
															</button>
														</div> */}

														{/* <div>
															<button
																id="role"
																type="button"
																// disabled={true}
																className="block w-full bg-red-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 outline-none"
																onClick={() => setConfirmModalOpen(true)}
															>
																Archive Candidate
															</button>
														</div> */}
													</div>
												</div>
											</div>
											<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3">
												{/* <button
													type="submit"
													// disabled={!validName}
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Update
												</button> */}
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setEditModalOpen(false)}
													ref={cancelButtonRef}
												>
													Close
												</button>
											</div>
										</div>
									</form>
									{/* <div hidden={candidateFormMode === "N"}>
										<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
											<button
												type="button"
												className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
												onClick={turnOffTest}
											>
												<span className="sr-only">Close</span>
												<XMarkIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
										<CandidateQuestions test_id={formDataTestId} turnOffTest={turnOffTest} />
									</div> */}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<MessageModal messageModalOpen={confirmModalOpen} setMessageModalOpen={setConfirmModalOpen}>
				<h2 className="w-full text-xl font-bold">
					Are you sure you would like to archive this candidate?
				</h2>
				<div className="flex">
					<button
						autoFocus={true}
						onClick={() => setConfirmModalOpen(false)}
						className="w-full mr-3 bg-blue-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 outline-none mt-8 border-none"
					>
						Cancel
					</button>
					<button
						onClick={() => {
							setConfirmModalOpen(false)
							submitArchive(false)
						}}
						className="w-full bg-red-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 outline-none mt-8 border-none"
					>
						Archive
					</button>
				</div>
			</MessageModal>
		</>
	)
}