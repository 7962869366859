import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { LoginUser } from "../../lib/backend/auth";
import { setToken } from "../../lib/token";

export default function LoginPage() {
	const [formDataEmail, setFormDataEmail] = useState("")
	const [formDataPassword, setFormDataPassword] = useState("")
	const [formDataError, setFormDataError] = useState(false)
	const [loginSuccess, setLoginSuccess] = useState(false)

	const navigate = useNavigate();

	useEffect(() => {
		if (loginSuccess) {
			navigate("/home/dashboard")
		}
	}, [loginSuccess, navigate])

	async function formSubmit(event: any) {
		event.preventDefault()

		setFormDataError(false)

		const response = await LoginUser(
			formDataEmail,
			formDataPassword
		)
		if (response.ok) {
			const body = await response.json()
			setToken(body.token)
			setLoginSuccess(true)
		} else {
			setFormDataError(true)
		}
	}

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<img
						className="mx-auto h-24 w-auto"
						src="/peloton-logo.png"
						alt="Candidate Tracker"
					/>
					<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
						Sign in to your account
					</h2>
				</div>

				<div hidden={!formDataError} className="mx-auto text-red-500 mt-8 text-xl">
					Invalid email or password
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={formSubmit}>
						<div>
							<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
								Email address
							</label>
							<div className="mt-2">
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									required
									onChange={(e) => setFormDataEmail(e.target.value)}
									value={formDataEmail}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<div className="flex items-center justify-between">
								<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
									Password
								</label>
								<div className="text-sm">
									<Link to="/forgot-password" className="font-semibold text-indigo-600 hover:text-indigo-500">
										Forgot password?
									</Link>
								</div>
							</div>
							<div className="mt-2">
								<input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									onChange={(e) => setFormDataPassword(e.target.value)}
									value={formDataPassword}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Sign in
							</button>
						</div>
					</form>

				</div>
			</div>
		</>
	)
}
