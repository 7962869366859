export default function Vocational({ formDataVocational, setFormDataVocational }: any) {
	return (<div>
		<label htmlFor="vocational" className="block text-sm font-medium leading-6 text-gray-900">
			Vocational
		</label>
		<div className="mt-2">
			<select
				id="vocational"
				name="vocational"
				onChange={(e) => setFormDataVocational(e.target.value)}
				value={formDataVocational}
				className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
			>
				<option value="No">No</option>
				<option value="Yes">Yes</option>
			</select>
		</div>
	</div>
	)
}
