import { useEffect, useState } from "react"
import { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { GetSession } from "../../lib/backend/auth"
import { UserGetMe } from "../../lib/backend/user"
import { MyTests } from "../../lib/backend/test"
import { Test } from "../../../../backend/models/test"
import TestCard, { props } from "../../components/TestCardTemplate"
import CandidateQuestions from "../candidate/CandidateQuestions"
import { ClientTaskMasterIndex } from "../../lib/backend/client-task-master"
import { JobIndex } from "../../lib/backend/job"
import ClientJobTaskTemplate from "../../components/ClientJobTaskTemplate"

import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	Title,
	CategoryScale,
	LinearScale,
	BarElement
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { CandidateIndex } from "../../lib/backend/candidate"
import { CalcWeightedScore, CalcWeightedScores } from "../../lib/candidateWeightedScore"

ChartJS.register(Title);
ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

type myTest = {
	id: Test["id"],
	test_code: Test["test_code"],
	test_description: Test["test_description"],
	test_heading: Test["test_heading"],
	test_introduction: Test["test_introduction"],
	test_revision_number: Test["test_revision_number"],
	test_status: Test["test_status"],
	test_tag: Test["test_tag"],
	question_count: number,
	answer_count: number,
	created_at: any,
	updated_at: any
}

type clientTaskMasterType = {
	job_id: number,
	job_number: string,
	job_description: string,
	totalWeight: number,
	type: "NotStarted" | "Partial" | "Complete"
}

export default function DashboardPage() {

	const [initialLoading, setInitialLoading] = useState(true)
	const [showModal, setShowModal] = useState(false)
	const [myTests, setMyTests] = useState<myTest[]>([])
	const [candidate, setCandidate] = useState("Y")
	const [arrived, setArrived] = useState(false)

	const [candidates, setCandidates] = useState<any[]>([])

	const [company, setCompany] = useState("Y")
	const [firstName, setFirstName] = useState("Y")
	const [lastName, setLastName] = useState("Y")

	const [showTest, setShowTest] = useState(false)
	const [showTestID, setShowTestID] = useState(0)

	const [clientUserData, setClientUserData] = useState<clientTaskMasterType[]>([])

	const navigate = useNavigate();

	useEffect(() => {
		getFilesData()
	}, [])

	async function getFilesData() {
		const response = await UserGetMe()
		if (!response.ok) {
			navigate("/")
		} else {
			const body = await response.json()
			setCandidate(body.candidate)
			setCompany(body.company)
			setFirstName(body.first_name)
			setLastName(body.last_name)
			setArrived(body.arrived)
			if (body.candidate === "Y") {
				const myTestsResponse = await MyTests()
				if (myTestsResponse.ok) {
					const myTestsBody = await myTestsResponse.json()
					// console.log(myTestsBody.tests)
					setMyTests(myTestsBody.tests)
				}
				// 	setShowModal(true)
			} else if (body.candidate === "C") {
				//
				const candidates = await CandidateIndex("Line Up")
				const candidatesJson = await candidates.json()
				//
				const jobs = await JobIndex()
				const jobsBody = await jobs.json()
				let jobsData: any[] = []
				//
				jobsBody.forEach((job: any) => {
					//
					jobsData.push({
						job_id: job.id,
						job_number: job.job_number,
						job_description: job.job_description,
						totalWeight: 0,
						type: "NotStarted"
					})
					//
				})
				//
				const clientTaskMaster = await ClientTaskMasterIndex()
				const clientTaskMasterBody = await clientTaskMaster.json()
				clientTaskMasterBody.forEach((job: any) => {
					//
					jobsData = jobsData.map((j: any) => {
						//
						if (j.job_id === job.job_id) {
							j.totalWeight += job.weight
							if (j.totalWeight === 100) {
								j.type = "Complete"
							} else if (j.totalWeight > 0) {
								j.type = "Partial"
							}
						}
						//
						return j
						//
					})
					//
				});
				//
				// console.log({ jobsData })
				setClientUserData(jobsData)
				//
				// console.log({ candidatesJson })
				const candidatesJsonUpdated = await CalcWeightedScores(candidatesJson, 0, clientTaskMasterBody)
				// console.log({ candidatesJsonUpdated })
				//
				setCandidates(candidatesJsonUpdated)
				//
			}
			setInitialLoading(false)
		}
	}

	async function testCardClicked(test_id: number) {
		if (test_id !== 0) {
			setShowTestID(test_id)
			setShowTest(true)
		}
	}

	async function hideTest() {
		setShowTest(false)
		setInitialLoading(true)
		getFilesData()
	}

	let heading = "Dashboard"
	let allTestsComplete: boolean = false
	if (candidate === "Y" && !initialLoading) {
		heading = "Peloton Testing Centre: Awaiting test completion"
		//
		let testCompleteTracker: boolean = true
		myTests.forEach(myTest => {
			if (myTest.answer_count < myTest.question_count) {
				testCompleteTracker = false
			}
		})
		if (myTests.length > 0 && testCompleteTracker === true) {
			allTestsComplete = true
			heading = "Peloton Testing Centre: All tests completed"
		}
	} else if (candidate === "C") {
		heading = `Dashboard: ${company}, ${firstName} ${lastName}`
	}

	return (
		<div className="px-4 md:px-16 mt-8">
			<div hidden={showTest}>
				<div className="text-2xl font-semibold mb-4 flex justify-between">
					<h1>{heading}</h1>
					<img src="/peloton-logo.png" alt="" className="h-18" hidden={initialLoading || candidate === "N"} />
				</div>
				<div className="w-full" hidden={!initialLoading}>
					<div className="flex w-full justify-center">
						<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
							<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
						</svg>
					</div>
				</div>
				<div hidden={candidate === "N"} className="mt-8">
					<div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
						{myTests.map((myTest: myTest, index: number) => {
							//
							let type: props["type"] = "NotStarted"
							if (myTest.answer_count > 0 && myTest.answer_count < myTest.question_count) {
								type = "Partial"
							} else if (myTest.answer_count === myTest.question_count) {
								type = "Complete"
							}
							//
							return <div className="w-full h-full">
								<TestCard
									id={myTest.id}
									key={index}
									type={type}
									test_code={myTest.test_code}
									test_heading={myTest.test_heading}
									test_description={myTest.test_description}
									question_count={myTest.question_count}
									answer_count={myTest.answer_count}
									clicked={testCardClicked}
								/>
							</div>
							//
						})}
					</div>
				</div>
				<div hidden={candidate === "N" || candidate === "C" || initialLoading} className="mt-40 w-full text-center">
					<div hidden={!allTestsComplete}>
						Thank you for completing your testing.
					</div>
					If you require support or assistance with the Peloton Testing Center, please contact our team at <Link
						className="text-blue-500"
						to={`mailto:support@pelotonworkready.com`}
					>
						support@pelotonworkready.com
					</Link>
				</div>
				<div hidden={candidate !== "C"}>
					<div className="text-gray-700 -mt-8">
						<p className="mb-2">
							Welcome to the Peloton Workready testing and evaluation platform.
						</p>
						<p className="mb-2">
							This platform will guide you through evaluating candidates and will provide you the tools you need to determine the best possible candidates for your business.
						</p>
						<p className="mb-8">
							At any point of testing, select the DASHBOARD option from the left hand navigation menu to return here.
						</p>
					</div>
					<div className="text-xl font-semibold mb-2">
						Test Weighting
					</div>
					<div className="text-gray-700">
						<p className="mb-2">
							The first step in the evaluation process is to determine what practical tests will be completed by the candidates and apply your test weightings to each test. This allows you to focus the scoring system on what is important to you.
						</p>
						<p className="mb-2">
							After assigning all test weightings for your evaluations, the tile(s) below will turn green.
						</p>
						<p className="mb-2">
							This should be completed before commencing any testing.
						</p>
					</div>
					<div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
						{clientUserData.map((job: clientTaskMasterType, index: number) => {
							return <div className="flex justify-center col-span-1">
								<ClientJobTaskTemplate
									id={job.job_id}
									key={index}
									type={job.type}
									job_number={job.job_number}
									job_description={job.job_description}
									weight_count={job.totalWeight}
									clicked={testCardClicked}
								/>
							</div>
						})}
					</div>
					<div className="mt-8" hidden={!arrived}>
						{clientUserData.map((row, index) => {
							return <div key={index} className="my-8">
								<div className="text-xl font-semibold my-8">
									{row.job_number} - {row.job_description} Progress Summary
								</div>
								<div className="grid grid-cols-1 md:grid-cols-2">
									<div className="h-80 col-span-1 flex justify-center">
										<DoughnutChart
											incompleteCount={candidates.filter(cand => cand.job_id === row.job_id && cand.line_up_status !== "Complete").length}
											completeCount={candidates.filter(cand => cand.job_id === row.job_id && cand.line_up_status === "Complete").length}
										/>
									</div>
									<div className="h-80 col-span-1 flex justify-center">
										<BarChart
											labels={candidates.filter(cand => cand.job_id === row.job_id).sort((a: any, b: any) => b.weighted_score_total - a.weighted_score_total).map(cand => `${cand.first_name} ${cand.last_name}`)}
											weighted_scores={candidates.filter(cand => cand.job_id === row.job_id).sort((a: any, b: any) => b.weighted_score_total - a.weighted_score_total).map(cand => parseFloat(cand.weighted_score_total))}
										// completeCount={candidates.filter(cand => cand.job_id === row.job_id && cand.line_up_status === "Complete").length}
										/>
									</div>
								</div>
							</div>
						})}
					</div>
				</div>
			</div>

			<Transition.Root show={showModal} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={setShowModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 lg:inset-x-72 lg:right-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-2 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div className="w-full">
										<div className="text-center sm:ml-2 sm:mt-0 sm:text-left">
											<Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
												Testing Required
											</Dialog.Title>
											<div className="w-full">

												<div className="mt-6 mb-4 text-gray-600 w-full">
													<p>
														You are required to do the following test for your application to progress
													</p>
												</div>

											</div>
											<div className="mt-8 text-gray-600">
												Test...
											</div>
										</div>
									</div>
									<div className="mt-8 sm:mt-4 w-full text-right">
										<button
											type="button"
											className="mt-3 inline-flex w-full justify-right rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
											onClick={() => setShowModal(false)}
										>
											Close
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	)
}

function DoughnutChart({ incompleteCount = 0, completeCount = 0 }) {

	const data = {
		labels: ['Incomplete', 'Complete'],
		datasets: [
			{
				label: '# of Active Line Up Tests',
				data: [incompleteCount, completeCount],
				backgroundColor: [
					'rgba(255, 99, 132, .5)',
					'rgba(199, 245, 211, .5)',
				],
				borderColor: [
					'rgba(255, 99, 132, 1)',
					'rgba(199, 245, 211, 1)',
				],
				borderWidth: 1,
			},
		],
	};

	const options = {
		plugins: {
			title: {
				display: true,
				text: 'Testing Progress',
				font: {
					size: 18
				}
			},
		},
	}

	return (
		<Doughnut data={data} options={options} />
	)
}

function BarChart({ labels, weighted_scores }: { labels: String[], weighted_scores: number[] }) {

	// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

	const data = {
		labels,
		datasets: [
			{
				label: 'Candidate Weighted Score',
				data: weighted_scores,
				borderColor: 'rgb(199, 245, 211)',
				backgroundColor: 'rgba(199, 245, 211, 0.5)',
			},
		],
	};

	const options = {
		indexAxis: 'y' as const,
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			// legend: {
			// 	position: 'right' as const,
			// },
			title: {
				display: true,
				text: 'Leading Candidates',
				font: {
					size: 18
				}
			},
		},
	};

	return (
		<Bar data={data} options={options} />
	)
}