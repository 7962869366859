import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { LoginUser, VerifyEmail } from "../../lib/backend/auth";

export default function EmailVerifyPage() {
	const [formDataError, setFormDataError] = useState(false)
	const [verifySuccess, setVerifySuccess] = useState(false)

	useEffect(() => {
		if (!formDataError && !verifySuccess) {
			verifyEmail()
		}
	}, [])

	async function verifyEmail() {
		let params = new URLSearchParams(document.location.search)
		let sid = params.get("sid")
		if (sid !== null) {
			const response = await VerifyEmail(sid)
			if (response.ok) {
				setVerifySuccess(true)
			} else {
				setFormDataError(true)
			}
		} else {
			setFormDataError(true)
		}
	}

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<img
						className="mx-auto h-24 w-auto"
						src="/peloton-logo.png"
						alt="Candidate Tracker"
					/>
					<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
						Verifying your account
					</h2>
				</div>

				<div hidden={verifySuccess || formDataError} className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-center">
					Verifying...
				</div>

				<div hidden={!verifySuccess} className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-center">
					Your email has been verified
				</div>

				<div hidden={!formDataError} className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-center">
					Your email was not able to be verified
				</div>
			</div>
		</>
	)
}
