import { CandidatePracticalTestIndex } from "./backend/candidate-practical-test"

export async function CalcWeightedScores(
	candidatesJson: any,
	job_id: number,
	taskMasterJson: any,
): Promise<any> {
	//
	for (const candidate of candidatesJson) {
		//
		let candidatePracticalTest: any = []
		if (job_id > 0) {
			candidatePracticalTest = await CandidatePracticalTestIndex(job_id, candidate.user_id)
		} else {
			candidatePracticalTest = await CandidatePracticalTestIndex(candidate.job_id, candidate.id)
		}
		const candidatePracticalTestJson = await candidatePracticalTest.json()
		//
		let total = 0
		let weighted_score_total = 0
		//
		for (const [index, test] of candidatePracticalTestJson.sort((a: any, b: any) => b.task_id - a.task_id).entries()) {
			//
			// console.log({ taskMasterJson })
			const tasksFiltered = taskMasterJson.filter((tm: any) => tm.task_id === test.task_id)
			//
			let weight = 0
			if (tasksFiltered.length > 0) {
				weight = tasksFiltered[0].weight
			}
			// console.log({ weight })
			//
			const weighted_score = CalcWeightedScore(test.test_result, weight)
			//
			candidate[`test_${index + 1}`] = test.test_result
			total += test.test_result
			//
			weighted_score_total += weighted_score * 100
			//
		}
		//
		candidate.total = total
		//
		if ((total / candidatePracticalTestJson.length) > 0) {
			candidate.score = `${(((total / candidatePracticalTestJson.length) * 10)).toFixed(2)}%`
		} else {
			candidate.score = `${0}%`
		}
		//
		candidate.weighted_score_total = weighted_score_total
		candidate.weighted_score = `${weighted_score_total.toFixed(2)}%`
		//
	}
	//
	// console.log(candidatesJson.filter((cand: any) => cand.first_name === 'Jack')[0])
	// console.log(candidatesJson.filter((cand: any) => cand.first_name === 'Erling')[0])
	//
	return candidatesJson
	//
}

export function CalcWeightedScore(
	raw_result: number,
	weight: number,
): number {
	//
	let raw_score = raw_result / 10
	let weighted_score = raw_score * (weight / 100)
	//
	return weighted_score
	//
}