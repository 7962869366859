import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
	createBrowserRouter,
	Navigate,
	RouterProvider,
} from "react-router-dom";

// import LandingPage from './pages/LandingPage';
import LoginPage from './pages/auth/LoginPage';
// import RegisterPage from './pages/auth/RegisterPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ChangePasswordPage from './pages/auth/ChangePasswordPage';
import DashboardPage from './pages/home/DashboardPage';
import HomePage from './pages/home/HomePage';
import LogoutPage from './pages/auth/LogoutPage';
import EmailVerifyPage from './pages/auth/EmailVerifyPage';
import OrganisationPage from './pages/home/OrganisationPage';
// import PublicOrgSignupPage from './pages/auth/PublicOrgSignupPage';
import CandidatePageAll from './pages/home/CandidatePageAll';
import CandidatePageEntered from './pages/home/CandidatePageEntered';
import CandidateAwaitingTesting from './pages/home/CandidateAwaitingTesting';
import ClientPage from './pages/home/ClientPage';
import OccupationPage from './pages/home/OccupationPage';
import JobPage from './pages/home/JobPage';
import TestMasterPage from './pages/home/TestMasterPage';
import OccupationTestPage from './pages/home/OccupationTestPage';
import CandidateQuestions from './pages/candidate/CandidateQuestions';
import CandidateCompletedTesting from './pages/home/CandidateCompletedTesting';
import ClientUsersPage from './pages/home/ClientUsersPage';
import CandidatesArchived from './pages/home/CandidateArchived';
import CandidatePageActiveLineup from './pages/home/CandidateActiveLineUp';
import PracticalExamTaskPage from './pages/home/PracticalExamTaskPage';
import TestWeightingPage from './pages/client/TestWeighting';
import ClientActiveLineUp from './pages/client/ClientActiveLineUp';
import ClientTestResults from './pages/client/ClientTestResults';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

export const PathForEntered = "candidates-entered";
export const PathForTestCompleted = "candidates-test-completed";

const router = createBrowserRouter([
	{
		path: "/",
		element: <LoginPage />,
	},
	{
		path: "/identity/password_reset/edit",
		element: <ChangePasswordPage />
	},
	{
		path: "/identity/email_verification",
		element: <EmailVerifyPage />
	},
	// {
	// 	path: "/login",
	// 	element: <LoginPage />
	// },
	// {
	// 	path: "/register",
	// 	element: <RegisterPage />
	// },
	{
		path: "/forgot-password",
		element: <ForgotPasswordPage />
	},
	{
		path: "/change-password",
		element: <ChangePasswordPage />
	},
	{
		path: "/logout",
		element: <LogoutPage />
	},
	// {
	// 	path: "/signup/:org_name",
	// 	element: <PublicOrgSignupPage />
	// },
	{
		path: "/home",
		element: <HomePage />,
		children: [
			{
				path: "/home/dashboard",
				element: <DashboardPage />,
			},
			// {
			// 	path: "/home/organisation",
			// 	element: <OrganisationPage />
			// },
			{
				path: "/home/candidates",
				element: <CandidatePageAll />
			},
			{
				path: `/home/${PathForEntered}`,
				element: <CandidatePageEntered />
			},
			{
				path: "/home/candidates-awaiting-testing",
				element: <CandidateAwaitingTesting />
			},
			{
				path: `/home/${PathForTestCompleted}`,
				element: <CandidateCompletedTesting />
			},
			{
				path: "/home/active-line-up",
				element: <CandidatePageActiveLineup />
			},
			{
				path: "/home/archived-candidates",
				element: <CandidatesArchived />
			},
			{
				path: "/home/setup-clients",
				element: <ClientPage />
			},
			{
				path: "/home/setup-occupation-types",
				element: <OccupationPage />
			},
			{
				path: "/home/clients-job-list",
				element: <JobPage />
			},
			{
				path: "/home/setup-jobs",
				element: <JobPage />
			},
			{
				path: "/home/clients-list",
				element: <ClientPage />
			},
			{
				path: "/home/setup-tests",
				element: <TestMasterPage />
			},
			{
				path: "/home/setup-tests-by-occupation",
				element: <OccupationTestPage />
			},
			{
				path: "/home/setup-practical-exam-tasks",
				element: <PracticalExamTaskPage />
			},
			{
				path: "/home/test-weighting",
				element: <TestWeightingPage />
			},
			{
				path: "/home/test/:test_id",
				element: <CandidateQuestions />
			},
			{
				path: "/home/client-users",
				element: <ClientUsersPage />
			},
			{
				path: "/home/client-active-line-up",
				element: <ClientActiveLineUp />
			},
			{
				path: "/home/client-test-results",
				element: <ClientTestResults />
			},
			// {
			// 	path: "/home/trips",
			// 	element: <TripsPage />
			// },
			// {
			// 	path: "/home/reports",
			// 	element: <ReportsPage />
			// },
		]
	},
]);

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
