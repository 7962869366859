import { UserTest } from "../../../../backend/models/user_test";
import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function UserTestIndex() {
	return FetchRequestGET("/user-tests")
}

export async function UserTestShow(id: number) {
	return FetchRequestGET(`/user-tests/${id}`)
}

export async function UserTestUpdate(id: number, body: Pick<UserTest, "status">) {
	return FetchRequestPATCH(
		`/user-tests/${id}`,
		body
	)
}

export async function UserTestCreate(body: Pick<UserTest, "test_id" | "status">) {
	return FetchRequestPOST(
		`/user-tests`,
		body
	)
}

export async function UserTestDelete(id: number) {
	return FetchRequestDELETE(
		`/user-tests/${id}`)
}

