import { Fragment, useEffect, useState } from 'react'
import { Combobox, Dialog, Menu, Transition } from '@headlessui/react'
import {
	AcademicCapIcon,
	AdjustmentsHorizontalIcon,
	ArchiveBoxXMarkIcon,
	ArrowLeftOnRectangleIcon,
	Bars3Icon,
	BellIcon,
	BoltIcon,
	BriefcaseIcon,
	ChartBarIcon,
	ChartBarSquareIcon,
	CheckCircleIcon,
	CheckIcon,
	ChevronDownIcon,
	ChevronUpDownIcon,
	ClipboardDocumentCheckIcon,
	ClipboardDocumentIcon,
	ClipboardDocumentListIcon,
	ClockIcon,
	// CalendarIcon,
	// ChartPieIcon,
	Cog6ToothIcon,
	DocumentTextIcon,
	FaceFrownIcon,
	GlobeAmericasIcon,
	// FolderIcon,
	HomeIcon,
	ListBulletIcon,
	MagnifyingGlassIcon,
	PauseCircleIcon,
	PresentationChartBarIcon,
	ScaleIcon,
	SwatchIcon,
	TicketIcon,
	UserIcon,
	UserMinusIcon,
	// UserIcon,
	UserPlusIcon,
	UsersIcon,
	XMarkIcon,
	// BuildingOffice2Icon
} from '@heroicons/react/24/outline'
import { Link, Outlet, useNavigate, useLocation, Navigation } from 'react-router-dom'
import { GetSession } from '../../lib/backend/auth'
import { getToken } from '../../lib/token'
import { UserGroupIcon } from '@heroicons/react/20/solid'
import SideBarLink from '../../components/SideBarLink'
// import { Session } from "../../../../backend/models/session"
import React from 'react'
import { UserShow } from '../../lib/backend/user'
import { JobIndex } from '../../lib/backend/job'
import { OccupationIndex } from '../../lib/backend/occupation'

type candidate_allowed = "Everyone" | "Candidates" | "Clients" | "Admin"

interface Page {
	type: string,
	candidate_allowed: candidate_allowed
	name: string,
	href: string,
	icon: any
}

function navigation(): Page[] {
	return [
		{ type: "Normal", candidate_allowed: "Everyone", name: 'Dashboard', href: '/home/dashboard', icon: HomeIcon },
		{ type: "Normal", candidate_allowed: "Candidates", name: 'Test', href: '/home/test', icon: ClipboardDocumentListIcon },
		{ type: "Normal", candidate_allowed: "Clients", name: 'Test Weighting', href: '/home/test-weighting', icon: ScaleIcon },
		{ type: "Normal", candidate_allowed: "Clients", name: 'Active Line Up', href: '/home/client-active-line-up', icon: AcademicCapIcon },
		{ type: "Normal", candidate_allowed: "Clients", name: 'Test Results', href: '/home/client-test-results', icon: ChartBarIcon },
		{ type: "Candidates", candidate_allowed: "Admin", name: 'All Candidates', href: '/home/candidates', icon: UserGroupIcon },
		{ type: "Candidates", candidate_allowed: "Admin", name: 'Entered Candidates', href: '/home/candidates-entered', icon: UserIcon },
		{ type: "Candidates", candidate_allowed: "Admin", name: 'Awaiting Testing', href: '/home/candidates-awaiting-testing', icon: PauseCircleIcon },
		{ type: "Candidates", candidate_allowed: "Admin", name: 'Test Completed', href: '/home/candidates-test-completed', icon: CheckCircleIcon },
		{ type: "Candidates", candidate_allowed: "Admin", name: 'Active Line Up', href: '/home/active-line-up', icon: UsersIcon },
		{ type: "Candidates", candidate_allowed: "Admin", name: 'Archived Candidates', href: '/home/archived-candidates', icon: ArchiveBoxXMarkIcon },
		// { type: "Candidates", candidate_allowed: "Admin", name: 'Evaluated: Passed', href: '/home/candidates-evaluated-passed', icon: UserPlusIcon },
		// { type: "Candidates", candidate_allowed: "Admin", name: 'Evaluated: Failed', href: '/home/candidates-evaluated-failed', icon: UserMinusIcon },
		// { type: "Clients", candidate_allowed: "Admin", name: 'Client List', href: '/home/clients-list', icon: ListBulletIcon },
		{ type: "Clients", candidate_allowed: "Admin", name: 'Client Users', href: '/home/client-users', icon: UsersIcon },
		// { type: "Clients", candidate_allowed: "Admin", name: 'Job List', href: '/home/clients-job-list', icon: BriefcaseIcon },
		{ type: "Testing", candidate_allowed: "Admin", name: 'Tests', href: '/home/setup-tests', icon: ClipboardDocumentListIcon },
		{ type: "Testing", candidate_allowed: "Admin", name: 'Test Results', href: '/home/tests-results', icon: ClipboardDocumentCheckIcon },
		{ type: "Setup", candidate_allowed: "Admin", name: 'Clients', href: '/home/setup-clients', icon: ListBulletIcon },
		{ type: "Setup", candidate_allowed: "Admin", name: 'Jobs', href: '/home/setup-jobs', icon: BriefcaseIcon },
		{ type: "Setup", candidate_allowed: "Admin", name: 'Occupation Types', href: '/home/setup-occupation-types', icon: SwatchIcon },
		{ type: "Setup", candidate_allowed: "Admin", name: 'Tests by Occupation', href: '/home/setup-tests-by-occupation', icon: TicketIcon },
		{ type: "Setup", candidate_allowed: "Admin", name: 'Practical Exam Tasks', href: '/home/setup-practical-exam-tasks', icon: PresentationChartBarIcon },
		// { name: 'Reports (Coming Soon)', href: '/home/reports', icon: ChartPieIcon, current: false },
	]
}

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

export default function HomePage() {
	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [token, setToken] = useState("")
	// const [user, setUser] = useState<Pick<Session, "id" | "admin">>({ id: 0, admin: "N" })
	const [user, setUser] = useState({ id: 0, admin: "N" })
	const [candidate, setCandidate] = useState("")
	const [arrived, setArrived] = useState(false)
	const [fullName, setFullName] = useState("")

	const [jobs, setJobs] = useState([])

	const navigate = useNavigate();

	const currentPath = useLocation().pathname

	useEffect(() => {
		if (token === "") {
			const token = getToken()
			setToken(token || "")
		}
		pageChange()
		//
		getData()
		//
	}, [navigate, currentPath])

	async function getData() {
		//
		const jobs = await JobIndex()
		const jobsBody = await jobs.json()
		//
		const occupationResponse = await OccupationIndex()
		const occupationJson = await occupationResponse.json()
		//
		const cleanJobsBody = jobsBody.map((job: any) => {
			const occupationsFiltered = occupationJson.filter((occupation: any) => occupation.id === job.occupation_id)
			if (occupationsFiltered.length > 0) {
				job.occupation_name = occupationsFiltered[0].occupation_name
			}
			return job
		})
		//
		setJobs(cleanJobsBody)
		//
	}

	async function pageChange() {
		const response = await GetSession()
		if (!response.ok) {
			navigate("/")
		} else {
			const body = await response.json()
			// setUser({ id: body.session.id, admin: body.session.admin })
			const meResponse = await UserShow(body.session.id)
			if (meResponse.ok) {
				const meBody = await meResponse.json()
				setFullName(`${meBody.first_name} ${meBody.last_name}`)
				setCandidate(meBody.candidate)
				setArrived(meBody.arrived)
				//
				if (currentPath.split("/")[1] === "home" && currentPath.split("/")[2] === "test") {
					// This url is ok
				} else {
					const filteredPages = navigation().filter(navRow => navRow.href === currentPath)
					if (filteredPages.length === 0) {
						navigate("/")
					} else {
						if (filteredPages[0].candidate_allowed === "Everyone") {
							// No problem
						} else if (filteredPages[0].candidate_allowed !== "Candidates" && meBody.candidate === "Y") {
							//
							navigate("/")
							//
						} else if (filteredPages[0].candidate_allowed !== "Clients" && meBody.candidate === "C") {
							//
							navigate("/")
							//
						}
					}
				}
				//
			} else {
				setCandidate("Y")
				setFullName("")
			}
		}
	}

	return (
		<>
			<div>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="relative z-50 xl:hidden" onClose={setSidebarOpen}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-900/80" />
						</Transition.Child>

						<div className="fixed inset-0 flex">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative w-full mr-16 flex max-w-xs flex-1">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
											<button id="sidebarButtonMobile" type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
												<span className="sr-only">Close sidebar</span>
												<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									{/* Sidebar component, swap this element with another sidebar if you like */}
									<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
										<div className="flex h-16 shrink-0 items-center mt-2">
											<img
												className="h-16 w-auto text-white brightness-0 invert mt-3 mx-auto"
												src="/logo512.png"
												alt="Peloton"
											/>
										</div>
										<SideBarLinks mobileOrDesktop="Mobile" setSidebarOpen={setSidebarOpen} candidate={candidate} jobs={jobs} arrived={arrived} />
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-gray-900 px-6 pb-4">
						<div className="flex h-16 shrink-0 items-center mt-2">
							<img
								className="h-16 w-auto text-white brightness-0 invert mt-3 mx-auto"
								src="/logo512.png"
								alt="Peloton"
							/>
						</div>
						<SideBarLinks mobileOrDesktop="Desktop" setSidebarOpen={setSidebarOpen} candidate={candidate} jobs={jobs} arrived={arrived} />
					</div>
				</div>

				<div className="xl:pl-72">
					<div className="sticky top-0 z-8 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
						<button id="sidebarButtonDesktop" type="button" className="-m-2.5 p-2.5 text-gray-700 xl:hidden" onClick={() => setSidebarOpen(true)}>
							<span className="sr-only">Open sidebar</span>
							<Bars3Icon className="h-6 w-6" aria-hidden="true" />
						</button>

						{/* Separator */}
						<div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

						{/* <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
							<div className="relative flex flex-1 text-2xl font-bold items-center">
								Ozland
							</div>
						</div> */}
						<div className="flex flex-1 gap-x-4 self-stretch place-content-end">
							<div className="hidden relative z-50 sm:flex flex-1" >
								<MagnifyingGlassIcon
									className="pointer-events-none absolute inset-y-0 left-0 h-full w-6 text-gray-400"
									aria-hidden="true"
								/>
								{/* <input
									id="search-field"
									className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
									placeholder="Search..."
									type="search"
									name="search"
								/> */}
								<div className="relative z-50 ml-6 mt-1 flex-grow w-96">
									<PageSelect />
								</div>
							</div>
							<div className="flex items-center gap-x-4 lg:gap-x-6">
								{/* Profile dropdown */}
								<Menu as="div" className="relative">
									<Menu.Button className="-m-1.5 flex items-center p-1.5">
										<span className="sr-only">Open user menu</span>
										{/* <UserCircleIcon className="h-8 w-8 rounded-full" /> */}
										{/* <span className="inline-block h-6 w-6 overflow-hidden rounded-full bg-gray-100">
											<svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
												<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
											</svg>
										</span> */}
										{/* <img
											className="h-8 w-8 rounded-full bg-gray-50"
											src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
											alt=""
										/> */}
										<span className="flex lg:items-center">
											<span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
												{fullName}
											</span>
											<ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
										</span>
									</Menu.Button>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
											{/* <Menu.Item>
												<div
													className='block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50'
												>
													Profile
												</div>
											</Menu.Item> */}
											<Menu.Item>
												<a
													className='block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50'
													href='/logout'
												>
													Logout
												</a>
											</Menu.Item>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
						</div>

					</div>

					<main className="py-4">
						<div className="px-4 sm:px-6 lg:px-8">
							<Outlet />
						</div>
					</main>
				</div>
			</div >
		</>
	)
}

function SideBarLinks({ mobileOrDesktop, setSidebarOpen, candidate, jobs, arrived }: any) {

	const location = useLocation().pathname;

	if (candidate === "") {
		return (
			<div role="status" className="mt-8 w-full grid justify-items-center">
				<svg aria-hidden="true" className="w-12 h-12 mr-4 text-center text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
					<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
				</svg>
			</div>
		)
	}

	return (
		<nav className="flex flex-1 flex-col">
			<ul className="flex flex-1 flex-col gap-y-7">
				<li>
					<ul className="-mx-2 space-y-1">
						{navigation().filter((item) => item.type === "Normal" && item.href !== "/home/test").map((item) => {
							if (item.name !== "Test Weighting" && item.name !== "Active Line Up" && item.name !== "Test Results") {
								//
								return (
									<li key={item.name}>
										<SideBarLink
											id={`${item.name}${mobileOrDesktop}`}
											link={item.href}
											onClick={() => setSidebarOpen(false)}
											icon={item.icon}
											text={item.name}
										/>
									</li>
								)
								//
							}
						})}
					</ul>
				</li>
				<li hidden={candidate !== "C"}>
					<div className="text-xs font-semibold leading-6 text-gray-400">
						Test Weighting
					</div>
					<ul className="-mx-2 mt-2 space-y-1">
						{navigation().filter((item) => item.type === "Normal" && item.href !== "/home/test").map((item) => {
							if (item.name === "Test Weighting" && candidate === "C") {
								//
								return jobs.map((job: any, index: number) => {
									return <li key={index}>
										<SideBarLink
											id={`${item.name}${mobileOrDesktop}`}
											link={`${item.href}?job_id=${job.id}`}
											onClick={() => setSidebarOpen(false)}
											icon={item.icon}
											text={`${job.job_number} - ${job.occupation_name}`}
										/>
									</li>
								})
								//
							}
						})}
					</ul>
				</li>
				<li hidden={candidate !== "C" || !arrived}>
					<div className="text-xs font-semibold leading-6 text-gray-400">
						Active Lineup
					</div>
					<ul className="-mx-2 mt-2 space-y-1">
						{navigation().filter((item) => item.type === "Normal" && item.href !== "/home/test").map((item) => {
							if (item.name === "Active Line Up" && candidate === "C" && arrived) {
								//
								return jobs.map((job: any, index: number) => {
									return <li key={index}>
										<SideBarLink
											id={`${item.name}${mobileOrDesktop}`}
											link={`${item.href}?job_id=${job.id}`}
											onClick={() => setSidebarOpen(false)}
											icon={item.icon}
											text={`${job.job_number} - ${job.occupation_name}`}
										/>
									</li>
								})
							}
						})}
					</ul>
				</li>
				<li hidden={candidate !== "C" || !arrived}>
					<div className="text-xs font-semibold leading-6 text-gray-400">
						Analytics
					</div>
					<ul className="-mx-2 mt-2 space-y-1">
						{navigation().filter((item) => item.type === "Normal" && item.href !== "/home/test").map((item) => {
							if (item.name === "Test Results" && candidate === "C" && arrived) {
								//
								return jobs.map((job: any, index: number) => {
									return <li key={index}>
										<SideBarLink
											id={`${item.name}${mobileOrDesktop}`}
											link={`${item.href}?job_id=${job.id}`}
											onClick={() => setSidebarOpen(false)}
											icon={item.icon}
											text={`${job.job_number} - ${job.occupation_name}`}
										/>
									</li>
								})
								//
							}
						})}
					</ul>
				</li>

				<li hidden={candidate === "Y" || candidate === "C"}>
					<div className="text-xs font-semibold leading-6 text-gray-400">
						Candidates
					</div>
					<ul className="-mx-2 mt-2 space-y-1">
						{navigation().filter(item => item.type === "Candidates").map((item) => (
							<li key={item.name}>
								<SideBarLink
									id={`${item.name}${mobileOrDesktop}`}
									link={item.href}
									onClick={() => setSidebarOpen(false)}
									icon={item.icon}
									text={item.name}
								/>
							</li>
						))}
					</ul>
				</li>
				<li hidden={candidate === "Y" || candidate === "C"}>
					<div className="text-xs font-semibold leading-6 text-gray-400">Clients</div>
					<ul className="-mx-2 mt-2 space-y-1">
						{navigation().filter(item => item.type === "Clients").map((item) => (
							<li key={item.name}>
								<SideBarLink
									id={`${item.name}${mobileOrDesktop}`}
									link={item.href}
									onClick={() => setSidebarOpen(false)}
									icon={item.icon}
									text={item.name}
								/>
							</li>
						))}
					</ul>
				</li>
				<li hidden={candidate === "Y" || candidate === "C"}>
					<div className="text-xs font-semibold leading-6 text-gray-400">Testing</div>
					<ul className="-mx-2 mt-2 space-y-1">
						{navigation().filter(item => item.type === "Testing").map((item) => (
							<li key={item.name}>
								<SideBarLink
									id={`${item.name}${mobileOrDesktop}`}
									link={item.href}
									onClick={() => setSidebarOpen(false)}
									icon={item.icon}
									text={item.name}
								/>
							</li>
						))}
					</ul>
				</li>
				<li className="mt-auto">
					<div hidden={candidate === "Y" || candidate === "C"}>
						<div className="text-xs font-semibold leading-6 text-gray-400">Setup</div>
						<ul className="-mx-2 mt-2 space-y-1">
							{navigation().filter(item => item.type === "Setup").map((item) => (
								<li key={item.name}>
									<SideBarLink
										id={`${item.name}${mobileOrDesktop}`}
										link={item.href}
										onClick={() => setSidebarOpen(false)}
										icon={item.icon}
										text={item.name}
									/>
								</li>
							))}
						</ul>
					</div>
				</li>
				<li>
					<ul className="-mx-2 space-y-1">
						{/* <li hidden={candidate === "Y"}> */}
						{/* <div hidden={candidate === "Y" || candidate === "C"}>
							<SideBarLink
								id={`organisation${mobileOrDesktop}`}
								link={"/home/organisation"}
								onClick={() => setSidebarOpen(false)}
								icon={Cog6ToothIcon}
								text={"Organisation"}
							/>
						</div> */}
						{/* </li> */}
						<li className="">
							<SideBarLink
								id={`logout${mobileOrDesktop}`}
								link={"/logout"}
								onClick={() => setSidebarOpen(false)}
								icon={ArrowLeftOnRectangleIcon}
								text={"Logout"}
							/>
						</li>
					</ul>
				</li>
			</ul>
		</nav>
	)
}

const pages: Page[] = navigation()

export function PageSelect() {
	const [query, setQuery] = useState('')
	const [selectedPage, setSelectedPage] = useState(null)

	const navigate = useNavigate();

	const filteredPages =
		query === ''
			? pages
			: pages.filter((page) => {
				return page.name.toLowerCase().includes(query.toLowerCase())
			})

	const itemSelected = (event: any) => {
		navigate(event.href)
	}

	return (
		<Combobox as="div" value={selectedPage} onChange={itemSelected} className="w-96 relative z-500">
			<div className="relative mt-2 w-96 z-500">
				<Combobox.Input
					className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 ring-0 ring-inset ring-gray-300 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 opacity-100"
					onChange={(event) => {
						if (event.target.value.length === 0) {
							setSelectedPage(null)
						}
						setQuery(event.target.value)
					}}
					displayValue={(page: Page) => {
						if (query === '') {
							return ""
						} else {
							return page?.name
						}
					}}
					placeholder='Search...'
				/>

				{filteredPages.length > 0 && (
					<Combobox.Options className="relative z-500 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm opacity-100">
						{filteredPages.map((page: Page) => (
							<Combobox.Option
								key={page.name}
								value={page}
								className={({ active }) =>
									classNames(
										'relative z-500 cursor-default select-none py-2 pl-3 pr-9 opacity-100',
										active ? 'bg-indigo-600 text-white relative z-50' : 'text-gray-900 relative z-50 opacity-100'
									)
								}
							>
								{({ active, selected }) => (
									<>
										{/* <span className={classNames('block truncate', selected && 'font-semibold')}>{page.name}</span> */}
										{/* {selected && ( */}
										<Link
											to={page.href}
											className={classNames(
												'truncate flex text-left pr-4 relative z-50 opacity-100',
												active ? 'text-white relative z-50' : 'text-black-600 relative z-50 opacity-100'
											)}
										>
											{React.createElement(page.icon, { className: "relative z-50 h-6 w-6 shrink-0 mr-2 opacity-100" })}
											{page.name}
										</Link>
										{/* )} */}
									</>
								)}
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
		</Combobox>
	)
}