
export function dateFieldStringToUTCDate(inputDateString: string): Date | "" {
	// 2024-04-21 is 21st of April 2024
	//
	if (inputDateString.length === 10) {
		//
		const year = parseInt(inputDateString.substring(0, 4))
		const month = parseInt(inputDateString.substring(5, 7))
		const day = parseInt(inputDateString.substring(8, 10))
		//
		return new Date(Date.UTC(year, month, day, 0, 0, 0));
		//
	} else {
		//
		return ""
		//
	}
}

export function convertDateFieldToFieldDateFormat(inputDateObject: Date): string {
	//
	return `${inputDateObject.getUTCFullYear()}-${("0" + (inputDateObject.getUTCMonth())).slice(-2).toString()}-${("0" + inputDateObject.getUTCDate()).slice(-2).toString()}`
	//
}
