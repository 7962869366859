import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function ClientIndex() {
	return FetchRequestGET(`/clients`)
}

export async function ClientShow(id: number) {
	return FetchRequestGET(`/clients/${id}`)
}

export async function ClientUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/clients/${id}`,
		body
	)
}

export async function ClientCreate(body: any) {
	return FetchRequestPOST(
		`/clients`,
		body
	)
}

export async function ClientDelete(id: number) {
	return FetchRequestDELETE(
		`/clients/${id}`)
}

