
export default function College({ formDataCollege, setFormDataCollege }: any) {
	return (<div>
		<label htmlFor="college" className="block text-sm font-medium leading-6 text-gray-900">
			College
		</label>
		<div className="mt-2">
			{formDataCollege && <select
				id="college"
				name="college"
				onChange={(e) => setFormDataCollege(e.target.value)}
				value={formDataCollege}
				className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
			>
				<option value="No">No</option>
				<option value="Yes">Yes</option>
			</select>}
		</div>
	</div>
	)
}
