import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function OccupationTestIndex() {
	return FetchRequestGET(`/occupation-tests`)
}

export async function OccupationTestShow(id: number) {
	return FetchRequestGET(`/occupation-tests/${id}`)
}

export async function OccupationTestUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/occupation-tests/${id}`,
		body
	)
}

export async function OccupationTestCreate(body: any) {
	return FetchRequestPOST(
		`/occupation-tests`,
		body
	)
}

export async function OccupationTestDelete(id: number) {
	return FetchRequestDELETE(
		`/occupation-tests/${id}`)
}

