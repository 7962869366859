import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function CandidateAnswerIndex(test_id: number) {
	return FetchRequestGET(`/candidate-answers?test_id=${test_id}`)
}

export async function CandidateAnswerShow(id: number) {
	return FetchRequestGET(`/candidate-answers/${id}`)
}

export async function CandidateAnswerUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/candidate-answers/${id}`,
		body
	)
}

export async function CandidateAnswerCreate(body: any) {
	return FetchRequestPOST(
		`/candidate-answers`,
		body
	)
}

export async function CandidateAnswerDelete(id: number) {
	return FetchRequestDELETE(
		`/candidate-answers/${id}`)
}

