export function url() {
	if (process.env.NODE_ENV === "development") {
		return "http://localhost:3333"
	} else {
		// return "https://sea-lion-app-fkiuc.ondigitalocean.app"
		return "https://api.peloton.greenfox.dev"
	}
}

export function pageUrl() {
	if (process.env.NODE_ENV === "development") {
		return "http://localhost:3000"
	} else {
		return "https://peloton.greenfox.dev"
	}
}