import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function TestIndex() {
	return FetchRequestGET(`/tests`)
}

export async function TestShow(id: number) {
	return FetchRequestGET(`/tests/${id}`)
}

export async function TestUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/tests/${id}`,
		body
	)
}

export async function TestCreate(body: any) {
	return FetchRequestPOST(
		`/tests`,
		body
	)
}

export async function TestDelete(id: number) {
	return FetchRequestDELETE(
		`/tests/${id}`)
}

export async function MyTests() {
	return FetchRequestGET(`/tests/me`)
}

export async function CopyTest(id: number) {
	return FetchRequestPOST(
		`/tests/copy/${id}`,
		{}
	)
}
