import { useEffect, useState } from 'react'
import { Test } from '../../../../backend/models/test'
import { TestShow } from '../../lib/backend/test'
import { useNavigate, useParams } from 'react-router-dom'
import { Question } from '../../../../backend/models/question'
import { Answer } from '../../../../backend/models/answer'
import { CandidateAnswerCreate, CandidateAnswerIndex, CandidateAnswerUpdate } from '../../lib/backend/candidate-answer'
import { CandidateAnswer } from '../../../../backend/models/candidate_answer'
import { UserTestCreate, UserTestIndex, UserTestShow, UserTestUpdate } from '../../lib/backend/user-test'
import { UserTest } from '../../../../backend/models/user_test'

type TestData = {
	id: Test["id"],
	test_code: Test["test_code"],
	test_description: Test["test_description"],
	test_heading: Test["test_heading"],
	test_introduction: Test["test_introduction"]
}

type QuestionData = {
	id: Question["id"],
	question_order_number: Question["question_order_number"],
	question_text: Question["question_text"],
	question_image: Question["question_image"],
	answer_type: Question["answer_type"],
	numeric_min: Question["numeric_min"],
	numeric_max: Question["numeric_max"],
	answers: {
		id: Answer["id"],
		answer_sequence: Answer["answer_sequence"],
		answer_text: Answer["answer_text"]
	}[]
}

type AnswerData = {
	id: CandidateAnswer["id"]
	test_id: CandidateAnswer["test_id"],
	question_id: CandidateAnswer["question_id"],
	entered_text: string
}

export default function CandidateQuestions(props: any) {

	const [initialLoading, setInitialLoading] = useState(true)
	const [testData, setTestData] = useState<TestData>({
		id: 0,
		test_code: "",
		test_description: "",
		test_heading: "",
		test_introduction: ""
	})
	const [questionData, setQuestionData] = useState<QuestionData[]>([])
	const [answerData, setAnswerData] = useState<AnswerData[]>([])
	const [status, setStatus] = useState<"Incomplete" | "Complete">("Incomplete")

	const [readOnlyMode, setReadOnlyMode] = useState(false)

	const navigate = useNavigate();

	let test_id_num = 0
	let { test_id } = useParams();
	if (test_id === undefined) {
		// navigate("/home/dashboard")
		test_id_num = props.test_id
	} else {
		test_id_num = parseInt(test_id)
	}

	useEffect(() => {
		getTests()
	}, [test_id_num])

	async function getTests() {
		//
		if (props.readOnlyMode === true) {
			setReadOnlyMode(true)
		}
		//
		if (test_id_num !== 0) {
			const response = await TestShow(test_id_num)
			if (!response.ok) {
				// navigate("/")
			} else {
				const body = await response.json()
				setTestData({
					id: body.id,
					test_code: body.test_code,
					test_description: body.test_description,
					test_heading: body.test_heading,
					test_introduction: body.test_introduction
				})
				setQuestionData(body.questions)
				//
				const answerResult = await CandidateAnswerIndex(test_id_num)
				if (answerResult.ok) {
					//
					// First get the gathered answers
					const requestedBody = await answerResult.json()
					const fetchedAnswers = requestedBody
					// This will eventually change to pull the candidate answers....
					const answerTemplate: AnswerData[] = body.questions.map((bodyQuestion: QuestionData): AnswerData => {
						const filteredFetchedAnswers = fetchedAnswers.filter((fAnswer: Answer) => fAnswer.question_id === bodyQuestion.id)
						if (filteredFetchedAnswers.length > 0) {
							return filteredFetchedAnswers[0]
						} else {
							return {
								id: 0,
								test_id: test_id_num,
								question_id: bodyQuestion.id,
								entered_text: ""
							}
						}
					})
					setAnswerData(answerTemplate)
					//
					if (fetchedAnswers.length > 0) {
						//
						const userTestIndexResult = await UserTestIndex()
						if (userTestIndexResult.ok) {
							const userTestIndexBody = await userTestIndexResult.json()
							const userTestIndexFiltered = userTestIndexBody.filter((ut: UserTest) => ut.test_id === test_id_num)
							if (userTestIndexFiltered.length > 0) {
								const userTestResult = await UserTestShow(userTestIndexFiltered[0].id)
								if (userTestResult.ok) {
									const userTestBody = await userTestResult.json()
									if (userTestBody.status === "Complete") {
										setStatus("Complete")
									}
								}
							}
						}
						//
					}
					//
					setInitialLoading(false)
					//
				}
			}
		}
	}

	async function answerChanged(answer: AnswerData) {
		//
		if (readOnlyMode) {
			return
		}
		//
		if (answer.id === 0) {
			const result = await CandidateAnswerCreate({
				candidate_answer: {
					test_id: answer.test_id,
					question_id: answer.question_id,
					entered_text: answer.entered_text
				}
			})
			if (result.ok) {
				const body = await result.json()
				const updatedData = answerData.map(aData => {
					//
					if (aData.test_id === body.test_id && aData.question_id === body.question_id) {
						aData = body
					}
					//
					return aData
					//
				})
				setAnswerData(updatedData)
			} else {
				alert("There was an error saving the answer. Please contact Peloton.")
			}
		} else {
			const result = await CandidateAnswerUpdate(answer.id, {
				candidate_answer: {
					entered_text: answer.entered_text
				}
			})
			if (result.ok) {
				// Great!
			} else {
				alert("There was an error saving the answer. Please contact Peloton.")
			}
		}
		//
	}

	function generateRange(start: number, end: number) {
		// Check if the end value is greater than or equal to the start value
		if (end < start) {
			return [];
		}
		//
		// Initialize an empty array to store the result
		let result = [];
		//
		// Loop from start to end, pushing each value into the result array
		for (let i = start; i <= end; i++) {
			result.push(i);
		}
		//
		return result;
		//
	}

	const allQuestionsAnswered = () => {
		const answeredQuestions = answerData.filter(ans => ans.entered_text.length > 0)
		return answeredQuestions.length === answerData.length
	}

	const possiblyComplete = async () => {
		//
		if (allQuestionsAnswered() && status === "Incomplete") {
			const userTestIndexResult = await UserTestIndex()
			if (userTestIndexResult.ok) {
				const userTestIndexBody = await userTestIndexResult.json()
				const userTestIndexFiltered = userTestIndexBody.filter((ut: UserTest) => ut.test_id === test_id_num)
				if (userTestIndexFiltered.length > 0) {
					await UserTestUpdate(userTestIndexFiltered[0].id, { status: "Complete" })
				} else {
					await UserTestCreate({ test_id: test_id_num, status: "Complete" })
				}
			}
		}
		//
		navigate("/home/dashboard")
		//
	}

	return (
		<div className='flex flex-col mt-12' id="testModal">
			{/* <div className="mb-8 -my-6">
				<button
					className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
				>
					<Link
						to="/home/dashboard">
						Back to Dashbord
					</Link>
				</button>
			</div> */}
			<div className="text-2xl font-semibold mb-4 flex justify-between">
				<h1>Peloton Testing Center: {testData.test_description}</h1>
				<img src="/peloton-logo.png" alt="" className="h-18" hidden={initialLoading} />
			</div>
			<div className="w-full" hidden={!initialLoading}>
				<div className="flex w-full justify-center">
					<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
						<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
					</svg>
				</div>
			</div>
			<div>
				<div className="my-4 sm:mx-auto sm:w-full">
					<div className="space-y-12">
						<div className={props.test_id ? "pb-12 mt-4" : "border-b border-gray-900/10 pb-12 mt-4"}>
							<div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">

								<div className="sm:col-span-2">
									<label htmlFor="test_code" className="block text-sm font-medium leading-6 text-gray-900">
										Test Code
									</label>
									<div className="mt-2">
										<input
											id="test_code"
											name="test_code"
											type="text"
											autoComplete="test_code"
											// required
											readOnly
											// onChange={(e) => setFormDataTestCode(e.target.value)}
											value={testData.test_code}
											className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
										/>
									</div>
								</div>

								{/* <div className="sm:col-span-2">
									<label htmlFor="test_description" className="block text-sm font-medium leading-6 text-gray-900">
										Test Description
									</label>
									<div className="mt-2">
										<input
											id="test_description"
											name="test_description"
											type="text"
											autoComplete="test_description"
											// required
											readOnly
											// onChange={(e) => setFormDataTestDescription(e.target.value)}
											value={testData.test_description}
											className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
										/>
									</div>
								</div> */}

								<div className="sm:col-span-2">
									<label htmlFor="test_heading" className="block text-sm font-medium leading-6 text-gray-900">
										Test Heading
									</label>
									<div className="mt-2">
										<input
											id="test_heading"
											name="test_heading"
											type="text"
											autoComplete="test_heading"
											// required
											readOnly
											// onChange={(e) => setFormDataTestHeading(e.target.value)}
											value={testData.test_heading}
											className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
										/>
									</div>
								</div>

								<div className="sm:col-span-2">
									<label htmlFor="test_introduction" className="block text-sm font-medium leading-6 text-gray-900">
										Test Introduction
									</label>
									<div className="mt-2">
										<input
											id="test_introduction"
											name="test_introduction"
											type="text"
											autoComplete="test_introduction"
											// required
											readOnly
											// onChange={(e) => setFormDataTestIntroduction(e.target.value)}
											value={testData.test_description}
											className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
										/>
									</div>
								</div>

								{questionData
									.sort((a, b) => a.question_order_number - b.question_order_number)
									.map((questionRow, index) => {
										//
										let questionAnswered = false
										const filteredAnswerData = answerData.filter(aData => aData.question_id === questionRow.id)
										if (filteredAnswerData.length > 0) {
											if (filteredAnswerData[0].entered_text.length > 0) {
												questionAnswered = true
											}
										}
										//
										let extraColors = "bg-red-200 border-red-300"
										if (questionAnswered) {
											extraColors = "bg-green-300 border-green-400"
										}
										//
										return <div key={index} className={"col-span-full flex flex-col border-2 p-6 rounded-lg shadow-lg " + extraColors}>

											{/* <div>
												<div className="sm:col-span-full">
													<label htmlFor="page_seq" className="block text-sm font-medium leading-6 text-gray-900">
														Question Number
													</label>
													<div className="mt-2 flex gap-x-1">
														<input
															id="page_seq"
															name="page_seq"
															type="text"
															autoComplete="page_seq"
															required
															readOnly
															value={questionRow.question_order_number.toFixed(2)}
															className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
											</div> */}

											<div className="mt-4">
												{/* <label htmlFor="question_text" className="block text-sm font-medium leading-6 text-gray-900"> */}
												Question {questionRow.question_order_number}: {questionRow.question_text}
												{/* </label> */}
												{/* <div className="mt-2">
													<textarea
														id="question_text"
														name="question_text"
														rows={4}
														autoComplete="question_text"
														value={questionRow.question_text}
														readOnly
														// onChange={(e) => {
														// 	const updatedRows = questionList.map(questionRowToUpdate => {
														// 		if (questionRowToUpdate.id === questionRow.id) {
														// 			questionRowToUpdate.question_text = e.target.value
														// 		}
														// 		return questionRowToUpdate
														// 	})
														// 	setQuestionList(updatedRows)
														// }}
														className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
													/>
												</div> */}
											</div>

											{/* <div className="mt-4">
												<label htmlFor="answer_type" className="block text-sm font-medium leading-6 text-gray-900">
													Answer Type
												</label>
												<div className="mt-2">
													<select
														id="answer_type"
														name="answer_type"
														value={questionRow.answer_type}
														// onChange={(e) => {
														// 	const updatedRows = questionList.map(questionRowToUpdate => {
														// 		if (questionRowToUpdate.id === questionRow.id) {
														// 			if (e.target.value === "Single Multiple Choice") {
														// 				//
														// 				questionRowToUpdate.answer_type = e.target.value
														// 				//
														// 			} else if (e.target.value === "Boolean") {
														// 				//
														// 				questionRowToUpdate.answer_type = e.target.value
														// 				//
														// 			} else if (e.target.value === "Numeric") {
														// 				//
														// 				questionRowToUpdate.answer_type = e.target.value
														// 				//
														// 			}
														// 		}
														// 		return questionRowToUpdate
														// 	})
														// 	setQuestionList(updatedRows)
														// }}
														className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
													>
														<option value="Single Multiple Choice">Multiple Choice (Single)</option>
														<option value="Boolean">True/False</option>
														<option value="Numeric">Numeric</option>
													</select>
												</div>
											</div> */}

											<div
												hidden={questionRow.answer_type !== "Numeric"}
												className='mt-4'
											>
												<select
													name=""
													id=""
													disabled={readOnlyMode}
													value={answerData.length > 0 ? answerData.filter(aData => aData.question_id === questionRow.id)[0].entered_text : ""}
													onChange={e => {
														if (status === "Complete") {
															return
														}
														const result = answerData.map(aData => {
															if (aData.question_id === questionRow.id) {
																aData.entered_text = e.target.value
																answerChanged(aData)
															}
															return aData
														})
														setAnswerData(result)
													}}
													className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
												>
													<option value="">Please select an answer</option>
													{generateRange(questionRow.numeric_min, questionRow.numeric_max).map(numeric => {
														return <option key={numeric} value={numeric}>{numeric}</option>
													})}
												</select>
											</div>
											{/* <div
												className="col-span-2 mt-6"
												hidden={questionRow.answer_type !== "Numeric"}
											>
												<label htmlFor="numeric_min" className="block text-sm font-medium leading-6 text-gray-900">
													Numeric Minimum Value
												</label>
												<div className="mt-2 flex gap-x-1">
													<input
														id="numeric_min"
														name="numeric_min"
														type="text"
														autoComplete="numeric_min"
														required
														readOnly
														value={questionRow.numeric_min.toFixed(2)}
														className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>

											<div
												className="col-span-2 mt-6"
												hidden={questionRow.answer_type !== "Numeric"}
											>
												<label htmlFor="numeric_max" className="block text-sm font-medium leading-6 text-gray-900">
													Numeric Maximum Value
												</label>
												<div className="mt-2 flex gap-x-1">
													<input
														id="numeric_max"
														name="numeric_max"
														type="text"
														autoComplete="numeric_max"
														required
														readOnly
														value={questionRow.numeric_max.toFixed(2)}
														className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
													/>
												</div>
											</div> */}

											<div
												className="col-span-2 mt-6"
												hidden={questionRow.answer_type !== "Boolean"}
											>
												<select
													className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
													name=""
													id=""
													disabled={readOnlyMode}
													value={answerData.length > 0 ? answerData.filter(aData => aData.question_id === questionRow.id)[0].entered_text : ""}
													onChange={e => {
														if (status === "Complete") {
															return
														}
														const result = answerData.map(aData => {
															if (aData.question_id === questionRow.id) {
																aData.entered_text = e.target.value
																answerChanged(aData)
															}
															return aData
														})
														setAnswerData(result)
													}}
												>
													<option value="">Please select an answer</option>
													<option value="true">True</option>
													<option value="false">False</option>
												</select>
											</div>

											<div
												className="col-span-2 mt-6"
												hidden={questionRow.answer_type !== "Single Multiple Choice"}
											>
												<fieldset>
													<legend className="sr-only">Notifications</legend>
													<div className="space-y-5">
														{questionRow.answers
															.sort((a: { answer_sequence: number }, b: { answer_sequence: number }) => a.answer_sequence - b.answer_sequence)
															.map((answerRow, index) => {
																//
																return <div key={index} className="flex items-start">
																	<div className="flex h-6 items-center">
																		<input
																			id={`${answerRow.answer_text}-${questionRow.id}`}
																			aria-describedby={`${answerRow.answer_text}-description`}
																			name={`${answerRow.answer_text}-${questionRow.id}`}
																			type="checkbox"
																			disabled={readOnlyMode}
																			checked={answerData.length > 0 ? answerData.filter(aData => aData.question_id === questionRow.id)[0].entered_text === answerRow.answer_text : false}
																			onChange={e => {
																				if (status === "Complete") {
																					return
																				}
																				if (e.target.checked) {
																					const result = answerData.map(aData => {
																						if (aData.question_id === questionRow.id) {
																							aData.entered_text = answerRow.answer_text
																							answerChanged(aData)
																						}
																						return aData
																					})
																					setAnswerData(result)
																				} else {
																					const result = answerData.map(aData => {
																						if (aData.question_id === questionRow.id) {
																							aData.entered_text = ""
																							answerChanged(aData)
																						}
																						return aData
																					})
																					setAnswerData(result)
																				}
																			}}
																			className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
																	</div>
																	<div className="ml-3 text-sm leading-6">
																		<label htmlFor={`${answerRow.answer_text}-${questionRow.id}`} className="font-medium text-gray-900">
																			{answerRow.answer_text}
																		</label>
																	</div>
																</div>
															})}
													</div>
												</fieldset>
												{/* {questionRow.answers
													.sort((a: { answer_sequence: number }, b: { answer_sequence: number }) => a.answer_sequence - b.answer_sequence)
													.map((answerRow, index) => {
														return <div key={index} className="col-span-full flex flex-col border-2 border-gray-300 p-6 rounded-lg shadow-lg mb-4">

															<div>
																<div className="sm:col-span-full">
																	<label htmlFor="answer_sequence" className="block text-sm font-medium leading-6 text-gray-900">
																		Answer Seq
																	</label>
																	<div className="mt-2 flex gap-x-1">
																		<input
																			id="answer_sequence"
																			name="answer_sequence"
																			type="text"
																			autoComplete="answer_sequence"
																			required
																			readOnly
																			value={answerRow.answer_sequence.toFixed(2)}
																			className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>
															</div>

															<div className="mt-4">
																<label htmlFor="answer_text" className="block text-sm font-medium leading-6 text-gray-900">
																	Answer Text
																</label>
																<div className="mt-2">
																	<textarea
																		id="answer_text"
																		name="answer_text"
																		rows={4}
																		autoComplete="answer_text"
																		value={answerRow.answer_text}
																		// onChange={(e) => {
																		// 	const updatedRows = questionList.map(questionRowToUpdate => {
																		// 		if (questionRowToUpdate.id === questionRow.id) {
																		// 			const updatedAnswers = questionRowToUpdate.answers.map(answerRowToUpdate => {
																		// 				//
																		// 				if (answerRowToUpdate.id === answerRow.id) {
																		// 					answerRowToUpdate.answer_text = e.target.value
																		// 				}
																		// 				//
																		// 				return answerRowToUpdate
																		// 				//
																		// 			})
																		// 			questionRowToUpdate.answers = updatedAnswers
																		// 		}
																		// 		return questionRowToUpdate
																		// 	})
																		// 	setQuestionList(updatedRows)
																		// }}
																		className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																	/>
																</div>
															</div>
														</div>
													})} */}
											</div>

										</div>
									})}

							</div>

						</div>
						<div className="text-center mb-8" hidden={!props.test_id}>
							<button
								onClick={props.turnOffTest}
								className="w-full text-center items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
							>
								Back
							</button>
						</div>
						<div className="text-center" hidden={props.test_id}>
							<button
								onClick={possiblyComplete}
								className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
							>
								{allQuestionsAnswered() && status === "Incomplete" ? "Complete" : "Back to Dashboard"}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
