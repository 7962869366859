import { useEffect, useState } from "react"
// import supabase from "../../lib/supabase"
import {
	useNavigate
} from "react-router-dom";
import { UpdatePassword } from "../../lib/backend/auth";
import { setToken } from "../../lib/token";
import { LoginUser } from "../../lib/backend/auth";

export default function RegisterPage() {
	const [formDataPassword, setFormDataPassword] = useState("")
	const [formDataPasswordConfirm, setFormDataPasswordConfirm] = useState("")
	//
	const [formDataPasswordInitial, setFormDataPasswordInitial] = useState(false)
	//
	const [formDataError, setFormDataError] = useState(false)
	const [formDataErrorText, setFormDataErrorText] = useState("")
	const [formDataPostError, setFormDataPostError] = useState(false)
	const [formDataPostErrorText, setFormDataPostErrorText] = useState("")
	const [formDataSuccess, setFormDataSuccess] = useState(false)
	const [formDataSuccessText, setFormDataSuccessText] = useState("")

	const [loginSuccess, setLoginSuccess] = useState(false)

	const [sid, setSid] = useState("")

	const navigate = useNavigate();

	useEffect(() => {
		if (loginSuccess) {
			navigate("/home/dashboard")
		} else {
			checkPasswords()
		}
	}, [formDataPassword, formDataPasswordConfirm, loginSuccess])

	async function formSubmit(event: any) {
		event.preventDefault()

		if (formDataError === false && formDataSuccess === false) {
			setFormDataPostError(false)
			let params = new URLSearchParams(document.location.search)
			// let email = params.get("email") || ""
			const response = await UpdatePassword(formDataPassword, sid)
			if (response.ok) {
				setFormDataSuccess(true)
				setFormDataSuccessText("Your password has been updated")
				setFormDataPostError(false)
				//
				const body1 = await response.json()
				//
				// Log the user in
				//
				const response2 = await LoginUser(
					body1.email || "",
					formDataPassword
				)
				if (response2.ok) {
					console.log("OK")
					const body = await response2.json()
					setToken(body.token)
					setLoginSuccess(true)
				} else {
					console.log("Not OK")
				}
				//
			} else {
				setFormDataSuccess(false)
				setFormDataPostError(true)
				setFormDataPostErrorText("Your password update link has expired")
			}
		} else {
			setFormDataPostError(true)
			setFormDataPostErrorText("Your password update link has expired")
		}
	}

	async function checkPasswords() {
		if (formDataPassword === "" && formDataPasswordConfirm === "") {
			// Do nothing
		} else if (formDataPassword !== "" && formDataPasswordConfirm === "" && formDataPasswordInitial === false) {
			setFormDataPasswordInitial(true)
		} else {
			if (formDataPassword !== formDataPasswordConfirm) {
				setFormDataError(true)
				setFormDataErrorText("Password and Password Confirm must be a match")
			} else if (formDataPassword.length < 6) {
				setFormDataError(true)
				setFormDataErrorText("Password must be 6 characters or more")
			} else {
				setFormDataError(false)
				setFormDataErrorText("")
			}
		}
	}

	if (sid === "") {
		let params = new URLSearchParams(document.location.search)
		let sidRaw = params.get("sid")
		if (sidRaw !== null) {
			setSid(sidRaw)
		}
	}

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<img
						className="mx-auto h-24 w-auto"
						src="/peloton-logo.png"
						alt="Candidate Tracker"
					/>
					<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
						Change password
					</h2>
				</div>

				<div hidden={!formDataError} className="mx-auto text-red-500 mt-8 text-xl">
					{formDataErrorText}
				</div>

				<div hidden={!formDataPostError} className="mx-auto text-red-500 mt-8 text-xl">
					{formDataPostErrorText}
				</div>

				<div hidden={formDataError && !formDataSuccess} className="mx-auto text-green-500 mt-8 text-xl">
					{formDataSuccessText}
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={formSubmit}>
						<div>
							<div className="flex items-center justify-between">
								<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
									Password
								</label>
							</div>
							<div className="mt-2">
								<input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									onChange={(e) => {
										setFormDataPassword(e.target.value)
									}}
									value={formDataPassword}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<div className="flex items-center justify-between">
								<label htmlFor="passwordConfirm" className="block text-sm font-medium leading-6 text-gray-900">
									Password Confirm
								</label>
							</div>
							<div className="mt-2">
								<input
									id="passwordConfirm"
									name="passwordConfirm"
									type="password"
									autoComplete="current-password-confirm"
									required
									onChange={(e) => {
										setFormDataPasswordConfirm(e.target.value)
									}}
									value={formDataPasswordConfirm}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Change password
							</button>
						</div>
					</form>

				</div>
			</div>
		</>
	)
}
