import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TemplateTable from "../../components/TemplateTable"
import { XMarkIcon } from '@heroicons/react/24/outline'
import { CopyTest, TestCreate, TestDelete, TestIndex, TestShow, TestUpdate } from '../../lib/backend/test'
import { QuestionIndex } from '../../lib/backend/question'
import { Question } from "../../../../backend/models/question"
import { Answer } from "../../../../backend/models/answer"
import Accordion from './Accordion'
import { Test } from '../../../../backend/models/test'
import MessageModal from '../../components/MessageModal'
import SelectElement from '../../components/SelectElement'

interface QuestionWithAnswers {
	id: number,
	question_order_number: number,
	question_text: string,
	question_image: string,
	answer_type: "Numeric" | "Single Multiple Choice" | "Boolean",
	numeric_min: number,
	numeric_max: number,
	correct_answer: string,
	answers: Answer[]
}

const columns = [
	{
		header: "Test Code",
		accessorKey: "test_code",
	},
	{
		header: "Test Description",
		accessorKey: "test_description",
	},
	{
		header: "Test Status",
		accessorKey: "test_status",
	},
]

const statuses = [
	{
		value: "",
		label: "All"
	},
	{
		value: "Active (Locked)",
		label: "Active (Locked)"
	},
	{
		value: "Entered",
		label: "Entered"
	},
	{
		value: "Retired",
		label: "Retired"
	},
]

const testStatusFilterOptions = [
	"Active (Locked)",
	"Entered",
	"Retired"
]

export default function TestMasterPage() {
	const [loadingData, setLoadingData] = useState(true)
	const [tableData, setTableData] = useState<Test[]>([])
	const [dropdown, setShowDropdwon] = useState(true)
	const [createModalOpen, setCreateModalOpen] = useState(false)
	const [createModalLoading, setcreateModalLoading] = useState(false)
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [editModalLoading, setEditModalLoading] = useState(false)
	const [showCreateError, setShowCreateError] = useState(false)
	const [showMaintainError, setShowMaintainError] = useState(false)

	const [validName, setValidName] = useState(false)

	const [formDataID, setFormDataID] = useState(0)

	const [formDataTestCode, setFormDataTestCode] = useState("")
	const [formDataTestDescription, setFormDataTestDescription] = useState("")
	const [formDataTestHeading, setFormDataTestHeading] = useState("")
	const [formDataTestIntroduction, setFormDataTestIntroduction] = useState("")

	const [questionList, setQuestionList] = useState<QuestionWithAnswers[]>([])
	const [questionsToggled, setCollapsedQuestions] = useState<boolean[]>([])
	const [collapsedAnswers, setCollapsedAnswers] = useState<boolean[]>([])

	const [testStatus, setTestStatus] = useState<Test["test_status"]>("Entered")

	const [showMessageModal, setShowMessageModal] = useState(false)

	const [testStatusSelectedFilterOptions, setTestStatusSelectedFilterOptions] = useState<string[]>([])
	const cancelButtonRef = useRef(null)

	const [statusType, setStatusType] = useState(statuses[0])

	useEffect(() => {
		getData()
	}, [])

	// sets state for collapsed questions
	useEffect(() => {
		setCollapsedQuestions(questionList.map(x => false))
		setCollapsedAnswers(questionList.map(x => false))
	}, [questionList])

	// Expand or collapse specific question
	function toggleQuestion(i: number) {
		const newQuestions = [...questionsToggled]
		newQuestions[i] = !newQuestions[i]
		setCollapsedQuestions(newQuestions)
	}
	// // sets state for collapsed answer
	// useEffect(() => {
	// 	if (answersToggled.length != questionList.answe) {
	// 		answersToggled.length = questionRow.answers.length
	// 	}
	// }, [questionList])

	// // Expand or collapse specific answer
	// function toggleAnswer(i: number) {
	// 	const newAnswers = [...answersToggled]
	// 	newAnswers[i] = !newAnswers[i]
	// 	setAnswersToggled(newAnswers)
	// }

	function setCorrectAnswer(i: number, text: string) {
		console.log('yeerrrr', text)
		const newQuestions = [...questionList]
		newQuestions[i].correct_answer = text
		setQuestionList(newQuestions)
	}

	async function getData() {
		//
		const tests = await TestIndex()
		const testsJson = await tests.json()
		//
		setTableData(testsJson);
		//
		setLoadingData(false)
		//
	}

	async function createQuestion() {
		//
		let latestIdNumber = 0
		let latestOrderNumber = 0
		//
		let sortedQuestions = questionList.sort((a, b) => a.question_order_number - b.question_order_number)
		if (sortedQuestions.length > 0) {
			latestOrderNumber = sortedQuestions[sortedQuestions.length - 1].question_order_number
		}
		//
		let sortedQuestionsId = questionList.sort((a, b) => a.id - b.id)
		if (sortedQuestionsId.length > 0) {
			latestIdNumber = sortedQuestionsId[sortedQuestionsId.length - 1].id
		}
		//
		let questions: QuestionWithAnswers[] = []
		questions = [...questionList,
		{
			id: latestIdNumber + 1,
			question_order_number: latestOrderNumber + 1,
			question_text: "This is a new question",
			question_image: "",
			correct_answer: "",
			answer_type: "Single Multiple Choice",
			numeric_min: 1,
			numeric_max: 10,
			answers: []
		}
		]
		setQuestionList(questions)
	}

	async function createAnswer(question_id: number) {
		//
		let latestAnswerId = 0
		let latestAnswerNumber = 0
		//
		let questions: QuestionWithAnswers[] = questionList.map(questionRow => {
			//
			if (questionRow.id === question_id) {
				//
				let sortedAnswers = questionRow.answers.sort((a, b) => a.answer_sequence - b.answer_sequence)
				if (sortedAnswers.length > 0) {
					latestAnswerNumber = sortedAnswers[sortedAnswers.length - 1].answer_sequence
				}
				//
				let sortedAnswersId = questionRow.answers.sort((a, b) => a.id - b.id)
				if (sortedAnswersId.length > 0) {
					latestAnswerId = sortedAnswersId[sortedAnswersId.length - 1].id
				}
				//
				const updatedAnswers: Answer[] = [...sortedAnswers, {
					id: latestAnswerId + 1,
					answer_sequence: latestAnswerNumber + 1,
					answer_text: "New Answer",
					question_id: question_id
				}]
				//
				questionRow.answers = updatedAnswers
			}
			//
			return questionRow
			//
		})
		setQuestionList(questions)
	}

	async function removeQuestion(question_id: number) {
		let questions = questionList.filter(row => row.id !== question_id)
		setQuestionList(questions)
	}

	async function removeAnswer(question_id: number, answer_id: number) {
		let questions = questionList.map(row => {
			//
			if (row.id === question_id) {
				const answersUpdated = row.answers.filter(answerRow => answerRow.id !== answer_id)
				row.answers = answersUpdated
			}
			//
			return row
			//
		})
		setQuestionList(questions)
	}

	function openCreateModal() {
		//
		setFormDataTestCode("")
		setFormDataTestDescription("")
		setFormDataTestHeading("")
		setFormDataTestIntroduction("")
		//
		setQuestionList([])
		setcreateModalLoading(false)
		setCreateModalOpen(true)
		setShowCreateError(false)
		//
	}

	async function submitCreateForm(event: any) {
		//
		event.preventDefault();
		//
		setcreateModalLoading(true)
		setShowCreateError(false)
		//
		const createResponse = await TestCreate({
			test: {
				//
				test_code: formDataTestCode,
				test_description: formDataTestDescription,
				test_heading: formDataTestHeading,
				test_introduction: formDataTestIntroduction,
				//
				questions: questionList
				//
			}
		})
		//
		const body = await createResponse.json()
		//
		if (createResponse.ok) {
			//
			const tableDataUpdated: any = JSON.parse(JSON.stringify(tableData))
			tableDataUpdated.push({
				id: body.id,
				test_code: body.test_code,
				test_description: body.test_description,
				test_status: body.test_status
			})
			setTableData(tableDataUpdated)
			setCreateModalOpen(false)
		} else {
			setcreateModalLoading(false)
			setShowCreateError(true)
		}
		//
	}

	async function openMaintainModal(id: number) {
		//
		setEditModalLoading(true)
		setEditModalOpen(true)
		//
		setFormDataID(id)
		//
		// getFilesData(id)
		//
		const response = await TestShow(id)
		const body = await response.json()
		//
		setQuestionList(body.questions)
		//
		setFormDataTestCode(body.test_code)
		setFormDataTestDescription(body.test_description)
		setFormDataTestHeading(body.test_heading)
		setFormDataTestIntroduction(body.test_introduction)
		setTestStatus(body.test_status)
		//
		formDataValidation()
		//
		setEditModalLoading(false)
		//
	}

	async function submitMaintainForm(event: any) {
		//
		event.preventDefault();
		//
		setEditModalLoading(true)
		setShowMaintainError(false)
		//
		const response = await TestUpdate(formDataID, {
			test: {
				//
				test_code: formDataTestCode,
				test_description: formDataTestDescription,
				test_heading: formDataTestHeading,
				test_introduction: formDataTestIntroduction,
				//
				questions: questionList
				//
			}
		})
		//
		if (response.ok) {
			//
			const body = await response.json()
			//
			const tableDataUpdated: any = tableData.map((tableDataRow: any) => {
				if (tableDataRow.id === formDataID) {
					tableDataRow.test_code = body.test_code
					tableDataRow.test_description = body.test_description
					tableDataRow.test_heading = body.test_heading
					tableDataRow.test_introduction = body.test_introduction
				}
				return tableDataRow
			})
			setTableData(tableDataUpdated)
			setEditModalOpen(false)
		} else {
			setEditModalLoading(false)
			setShowMaintainError(true)
		}
	}

	async function submitDelete(event: any) {
		//
		event.preventDefault()
		setEditModalLoading(true)
		//
		const response = await TestDelete(formDataID)
		//
		if (response.ok) {
			const tableDataUpdated: any = tableData.filter((tableDataRow: any) => {
				return tableDataRow.id !== formDataID
			})
			setTableData(tableDataUpdated)
			setEditModalOpen(false)
		} else {
			setShowMaintainError(true)
			setEditModalLoading(false)
		}
	}

	async function submitRetired(event: any) {
		//
		event.preventDefault()
		setEditModalLoading(true)
		//
		const response = await TestUpdate(formDataID, {
			test: {
				test_status: "Retired"
			}
		})
		//
		if (response.ok) {
			const tableDataUpdated: any = tableData.map((tableDataRow: any) => {
				if (tableDataRow.id === formDataID) {
					tableDataRow.test_status = "Retired"
				}
				return tableDataRow
			})
			setTableData(tableDataUpdated)
			setEditModalOpen(false)
		} else {
			const body = await response.json()
			if (body.error === "An occupation is still attached so cannot Retire") {
				//
				setShowMessageModal(true)
				//
			} else {
				// Not sure what to do here...
			}
			setShowMaintainError(true)
			setEditModalLoading(false)
		}
	}

	async function attemptCopy() {
		//
		setEditModalLoading(true)
		//
		const response = await CopyTest(formDataID)
		//
		if (response.ok) {
			window.location.reload();
		} else {
			setShowMaintainError(true)
			setEditModalLoading(false)
		}
	}

	// const handleFocus = (event: any) => event.target.select();

	function formDataValidation() {
		// if (formDataClientName.length > 0) {
		// 	setValidName(true)
		// } else {
		// 	setValidName(false)
		// }
	}

	return (
		<>
			<div className="text-2xl font-semibold">
				Tests
			</div>
			<div role="status" className="mt-8" hidden={!loadingData}>
				<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
					<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
				</svg>
			</div>
			<div hidden={loadingData}>
				<TemplateTable
					data={tableData.filter(test => !statusType.value || test.test_status === statusType.value)}
					columns={columns}
					openCreateModal={openCreateModal}
					openMaintainModal={openMaintainModal}
					testStatusFilterOptions={testStatusFilterOptions}
					testStatusSelectedFilterOptions={testStatusSelectedFilterOptions}
					setTestStatusSelectedFilterOptions={setTestStatusSelectedFilterOptions}
					object_name="Test"
					disableCreate={false}
					bulk={false}
					hidePageButtons={false}
					initialSortState={[{ id: "test_code", desc: false }]}
					UnderComponent={() => {
						return <div className="sm:flex sm:items-end w-full mt-5 gap-4">
							<SelectElement items={statuses} selected={statusType} setSelected={setStatusType} title="Test Status" ></SelectElement>
						</div>
					}} />
			</div>

			<Transition.Root show={createModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100"
					initialFocus={cancelButtonRef}
					onClose={() => { }}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed inset-0 xl:inset-x-72 xl:right-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
									<div className="w-full" hidden={!createModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={createModalLoading} onSubmit={submitCreateForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full sm:max-w-sm">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														Create Test
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setCreateModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div hidden={!showCreateError} className="text-red-400 text-center">
														Something has gone wrong. Please try again.
													</div>
													<div className="space-y-12">
														<div className="border-b border-gray-900/10 pb-12 mt-4">
															<div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">

																<div className="sm:col-span-2">
																	<label htmlFor="test_code" className="block text-sm font-medium leading-6 text-gray-900">
																		Test Code
																	</label>
																	<div className="mt-2">
																		<input
																			id="test_code"
																			name="test_code"
																			type="text"
																			autoComplete="test_code"
																			required
																			onChange={(e) => setFormDataTestCode(e.target.value)}
																			value={formDataTestCode}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>

																<div className="sm:col-span-2">
																	<label htmlFor="test_description" className="block text-sm font-medium leading-6 text-gray-900">
																		Test Description
																	</label>
																	<div className="mt-2">
																		<input
																			id="test_description"
																			name="test_description"
																			type="text"
																			autoComplete="test_description"
																			required
																			onChange={(e) => setFormDataTestDescription(e.target.value)}
																			value={formDataTestDescription}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>

																<div className="sm:col-span-2">
																	<label htmlFor="test_heading" className="block text-sm font-medium leading-6 text-gray-900">
																		Test Heading
																	</label>
																	<div className="mt-2">
																		<input
																			id="test_heading"
																			name="test_heading"
																			type="text"
																			autoComplete="test_heading"
																			required
																			onChange={(e) => setFormDataTestHeading(e.target.value)}
																			value={formDataTestHeading}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>

																<div className="sm:col-span-2">
																	<label htmlFor="test_introduction" className="block text-sm font-medium leading-6 text-gray-900">
																		Test Introduction
																	</label>
																	<div className="mt-2">
																		<input
																			id="test_introduction"
																			name="test_introduction"
																			type="text"
																			autoComplete="test_introduction"
																			required
																			onChange={(e) => setFormDataTestIntroduction(e.target.value)}
																			value={formDataTestIntroduction}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>

																<div className="col-span-full flex justify-between items-center border-t border-gray-900/10 mt-4 pt-8">
																	<h2 className="font-bold text-lg">Questions</h2>
																	<button
																		className="bg-green-500 text-white px-3 py-1 rounded-lg"
																		type="button"
																		onClick={createQuestion}
																	>+</button>
																</div>

																{questionList
																	.sort((a, b) => a.question_order_number - b.question_order_number)
																	.map((questionRow, questionIndex) => {
																		return <div key={questionIndex} className="col-span-full flex flex-col border-2 border-gray-300 p-6 rounded-lg shadow-lg">
																			<div className="flex w-full justify-end">
																				<button
																					className="bg-red-500 text-white px-3 py-1 rounded-lg"
																					type="button"
																					onClick={() => removeQuestion(questionRow.id)}
																				>-</button>
																			</div>

																			<div>
																				<div className="sm:col-span-full">
																					<label htmlFor="page_seq" className="block text-sm font-medium leading-6 text-gray-900">
																						Question Number
																					</label>
																					<div className="mt-2 flex gap-x-1">
																						<button
																							type="button"
																							className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																							onClick={(e) => {
																								const original_question_order_number = questionRow.question_order_number
																								const updatedRows = questionList
																									.sort((a, b) => a.question_order_number - b.question_order_number)
																									.map(questionRowToUpdate => {
																										if (questionRowToUpdate.id === questionRow.id) {
																											questionRowToUpdate.question_order_number += 1
																										} else if (questionRowToUpdate.question_order_number === original_question_order_number + 1) {
																											questionRowToUpdate.question_order_number -= 1
																										}
																										return questionRowToUpdate
																									})
																								setQuestionList(updatedRows)
																							}}
																						>
																							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																								<path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
																							</svg>
																						</button>
																						<input
																							id="page_seq"
																							name="page_seq"
																							type="text"
																							autoComplete="page_seq"
																							required
																							readOnly
																							value={questionRow.question_order_number.toFixed(2)}
																							className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																						/>
																						<button
																							type="button"
																							disabled={questionRow.question_order_number === 1}
																							className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																							onClick={(e) => {
																								const original_question_order_number = questionRow.question_order_number
																								const updatedRows = questionList
																									.sort((a, b) => a.question_order_number - b.question_order_number)
																									.map(questionRowToUpdate => {
																										if (questionRowToUpdate.id === questionRow.id) {
																											questionRowToUpdate.question_order_number -= 1
																										} else if (questionRowToUpdate.question_order_number === original_question_order_number - 1) {
																											questionRowToUpdate.question_order_number += 1
																										}
																										return questionRowToUpdate
																									})
																								setQuestionList(updatedRows)
																							}}
																						>
																							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																								<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
																							</svg>
																						</button>
																						<button
																							type="button"
																							className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																							onClick={(e) => {
																								const original_question_order_number = questionRow.question_order_number
																								const updatedRows = questionList
																									.sort((a, b) => a.question_order_number - b.question_order_number)
																									.map(questionRowToUpdate => {
																										if (questionRowToUpdate.id === questionRow.id) {
																											questionRowToUpdate.question_order_number += .01
																										} else if (questionRowToUpdate.question_order_number === original_question_order_number + .01) {
																											questionRowToUpdate.question_order_number -= .01
																										}
																										return questionRowToUpdate
																									})
																								setQuestionList(updatedRows)
																							}}
																						>
																							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																								<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
																							</svg>
																						</button>
																						<button
																							type="button"
																							className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																							disabled={questionRow.question_order_number.toFixed(2).slice(-2) === "00"}
																							onClick={(e) => {
																								const original_question_order_number = questionRow.question_order_number
																								const updatedRows = questionList
																									.sort((a, b) => a.question_order_number - b.question_order_number)
																									.map(questionRowToUpdate => {
																										if (questionRowToUpdate.id === questionRow.id) {
																											questionRowToUpdate.question_order_number -= .01
																										} else if (questionRowToUpdate.question_order_number === original_question_order_number - .01) {
																											questionRowToUpdate.question_order_number += .01
																										}
																										return questionRowToUpdate
																									})
																								setQuestionList(updatedRows)
																							}}
																						>
																							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																								<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
																							</svg>
																						</button>
																					</div>
																				</div>
																			</div>

																			<div className="mt-4">
																				<label htmlFor="question_text" className="block text-sm font-medium leading-6 text-gray-900">
																					Question Text
																				</label>
																				<div className="mt-2">
																					<textarea
																						id="question_text"
																						name="question_text"
																						rows={4}
																						autoComplete="question_text"
																						value={questionRow.question_text}
																						onChange={(e) => {
																							const updatedRows = questionList.map(questionRowToUpdate => {
																								if (questionRowToUpdate.id === questionRow.id) {
																									questionRowToUpdate.question_text = e.target.value
																								}
																								return questionRowToUpdate
																							})
																							setQuestionList(updatedRows)
																						}}
																						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																					/>
																				</div>
																			</div>

																			<div className="mt-4">
																				<label htmlFor="answer_type" className="block text-sm font-medium leading-6 text-gray-900">
																					Answer Type
																				</label>
																				<div className="mt-2">
																					<select
																						id="answer_type"
																						name="answer_type"
																						value={questionRow.answer_type}
																						onChange={(e) => {
																							const updatedRows = questionList.map(questionRowToUpdate => {
																								if (questionRowToUpdate.id === questionRow.id) {
																									if (e.target.value === "Single Multiple Choice") {
																										//
																										questionRowToUpdate.answer_type = e.target.value
																										//
																									} else if (e.target.value === "Boolean") {
																										//
																										questionRowToUpdate.answer_type = e.target.value
																										//
																									} else if (e.target.value === "Numeric") {
																										//
																										questionRowToUpdate.answer_type = e.target.value
																										//
																									}
																								}
																								return questionRowToUpdate
																							})
																							setQuestionList(updatedRows)
																						}}
																						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																					>
																						<option value="Single Multiple Choice">Multiple Choice (Single)</option>
																						<option value="Boolean">True/False</option>
																						<option value="Numeric">Numeric</option>
																					</select>
																				</div>
																			</div>

																			<div className="col-span-2 mt-6"
																				hidden={questionRow.answer_type !== "Boolean"}>
																				Correct Answer:
																				<input
																					disabled={testStatus === "Active (Locked)"}
																					className='ml-3' type='radio' checked={questionList[questionIndex].correct_answer === 'true'} value='true' onChange={() => {
																						setCorrectAnswer(questionIndex, 'true')
																					}} /> True
																				<input
																					disabled={testStatus === "Active (Locked)"}
																					className='ml-3' type='radio' checked={questionList[questionIndex].correct_answer !== 'true'} value='false' onChange={() => {
																						setCorrectAnswer(questionIndex, 'false')
																					}} /> False
																			</div>

																			<div
																				className="col-span-2 mt-6"
																				hidden={questionRow.answer_type !== "Numeric"}
																			>
																				<label htmlFor="numeric_min" className="block text-sm font-medium leading-6 text-gray-900">
																					Numeric Minimum Value
																				</label>
																				<div className="mt-2 flex gap-x-1">
																					<button
																						type="button"
																						className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																						onClick={(e) => {
																							const updatedRows = questionList.map(questionRowToUpdate => {
																								if (questionRowToUpdate.id === questionRow.id) {
																									questionRowToUpdate.numeric_min += 1
																								}
																								return questionRowToUpdate
																							})
																							setQuestionList(updatedRows)
																						}}
																					>
																						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																							<path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
																						</svg>
																					</button>
																					<input
																						id="numeric_min"
																						name="numeric_min"
																						type="text"
																						autoComplete="numeric_min"
																						required
																						readOnly
																						value={questionRow.numeric_min.toFixed(2)}
																						className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																					/>
																					<button
																						type="button"
																						disabled={questionRow.numeric_min === 0}
																						className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																						onClick={(e) => {
																							const updatedRows = questionList.map(questionRowToUpdate => {
																								if (questionRowToUpdate.id === questionRow.id) {
																									questionRowToUpdate.numeric_min -= 1
																								}
																								return questionRowToUpdate
																							})
																							setQuestionList(updatedRows)
																						}}
																					>
																						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																							<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
																						</svg>
																					</button>
																				</div>
																			</div>

																			<div
																				className="col-span-2 mt-6"
																				hidden={questionRow.answer_type !== "Numeric"}
																			>
																				<label htmlFor="numeric_max" className="block text-sm font-medium leading-6 text-gray-900">
																					Numeric Maximum Value
																				</label>
																				<div className="mt-2 flex gap-x-1">
																					<button
																						type="button"
																						className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																						onClick={(e) => {
																							const updatedRows = questionList.map(questionRowToUpdate => {
																								if (questionRowToUpdate.id === questionRow.id) {
																									questionRowToUpdate.numeric_max += 1
																								}
																								return questionRowToUpdate
																							})
																							setQuestionList(updatedRows)
																						}}
																					>
																						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																							<path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
																						</svg>
																					</button>
																					<input
																						id="numeric_max"
																						name="numeric_max"
																						type="text"
																						autoComplete="numeric_max"
																						required
																						readOnly
																						value={questionRow.numeric_max.toFixed(2)}
																						className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																					/>
																					<button
																						type="button"
																						disabled={questionRow.numeric_max === 0}
																						className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																						onClick={(e) => {
																							const updatedRows = questionList.map(questionRowToUpdate => {
																								if (questionRowToUpdate.id === questionRow.id) {
																									questionRowToUpdate.numeric_max -= 1
																								}
																								return questionRowToUpdate
																							})
																							setQuestionList(updatedRows)
																						}}
																					>
																						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																							<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
																						</svg>
																					</button>
																				</div>
																			</div>

																			<div
																				className={`col-span-full flex justify-between items-center border-t border-gray-900/10 mt-4 pt-8 ${questionRow.answer_type !== "Single Multiple Choice" ? "hidden" : ""}`}
																				hidden={questionRow.answer_type !== "Single Multiple Choice"}
																			>
																				<h2 className="font-bold text-lg">Answers</h2>
																				<button
																					className="bg-green-500 text-white px-3 py-1 rounded-lg"
																					type="button"
																					onClick={() => createAnswer(questionRow.id)}
																				>+</button>
																			</div>

																			<div
																				className="col-span-2 mt-6"
																				hidden={questionRow.answer_type !== "Single Multiple Choice"}
																			>
																				{questionRow.answers
																					.sort((a, b) => a.answer_sequence - b.answer_sequence)
																					.map((answerRow, answerIndex) => {
																						return <div key={answerIndex} className="col-span-full flex flex-col border-2 border-gray-300 p-6 rounded-lg shadow-lg mb-4">
																							<div className="flex w-full justify-end">
																								<button
																									className="bg-red-500 text-white px-3 py-1 rounded-lg"
																									type="button"
																									onClick={() => removeAnswer(questionRow.id, answerRow.id)}
																								>-</button>

																							</div>

																							<div>
																								<div className="sm:col-span-full">
																									<label htmlFor="answer_sequence" className="block text-sm font-medium leading-6 text-gray-900">
																										Answer Seq
																									</label>
																									<div className="mt-2 flex gap-x-1">
																										<button
																											type="button"
																											className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																											onClick={(e) => {
																												const updatedRows = questionList.map(questionRowToUpdate => {
																													if (questionRowToUpdate.id === questionRow.id) {
																														const original_answer_sequence = answerRow.answer_sequence
																														const updatedAnswers = questionRowToUpdate.answers
																															.sort((a, b) => a.answer_sequence - b.answer_sequence)
																															.map(answerRowToUpdate => {
																																//
																																if (answerRowToUpdate.id === answerRow.id) {
																																	answerRowToUpdate.answer_sequence += 1
																																} else if (answerRowToUpdate.answer_sequence === original_answer_sequence + 1) {
																																	answerRowToUpdate.answer_sequence -= 1
																																}
																																//
																																return answerRowToUpdate
																																//
																															})
																														questionRowToUpdate.answers = updatedAnswers
																													}
																													return questionRowToUpdate
																												})
																												setQuestionList(updatedRows)
																											}}
																										>
																											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																												<path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
																											</svg>
																										</button>
																										<input
																											id="answer_sequence"
																											name="answer_sequence"
																											type="text"
																											autoComplete="answer_sequence"
																											required
																											readOnly
																											value={answerRow.answer_sequence.toFixed(2)}
																											className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																										/>
																										<button
																											type="button"
																											disabled={answerRow.answer_sequence === 1}
																											className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																											onClick={(e) => {
																												const updatedRows = questionList.map(questionRowToUpdate => {
																													if (questionRowToUpdate.id === questionRow.id) {
																														const original_answer_sequence = answerRow.answer_sequence
																														const updatedAnswers = questionRowToUpdate.answers
																															.sort((a, b) => a.answer_sequence - b.answer_sequence)
																															.map(answerRowToUpdate => {
																																//
																																if (answerRowToUpdate.id === answerRow.id) {
																																	answerRowToUpdate.answer_sequence -= 1
																																} else if (answerRowToUpdate.answer_sequence === original_answer_sequence - 1) {
																																	answerRowToUpdate.answer_sequence += 1
																																}
																																//
																																return answerRowToUpdate
																																//
																															})
																														questionRowToUpdate.answers = updatedAnswers
																													}
																													return questionRowToUpdate
																												})
																												setQuestionList(updatedRows)
																											}}
																										>
																											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																												<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
																											</svg>
																										</button>
																									</div>
																								</div>
																							</div>

																							<div className="mt-4">
																								<label htmlFor="answer_text" className="block text-sm font-medium leading-6 text-gray-900">
																									Answer Text
																								</label>
																								<div className="mt-2">
																									<textarea
																										id="answer_text"
																										name="answer_text"
																										rows={4}
																										autoComplete="answer_text"
																										value={answerRow.answer_text}
																										onChange={(e) => {
																											const updatedRows = questionList.map(questionRowToUpdate => {
																												if (questionRowToUpdate.id === questionRow.id) {
																													const updatedAnswers = questionRowToUpdate.answers.map(answerRowToUpdate => {
																														//
																														if (answerRowToUpdate.id === answerRow.id) {
																															answerRowToUpdate.answer_text = e.target.value
																														}
																														//
																														return answerRowToUpdate
																														//
																													})
																													questionRowToUpdate.answers = updatedAnswers
																												}
																												return questionRowToUpdate
																											})
																											setQuestionList(updatedRows)
																										}}
																										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																									/>
																								</div>
																							</div>
																							{/* Checkbox */}
																							<div className="flex w-full justify-end mt-5">
																								<input id="correct-checkbox" type="checkbox" value="" className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-600"
																									checked={questionList[questionIndex].correct_answer && answerRow.answer_text ? questionList[questionIndex].correct_answer === answerRow.answer_text : !questionIndex}
																									onChange={() =>
																										setCorrectAnswer(questionIndex, answerRow.answer_text)} />
																								<label className="ms-2 text-sm font-medium">Correct Answer?</label>
																							</div>

																						</div>
																					})}
																			</div>

																		</div>
																	})}

																<div className="col-span-full flex justify-between items-center border-t border-gray-900/10 mt-4 pt-8">
																	<h2 className="font-bold text-lg">Questions</h2>
																	<button
																		className="bg-green-500 text-white px-3 py-1 rounded-lg"
																		type="button"
																		onClick={createQuestion}
																	>+</button>
																</div>

															</div>

														</div>
													</div>
												</div>
											</div>
											<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													type="submit"
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Create
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setCreateModalOpen(false)}
													ref={cancelButtonRef}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog >
			</Transition.Root >

			<Transition.Root show={editModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={() => { }}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 xl:inset-x-72 xl:right-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-11/12 md:w-9/12 lg:w-9/12 sm:p-6">


									<div className="w-full" hidden={!editModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={editModalLoading} onSubmit={submitMaintainForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														Maintain Test
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setEditModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div className="space-y-4">
														<div hidden={!showMaintainError} className="text-red-400 text-center">
															Something has gone wrong
														</div>
														<div className="space-y-12">
															<div className="">
																<div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">

																	<div className="sm:col-span-2">
																		<label htmlFor="test_code" className="block text-sm font-medium leading-6 text-gray-900">
																			Test Code
																		</label>
																		{/* Test Code Field */}
																		<div className="mt-2">
																			<input
																				id="test_code"
																				name="test_code"
																				type="text"
																				autoComplete="test_code"
																				required
																				readOnly={testStatus !== "Entered"}
																				onChange={(e) => setFormDataTestCode(e.target.value)}
																				value={formDataTestCode}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																	{/* Test Description Field */}
																	<div className="sm:col-span-2">
																		<label htmlFor="test_description" className="block text-sm font-medium leading-6 text-gray-900">
																			Test Description
																		</label>
																		<div className="mt-2">
																			<input
																				id="test_description"
																				name="test_description"
																				type="text"
																				autoComplete="test_description"
																				required
																				readOnly={testStatus !== "Entered"}
																				onChange={(e) => setFormDataTestDescription(e.target.value)}
																				value={formDataTestDescription}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>

																	<div className="sm:col-span-2">
																		<label htmlFor="test_heading" className="block text-sm font-medium leading-6 text-gray-900">
																			Test Heading
																		</label>
																		<div className="mt-2">
																			<input
																				id="test_heading"
																				name="test_heading"
																				type="text"
																				autoComplete="test_heading"
																				required
																				readOnly={testStatus !== "Entered"}
																				onChange={(e) => setFormDataTestHeading(e.target.value)}
																				value={formDataTestHeading}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>

																	<div className="sm:col-span-2">
																		<label htmlFor="test_introduction" className="block text-sm font-medium leading-6 text-gray-900">
																			Test Introduction
																		</label>
																		<div className="mt-2">
																			<input
																				id="test_introduction"
																				name="test_introduction"
																				type="text"
																				autoComplete="test_introduction"
																				required
																				readOnly={testStatus !== "Entered"}
																				onChange={(e) => setFormDataTestIntroduction(e.target.value)}
																				value={formDataTestIntroduction}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>

																	<div
																		className="col-span-full flex justify-between items-center border-t border-gray-900/10 mt-4 pt-8"
																	>
																		<h2 className="font-bold text-lg">Questions</h2>
																		<button
																			className="bg-green-500 text-white px-3 py-1 rounded-lg"
																			type="button"
																			onClick={createQuestion}
																			hidden={testStatus !== "Entered"}
																		>+</button>
																	</div>

																	{questionList
																		.sort((a, b) => a.question_order_number - b.question_order_number)
																		.map((questionRow, questionIndex) => {
																			if (questionsToggled[questionIndex]) {
																				return <div key={questionIndex} className="col-span-full flex flex-col border-2 border-gray-300 p-6 rounded-lg shadow-lg">
																					<div className="flex w-full justify-end"><img className="rotate-90" src="/chevron-double-down.svg" onClick={() =>
																						toggleQuestion(questionIndex)}></img>
																						<img src='/delete-bin-2.svg'
																							hidden={testStatus !== "Entered"}
																							onClick={() => removeQuestion(questionRow.id)}>
																						</img>
																					</div>
																					<div className=" overflow-hidden">
																						<label htmlFor="page_seq" className="block text-sm font-medium leading-6 text-gray-900">
																							Question #{questionRow.question_order_number + 1}
																						</label>
																						<div className="mt-5"> {questionRow.question_text.length > 50 ? questionRow.question_text.slice(0, 50) + '...' : questionRow.question_text}</div>

																					</div>
																				</div>
																			}
																			return <div key={questionIndex} className="col-span-full flex flex-col border-2 border-gray-300 p-6 rounded-lg shadow-lg">

																				<div className="flex w-full justify-end">
																					<img src="/chevron-double-down.svg" onClick={() =>
																						toggleQuestion(questionIndex)}></img>
																					{/* <button
																						className="bg-red-500 text-white px-3 py-1 rounded-lg"
																						type="button"
																					><img src="./chevron-double-down.svg"></img></button> */}
																					{/* Delete question */}
																					<img src='/delete-bin-2.svg'
																						hidden={testStatus !== "Entered"}
																						onClick={() => removeQuestion(questionRow.id)}>
																					</img>
																				</div>
																				<div>
																					<div className="sm:col-span-full">
																						<label htmlFor="page_seq" className="block text-sm font-medium leading-6 text-gray-900">
																							Question #{questionRow.question_order_number + 1}
																						</label>
																						{/* Question Up Arrow  */}
																						<div className="mt-2 flex gap-x-1">
																							{/* <button
																								type="button"
																								className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																								onClick={(e) => {
																									const updatedRows = questionList.map(questionRowToUpdate => {
																										if (questionRowToUpdate.id === questionRow.id) {
																											questionRowToUpdate.question_order_number += 1
																										}
																										return questionRowToUpdate
																									})
																									setQuestionList(updatedRows)
																								}}
																							>
																								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																									<path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
																								</svg>
																							</button> */}
																							{/* original question number */}
																							{/* <input
																								id="page_seq"
																								name="page_seq"
																								type="text"
																								autoComplete="page_seq"
																								required
																								readOnly
																								value={questionRow.question_order_number +1}
																								// value={questionRow.question_order_number.toFixed(2)}
																								className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																							/> */}
																							{/* <button
																								type="button"
																								disabled={questionRow.question_order_number === 1}
																								className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																								onClick={(e) => {
																									const updatedRows = questionList.map(questionRowToUpdate => {
																										if (questionRowToUpdate.id === questionRow.id) {
																											questionRowToUpdate.question_order_number -= 1
																										}
																										return questionRowToUpdate
																									})
																									setQuestionList(updatedRows)
																								}}
																							>
																								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																									<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
																								</svg>
																							</button> */}
																							{/* Double Up */}
																							{/* <button
																								type="button"
																								className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																								onClick={(e) => {
																									const updatedRows = questionList.map(questionRowToUpdate => {
																										if (questionRowToUpdate.id === questionRow.id) {
																											questionRowToUpdate.question_order_number += .01
																										}
																										return questionRowToUpdate
																									})
																									setQuestionList(updatedRows)
																								}}
																							>
																								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																									<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
																								</svg>
																							</button> */}
																							{/* Double Down */}
																							{/* <button
																								type="button"
																								className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																								disabled={questionRow.question_order_number.toFixed(2).slice(-2) === "00"}
																								onClick={(e) => {
																									const updatedRows = questionList.map(questionRowToUpdate => {
																										if (questionRowToUpdate.id === questionRow.id) {
																											questionRowToUpdate.question_order_number -= .01
																										}
																										return questionRowToUpdate
																									})
																									setQuestionList(updatedRows)
																								}}
																							>
																								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																									<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
																								</svg>
																							</button> */}
																						</div>
																					</div>
																				</div>

																				<div className="mt-4">
																					{/* <label htmlFor="question_text" className="block text-sm font-medium leading-6 text-gray-900">
																						Question Text
																					</label> */}
																					<div className="mt-2">
																						<textarea
																							id="question_text"
																							name="question_text"
																							rows={4}
																							autoComplete="question_text"
																							value={questionRow.question_text}
																							readOnly={testStatus !== "Entered"}
																							onChange={(e) => {
																								const updatedRows = questionList.map(questionRowToUpdate => {
																									if (questionRowToUpdate.id === questionRow.id) {
																										questionRowToUpdate.question_text = e.target.value
																									}
																									return questionRowToUpdate
																								})
																								setQuestionList(updatedRows)
																							}}
																							className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																						/>
																					</div>
																				</div>

																				<div className="mt-4">
																					<label htmlFor="answer_type" className="block text-sm font-medium leading-6 text-gray-900">
																						Answer Type
																					</label>
																					<div className="mt-2">
																						<select
																							id="answer_type"
																							name="answer_type"
																							value={questionRow.answer_type}
																							disabled={testStatus !== "Entered"}
																							onChange={(e) => {
																								const updatedRows = questionList.map(questionRowToUpdate => {
																									if (questionRowToUpdate.id === questionRow.id) {
																										if (e.target.value === "Single Multiple Choice") {
																											//
																											questionRowToUpdate.answer_type = e.target.value
																											//
																										} else if (e.target.value === "Boolean") {
																											//
																											questionRowToUpdate.answer_type = e.target.value
																											//
																										} else if (e.target.value === "Numeric") {
																											//
																											questionRowToUpdate.answer_type = e.target.value
																											//
																										}
																									}
																									return questionRowToUpdate
																								})
																								setQuestionList(updatedRows)
																							}}
																							className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																						>
																							<option value="Single Multiple Choice">Multiple Choice (Single)</option>
																							<option value="Boolean">True/False</option>
																							<option value="Numeric">Numeric</option>
																						</select>
																					</div>
																				</div>
																				<div className="col-span-2 mt-6"
																					hidden={questionRow.answer_type !== "Boolean"}>
																					Correct Answer:
																					<input
																						disabled={testStatus === "Active (Locked)"}
																						className='ml-3' type='radio' checked={questionList[questionIndex].correct_answer === 'true'} value='true' onChange={() => {
																							setCorrectAnswer(questionIndex, 'true')
																						}} /> True
																					<input
																						disabled={testStatus === "Active (Locked)"}
																						className='ml-3' type='radio' checked={questionList[questionIndex].correct_answer !== 'true'} value='false' onChange={() => {
																							setCorrectAnswer(questionIndex, 'false')
																						}} /> False
																				</div>

																				<div
																					className="col-span-2 mt-6"
																					hidden={questionRow.answer_type !== "Numeric"}
																				>
																					<label htmlFor="numeric_min" className="block text-sm font-medium leading-6 text-gray-900">
																						Numeric Minimum Value
																					</label>
																					<div className="mt-2 flex gap-x-1">
																						<button
																							type="button"
																							className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																							hidden={testStatus !== "Entered"}
																							onClick={(e) => {
																								const updatedRows = questionList.map(questionRowToUpdate => {
																									if (questionRowToUpdate.id === questionRow.id) {
																										questionRowToUpdate.numeric_min += 1
																									}
																									return questionRowToUpdate
																								})
																								setQuestionList(updatedRows)
																							}}
																						>
																							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																								<path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
																							</svg>
																						</button>
																						<input
																							id="numeric_min"
																							name="numeric_min"
																							type="text"
																							autoComplete="numeric_min"
																							required
																							readOnly
																							value={questionRow.numeric_min.toFixed(2)}
																							className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																						/>
																						<button
																							type="button"
																							disabled={questionRow.numeric_min === 0}
																							className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																							hidden={testStatus !== "Entered"}
																							onClick={(e) => {
																								const updatedRows = questionList.map(questionRowToUpdate => {
																									if (questionRowToUpdate.id === questionRow.id) {
																										questionRowToUpdate.numeric_min -= 1
																									}
																									return questionRowToUpdate
																								})
																								setQuestionList(updatedRows)
																							}}
																						>
																							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																								<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
																							</svg>
																						</button>
																					</div>
																				</div>

																				<div
																					className="col-span-2 mt-6"
																					hidden={questionRow.answer_type !== "Numeric"}
																				>
																					<label htmlFor="numeric_max" className="block text-sm font-medium leading-6 text-gray-900">
																						Numeric Maximum Value
																					</label>
																					<div className="mt-2 flex gap-x-1">
																						<button
																							type="button"
																							className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																							hidden={testStatus !== "Entered"}
																							onClick={(e) => {
																								const updatedRows = questionList.map(questionRowToUpdate => {
																									if (questionRowToUpdate.id === questionRow.id) {
																										questionRowToUpdate.numeric_max += 1
																									}
																									return questionRowToUpdate
																								})
																								setQuestionList(updatedRows)
																							}}
																						>
																							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																								<path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
																							</svg>
																						</button>
																						<input
																							id="numeric_max"
																							name="numeric_max"
																							type="text"
																							autoComplete="numeric_max"
																							required
																							readOnly
																							value={questionRow.numeric_max.toFixed(2)}
																							className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																						/>
																						<button
																							type="button"
																							disabled={questionRow.numeric_max === 0}
																							className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																							hidden={testStatus !== "Entered"}
																							onClick={(e) => {
																								const updatedRows = questionList.map(questionRowToUpdate => {
																									if (questionRowToUpdate.id === questionRow.id) {
																										questionRowToUpdate.numeric_max -= 1
																									}
																									return questionRowToUpdate
																								})
																								setQuestionList(updatedRows)
																							}}
																						>
																							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																								<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
																							</svg>
																						</button>
																					</div>
																				</div>

																				<div
																					className={`col-span-full flex justify-between items-center border-t border-gray-900/10 mt-4 pt-8 ${questionRow.answer_type !== "Single Multiple Choice" ? "hidden" : ""}`}
																					hidden={questionRow.answer_type !== "Single Multiple Choice"}
																				>
																					<h2 className="font-bold text-lg">Answers</h2>
																					<button
																						className="bg-green-500 text-white px-3 py-1 rounded-lg"
																						type="button"
																						hidden={testStatus !== "Entered"}
																						onClick={() => createAnswer(questionRow.id)}
																					>+</button>
																				</div>

																				<div
																					className="col-span-2 mt-6"
																					hidden={questionRow.answer_type !== "Single Multiple Choice"}
																				>
																					{questionRow.answers
																						.sort((a, b) => a.answer_sequence - b.answer_sequence)
																						.map((answerRow, answerIndex) => {
																							return <div key={answerIndex} className="col-span-full flex flex-col border-2 border-gray-300 p-6 rounded-lg h-.2  shadow-lg mb-4">
																								<div className="flex w-full justify-end">
																									{/* <button
																										type="button"
																										onClick={() => removeAnswer(questionRow.id, answerRow.id)}
																									></button> */}
																									<img
																										src='/delete-bin-2.svg'
																										onClick={() => removeAnswer(questionRow.id, answerRow.id)}
																										hidden={testStatus !== "Entered"}
																									></img>
																								</div>

																								<div>
																									<div className="sm:col-span-full">
																										<label htmlFor="answer_sequence" className="block text-sm font-medium leading-6 text-gray-900">
																											Answer Seq
																										</label>
																										<div className="mt-2 flex gap-x-1">
																											<button
																												type="button"
																												className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																												hidden={testStatus !== "Entered"}
																												onClick={(e) => {
																													const updatedRows = questionList.map(questionRowToUpdate => {
																														if (questionRowToUpdate.id === questionRow.id) {
																															const original_answer_sequence = answerRow.answer_sequence
																															const updatedAnswers = questionRowToUpdate.answers
																																.sort((a, b) => a.answer_sequence - b.answer_sequence)
																																.map(answerRowToUpdate => {
																																	//
																																	if (answerRowToUpdate.id === answerRow.id) {
																																		answerRowToUpdate.answer_sequence += 1
																																	} else if (answerRowToUpdate.answer_sequence === original_answer_sequence + 1) {
																																		answerRowToUpdate.answer_sequence -= 1
																																	}
																																	//
																																	return answerRowToUpdate
																																	//
																																})
																															questionRowToUpdate.answers = updatedAnswers
																														}
																														return questionRowToUpdate
																													})
																													setQuestionList(updatedRows)
																												}}
																											>
																												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																													<path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
																												</svg>
																											</button>
																											<input
																												id="answer_sequence"
																												name="answer_sequence"
																												type="text"
																												autoComplete="answer_sequence"
																												required
																												readOnly
																												value={answerRow.answer_sequence.toFixed(2)}
																												className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																											/>
																											{/* sequence down button */}
																											<button
																												type="button"
																												disabled={answerRow.answer_sequence === 1}
																												className="bg-orange-400 px-3 py-1 text-white rounded-lg"
																												hidden={testStatus !== "Entered"}
																												onClick={(e) => {
																													const updatedRows = questionList.map(questionRowToUpdate => {
																														if (questionRowToUpdate.id === questionRow.id) {
																															const original_answer_sequence = answerRow.answer_sequence
																															const updatedAnswers = questionRowToUpdate.answers
																																.sort((a, b) => a.answer_sequence - b.answer_sequence)
																																.map(answerRowToUpdate => {
																																	//
																																	if (answerRowToUpdate.id === answerRow.id) {
																																		answerRowToUpdate.answer_sequence -= 1
																																	} else if (answerRowToUpdate.answer_sequence === original_answer_sequence - 1) {
																																		answerRowToUpdate.answer_sequence += 1
																																	}
																																	//
																																	return answerRowToUpdate
																																	//
																																})
																															questionRowToUpdate.answers = updatedAnswers
																														}
																														return questionRowToUpdate
																													})
																													setQuestionList(updatedRows)
																												}}
																											>
																												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
																													<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
																												</svg>
																											</button>
																										</div>
																									</div>
																								</div>

																								<div className="mt-4">
																									<label htmlFor="answer_text" className="block text-sm font-medium leading-6 text-gray-900">
																										Answer Text
																									</label>
																									<div className="mt-2">
																										<textarea
																											id="answer_text"
																											name="answer_text"
																											rows={4}
																											autoComplete="answer_text"
																											readOnly={testStatus !== "Entered"}
																											value={answerRow.answer_text}
																											onChange={(e) => {
																												const updatedRows = questionList.map(questionRowToUpdate => {
																													if (questionRowToUpdate.id === questionRow.id) {
																														const updatedAnswers = questionRowToUpdate.answers.map(answerRowToUpdate => {
																															//
																															if (answerRowToUpdate.id === answerRow.id) {
																																answerRowToUpdate.answer_text = e.target.value
																															}
																															//
																															return answerRowToUpdate
																															//
																														})
																														questionRowToUpdate.answers = updatedAnswers
																													}
																													return questionRowToUpdate
																												})
																												setQuestionList(updatedRows)
																											}}
																											className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																										/>
																									</div>
																								</div>
																								{/* Checkbox */}
																								<div className="flex w-full justify-end mt-5">
																									<input id="correct-checkbox" type="checkbox" value="" className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-600"
																										checked={answerRow.answer_text ? questionList[questionIndex].correct_answer === answerRow.answer_text : !questionIndex}
																										disabled={testStatus !== "Entered"}
																										onChange={() =>
																											setCorrectAnswer(questionIndex, answerRow.answer_text)} />
																									<label className="ms-2 text-sm font-medium">Correct Answer?</label>
																								</div>
																							</div>

																						})}
																				</div>

																			</div>
																		})}
																	<div className="col-span-full flex justify-between items-center border-b border-gray-900/10 mt-4 pt-8 pb-8">
																		<h2 className="font-bold text-lg">Questions</h2>
																		<button
																			className="bg-green-500 text-white px-3 py-1 rounded-lg"
																			type="button"
																			hidden={testStatus !== "Entered"}
																			onClick={createQuestion}
																		>+</button>
																	</div>

																</div>
															</div>
														</div>

														<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 gap-y-2">
															<button
																id="role"
																disabled={true}
																className="block w-full bg-red-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 outline-none"
																onClick={submitDelete}
															>
																Delete (Disabled)
															</button>
															<button
																id="role"
																disabled={testStatus === "Retired"}
																className="mt-2 sm:mt-0 block w-full bg-orange-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6 outline-none"
																onClick={submitRetired}
															>
																Archive Test
															</button>
														</div>
													</div>
												</div>
											</div>
											<div className="mt-0 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													type="button"
													className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
													onClick={attemptCopy}
												>
													Copy Test
												</button>
											</div>
											<div className="mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													type="submit"
													// disabled={!validName}
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Update
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setEditModalOpen(false)}
													ref={cancelButtonRef}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<MessageModal messageModalOpen={showMessageModal} setMessageModalOpen={setShowMessageModal}>
				<div>
					<h2 className='text-xl font-bold mb-6'>
						Linked to Occupation
					</h2>
					<p className='mb-4 text-gray-600'>
						This test is currently assigned to an occupation and cannot be retired.
					</p>
					<p className="text-gray-600">
						Unlink any occupations to this test and try again.
					</p>
					<div className="flex items-end mt-8">
						<button
							className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
							onClick={() => setShowMessageModal(false)}
							autoFocus
						>
							OK
						</button>
					</div>
				</div>
			</MessageModal>
		</>
	)
}